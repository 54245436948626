import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import toastr from "toastr";

import ProfileTemplate from "./ProfileTemplate";

import Switch from "../utils/components/Switch";
import API, { headers } from "../utils/API";

import styles from "./Profile.module.scss";
import Helmet from "react-helmet";
import { handleErrors } from "../utils/helper";

class NotificationSettings extends Component {
  state = {
    email: null,
    error: false,
  };

  componentDidMount() {
    const { user } = this.props;
    const config = {
      headers: {
        ...headers,
        Authorization: `${user._tokenType} ${user._token}`,
      },
    };

    API.get("account/settings", config)
      .then((response) => {
        const { data } = response.data;

        this.setState({
          email: data.notifications.email_notification ? true : false,
        });
      })
      .catch((err) => handleErrors(err));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.email !== null &&
      prevState.email !== this.state.email &&
      !this.state.error
    ) {
      const params = {
        email_notification: this.state.email,
      };

      const config = {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        },
      };

      API.patch("account/settings", params, config)
        .then((response) => {
          toastr.clear();
          toastr.success(response.data.info.message, "", { progressBar: true });
        })
        .catch((error) => {
          handleErrors(error);
          setTimeout(() => {
            this.setState((prevState) => {
              return { email: !prevState.email, error: true };
            });
          }, 200);
        });
    }
  }

  handleChange = (e) => {
    const { name, checked } = e.target;

    this.setState({
      [name]: checked,
      error: false,
    });
  };

  render() {
    const { translate } = this.props;
    const switchProps = {
      type: "checkbox",
      name: "email",
      checked: this.state.email,
      id: "email",
      onChange: this.handleChange,
    };
    return (
      <ProfileTemplate>
        <Helmet>
          <title>
            {translate("notification_settings.notification_settings")}
          </title>
        </Helmet>
        <div className={styles.pageContent}>
          <div className="row mb-2">
            <div className="col">
              <h3 className={styles.pageTitle}>
                {translate("notification_settings.notification_settings")}
              </h3>
            </div>
          </div>
          <div className="row mt-3 mt-md-5">
            <div className="form-group col">
              <Switch
                inputProps={switchProps}
                sub={translate(
                  "notification_settings.email_notifications_desc"
                )}
              >
                {translate("notification_settings.email_notifications")}
              </Switch>
            </div>
          </div>
        </div>
      </ProfileTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(NotificationSettings));
