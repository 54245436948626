import React from "react";

export default function RejectPopup(props) {
  const {
    positions,
    translate,
    reason,
    description,
    bookingID,
    bookingStatus,
    reasons,
    handleChange,
    handleConfirmAction,
    handleCloseAllPopups
  } = props;
  return (
    <div
      className="cancel-popup position-fixed"
      style={{
        top: positions.cancel.top,
        left: positions.cancel.left,
        transform: `translateX(calc(-100% + ${positions.cancel.width}px))`
      }}
    >
      <div
        className="overlay"
        onClick={e => handleCloseAllPopups(e, "cancelModal", true)}
      ></div>
      <div className="popup-holder">
        <div className="row mb-3">
          <div className="col">
            <h6 className="text-center">
              {translate("bookings.reject_title")}
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group position-relative sliding-label">
              <select
                name="reason"
                id="reason"
                value={reason}
                onChange={handleChange}
                className={`form-control custom-select ${reason !== "" &&
                  `filled`}`}
                required
              >
                <option value="" hidden>
                  &nbsp;
                </option>
                {Object.keys(reasons).map(key => {
                  return (
                    <option key={key} value={key}>
                      {reasons[key].title}
                    </option>
                  );
                })}
              </select>
              <label htmlFor="reason" className="position-absolute">
                <span>{translate("bookings.reason_to_reject")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group position-relative sliding-label">
              <textarea
                name="description"
                value={description}
                className={`form-control ${description !== "" && `filled`}`}
                id="description"
                onChange={handleChange}
                style={{ height: "140px" }}
              />
              <label htmlFor="description" className="position-absolute">
                <span>{translate("bookings.description")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <button
              className="button-primary w-100"
              onClick={e =>
                handleConfirmAction("reject", {
                  id: bookingID,
                  reason: reason,
                  desc: description,
                  status_id: bookingStatus
                })
              }
            >
              {translate("bookings.reject")}
            </button>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <button
              className="button-transparent w-100"
              onClick={e => handleCloseAllPopups(e, "cancelModal", true)}
            >
              {translate("bookings.do_nothing")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
