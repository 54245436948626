import API, { headers } from "./API";
import toastr from "toastr";
import store from "../store";
import { logout } from "../auth/authActions";
import {
  guestRoute,
  inactiveLoggedRoute,
  loggedRoute,
  route,
} from "../app/routes";
import { matchPath } from "react-router";
import { STEPS } from "../account-completion/pages/Information/Information";

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

export const getBase64Url = (url) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      fetch(url, { mode: "cors" }).then((response) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(response);
      });
    }, 200);
  });
};

export const seeHidePassword = (state, which = null) => {
  if (which) {
    const getState = { ...state };
    getState[which] = !getState[which];
    return getState;
  } else {
    const getState = !state;
    return getState;
  }
};

export const getCountries = async (cancelToken = null) => {
  let countries;
  await API.post("locations", { headers, cancelToken: cancelToken || "" })
    .then((response) => {
      const { data } = response.data;

      countries = data;
    })
    .catch((error) => consoleError("Countries", error));

  return countries;
};

export const getCities = (country_id, cancelToken = null) => {
  const params = {
    type: 1,
    parent_id: country_id,
  };

  const cities = API.post("locations", params, {
    headers,
    cancelToken: cancelToken || "",
  })
    .then((response) => {
      const { data } = response.data;

      return data;
    })
    .catch((error) => consoleError("Cities", error));

  return cities;
};

export const getDistricts = (city_id, cancelToken = null) => {
  const params = {
    type: 2,
    parent_id: city_id,
  };

  const districts = API.post("locations", params, {
    headers,
    cancelToken: cancelToken || "",
  })
    .then((response) => {
      const { data } = response.data;

      return data;
    })
    .catch((error) => consoleError("Districts", error));

  return districts;
};

export const getMainCategories = (token) => {
  const configs = {
    headers: {
      ...headers,
      Authorization: token,
    },
  };

  const params = {};

  const categories = API.post("categories", params, configs)
    .then((response) => {
      const { data } = response.data;

      return data;
    })
    .catch((error) => consoleError("Main Categories", error));

  return categories;
};

export const getSubCategories = (token, cat_id) => {
  const configs = {
    headers: {
      ...headers,
      Authorization: token,
    },
  };

  const params = {
    parent_id: cat_id,
  };

  const categories = API.post("categories", params, configs)
    .then((response) => {
      const { data } = response.data;

      return data;
    })
    .catch((error) => consoleError("Sub Categories", error));

  return categories;
};

const consoleError = (type, error) => {
  if (error.response) {
    console.error("%s: %s", type, error.response.data.info.message);
  } else {
    console.error("%s: %s", type, error);
  }
};

export const validateInput = (type, value) => {
  let regexp;
  if (type === "phone")
    regexp =
      /^(\+)?(\d{1,3})?[\s]?[(]?[0-9]{3}[)]?[\s]?[0-9]{3}[\s]?[0-9]{2}[\s]?[0-9]{1,2}$/im;

  if (type === "website")
    regexp =
      /^(?:(?:(?:https?|ftp)?:)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  if (type === "email")
    // eslint-disable-next-line no-useless-escape
    regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (type === "number") regexp = /^([0-9])+$/;

  if (type === "idn") regexp = /^[1-9]{1}[0-9]{9}[02468]{1}$/;

  return regexp.test(value);
};

export const compareObjects = (obj1, obj2) => {
  if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
    const diffs = Object.keys(obj1).filter((key) => {
      if (typeof obj1[key] === "object") {
        return JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key]);
      }
      return obj1[key] !== obj2[key];
    });

    return diffs;

    // console.log(diffs);
  } else {
    return false;
  }
};

export const getWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
};

export const getHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
};

export const makeURL = (text) =>
  text
    .replace(/Ğ/gim, "g")
    .replace(/Ü/gim, "u")
    .replace(/Ş/gim, "s")
    .replace(/I/gim, "i")
    .replace(/İ/gim, "i")
    .replace(/Ö/gim, "o")
    .replace(/Ç/gim, "c")
    .replace(/ğ/gim, "g")
    .replace(/ü/gim, "u")
    .replace(/ş/gim, "s")
    .replace(/ı/gim, "i")
    .replace(/ö/gim, "o")
    .replace(/ç/gim, "c")
    .replace(/[^a-z0-9]/gi, "_")
    .toLowerCase();

export const getScrollY = () =>
  typeof window.pageYOffset == "number"
    ? window.pageYOffset
    : document.body && (document.body.scrollLeft || document.body.scrollTop)
    ? document.body.scrollTop
    : document.documentElement &&
      (document.documentElement.scrollLeft ||
        document.documentElement.scrollTop)
    ? document.documentElement.scrollTop
    : "";

export const handleErrors = (err, showError = true) => {
  if (err.response) {
    if (err.response.status || err.response.code) {
      if (err.response.status === 401 || err.response.code === 401) {
        const { dispatch } = store;
        dispatch(logout());
      }
    }

    if (showError) {
      toastr.clear();
      if (err.response.data) {
        if (
          err.response.data.errors &&
          Object.keys(err.response.data.errors).length
        ) {
          Object.keys(err.response.data.errors).forEach((key) => {
            toastr.error(err.response.data.errors[key][0]);
          });
        }
      }

      if (err.response.data.info) {
        toastr.error(err.response.data.info.message);
      }
    }
  }
};

export const currencyFormatter = (currency = {}, amount, lang = "tr") => {
  amount = new Intl.NumberFormat(lang).format(parseFloat(amount).toFixed(2));
  let formatted = amount;

  switch (currency.code.trim().toLowerCase()) {
    case "try":
      formatted = currency.symbol + amount;
      break;

    case "usd":
      formatted = currency.symbol + amount;
      break;

    case "eur":
      formatted = amount + currency.symbol;
      break;

    case "gbp":
      formatted = currency.symbol + amount;
      break;

    default:
      return false;
  }

  return formatted;
};

export const approvalProgressCalculator = (checklist) => {
  if (checklist && typeof checklist === "object") {
    const keys = Object.keys(checklist);

    const listCount = keys.length;
    const done = keys.filter((key) => checklist[key]).length;

    const percentage = (done / listCount) * 100;

    return {
      listCount,
      done,
      percentage,
    };
  } else return { listCount: 0, done: 0, percentage: 0 };
};

export const getCurrentRouteName = async (link, lang) => {
  const allRoutes = {
    ...route,
    ...guestRoute,
    ...loggedRoute,
    ...inactiveLoggedRoute,
  };

  let currLocation = "";

  Object.keys(allRoutes).some((key) => {
    const match = matchPath(link, {
      path: allRoutes[key].links[lang],
      exact: allRoutes[key].exact,
      strict: false,
    });

    if (match) {
      currLocation = key;
      return true;
    }
    return false;
  });

  return currLocation && currLocation;
};

export const getCurrentRoute = async (link, _lang) => {
  const allRoutes = {
    ...route,
    ...guestRoute,
    ...loggedRoute,
    ...inactiveLoggedRoute,
  };

  let currLocation = {};
  let routeName = "";
  let routeType = "";

  Object.keys(allRoutes).some((route) => {
    return Object.keys(allRoutes[route]).some((key) => {
      const match = matchPath(link, {
        path: _lang
          ? allRoutes[route][key].links[_lang]
          : allRoutes[route][key].links,
        exact: allRoutes[route][key].exact,
        strict: false,
      });

      if (match) {
        routeType = route;
        routeName = key;
        currLocation = match;
        return true;
      }
      return false;
    });
  });

  return (
    currLocation &&
    routeName && {
      name: routeName,
      route: allRoutes[routeType][routeName],
      match: currLocation,
    }
  );
};

export const redirectLoggedUser = async (
  user,
  lang,
  noRedirectOnMatch = false
) => {
  let url = "";
  let currentRoute = await getCurrentRouteName(window.location.pathname, lang);
  let redirectRoute = "";

  if (user.approvalStatus === 6) {
    url = inactiveLoggedRoute.completion.links[lang].replace(
      ":step?",
      STEPS[0].slug[lang]
    );
    redirectRoute = "completion";
  } else if (user.approvalStatus === 7) {
    url = inactiveLoggedRoute.checking.links[lang];
    redirectRoute = "checking";
  } else if (user.approvalStatus === 8) {
    url = inactiveLoggedRoute.agreement.links[lang];
    redirectRoute = "agreement";
  } else if (user.approvalStatus === 1) {
    url = loggedRoute.dashboard.links[lang];
    redirectRoute = "dashboard";
  } else {
    url = "/";
    redirectRoute = "home";
  }

  if (currentRoute === redirectRoute && noRedirectOnMatch)
    url = window.location.pathname;

  return url;
};

export const isTouchDevice = () =>
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;
