import React from "react";
import { withLocalize } from "react-localize-redux";
import { inactiveLoggedRoute } from "../../../app/routes";
import API, { headers } from "../../../utils/API";
import AccountCompletion from "../../AccountCompletion";
import DocumentsStep from "./components/DocumentsStep";
import InformationStep from "./components/InformationStep";
import TypeStep from "./components/TypeStep";

import Axios from "axios";

export const STEPS = [
  {
    order: 1,
    name: "supplier_type",
    slug: { en: "supplier-type", tr: "saglayici-turu" },
  },
  {
    order: 2,
    name: "supplier_information",
    slug: { en: "supplier-information", tr: "saglayici-bilgileri" },
  },
  {
    order: 3,
    name: "supplier_documents",
    slug: { en: "supplier-documents", tr: "saglayici-belgeleri" },
  },
];

const Information = ({ translate, match, history, lang, user }) => {
  const [currentStep, setCurrentStep] = React.useState(STEPS[0]);
  const [information, setInformation] = React.useState({
    type: 0,
    country_id: "212",
  });

  React.useEffect(() => {
    if (user.approvalStatus === 6) {
      const information = localStorage.getItem("supplier_information");

      const cancelSource = Axios.CancelToken.source();

      if (information) {
        setInformation(JSON.parse(information));
      } else {
        API.get("account", {
          headers: {
            ...headers,
            Authorization: `${user._tokenType} ${user._token}`,
          },
          cancelToken: cancelSource.token,
        }).then(({ data: res }) => {
          const { data } = res;

          const userInfo = {
            type: data.contact.company_type,
            authorized_person_first_name: data.authorized_first_name,
            authorized_person_last_name: data.authorized_last_name,
            authorized_phone: data.authorized_phone,
            authorized_email: data.authorized_email,
            idn: data.idn,
            supplier_name: data.supplier_name,
            company_name: data.contact.company_name,
            address_line: data.contact.address_line,
            country_id: data.contact.country.id,
            city_id: data.contact.city.id,
            state_id: data.contact.state.id,
            zip: data.contact.zip,
            tax_administration: data.tax_administration,
            tax_number: data.tax_number,
            bank_iban: data.bank_iban,
          };

          setInformation(userInfo);
        });
      }

      return () => {
        if (!localStorage.getItem("supplier_information"))
          cancelSource.cancel("Operation canceled by the user.");
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const step = STEPS.find(
      (step) => step.slug[lang || "tr"] === match.params.step
    );

    if (step) {
      setCurrentStep(step);
    }
  }, [match.params.step, lang]);

  React.useEffect(() => {
    localStorage.setItem("supplier_information", JSON.stringify(information));
  }, [information]);

  React.useEffect(() => {
    if (!information.type && currentStep.order > 1) {
      history.push(
        inactiveLoggedRoute.completion.links[lang].replace(
          ":step?",
          STEPS[0].slug[lang]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, information.type]);

  // React.useEffect(() => {
  //   setInformation((prevInformation) => ({ type: prevInformation.type }));
  // }, [information.type]);

  const mutualProps = {
    translate,
    history,
    lang,
    setInformation,
    information,
    user,
  };

  return (
    <AccountCompletion
      pageTitle={translate(
        `account_completion.information.${currentStep.name}.title`
      )}
      user={user}
      lang={lang}
    >
      <div
        className="position-relative pages-holder d-flex justify-content-start align-items-start"
        data-step={currentStep?.order}
      >
        <div className="page">
          <TypeStep {...mutualProps} />
        </div>

        <div className="page">
          <InformationStep {...mutualProps} />
        </div>

        <div className="page">
          <DocumentsStep {...mutualProps} />
        </div>
      </div>
    </AccountCompletion>
  );
};

export default withLocalize(Information);
