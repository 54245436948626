import React from "react";

function ProductDetails({ product, translate, handleEditProduct }) {
  const status =
    product.status === 3
      ? translate("products.on_hold")
      : product.status === 1
      ? translate("products.published")
      : translate("products.draft");
  return (
    <div className="product-detail">
      <div className="image position-relative d-flex justify-content-center align-items-center">
        {product.images && product.images.length ? (
          <img
            src={product.images[0].url}
            alt={`${product.product_name} Cover`}
          />
        ) : (
          ""
        )}
        <div className="status position-absolute">
          {translate("globalTranslations.status")}: {status}
        </div>
      </div>
      <div className="info d-flex flex-column align-items-center">
        <div className="exp-title">
          <h6 className="not-selectable">
            {translate("products.product_info")}:
          </h6>
          <span className="selectable">{product.product_name}</span>
        </div>
        <div className="exp-info">
          <h6 className="not-selectable">
            {translate("products.product_code")}:
          </h6>
          <span className="selectable">{product.product_code}</span>
        </div>
        <div className="exp-info">
          <h6 className="not-selectable">
            {translate("products.created_date")}:
          </h6>
          <span className="selectable">{product.date_created}</span>
        </div>
        <div className="exp-info">
          <h6 className="not-selectable">{translate("products.category")}:</h6>
          <span className="selectable">{product.main_category.name}</span>
        </div>
        <div className="exp-info">
          <h6 className="not-selectable">
            {translate("products.sub_category")}:
          </h6>
          <span className="selectable">{product.sub_category.name}</span>
        </div>
        {product.price_options.length ? (
          <div className="exp-info">
            <h6 className="not-selectable">{translate("products.price")}:</h6>
            <span className="selectable">
              {product.price_options[0].price_formatted}
            </span>
          </div>
        ) : (
          ""
        )}

        <button
          className="button-secondary close-button mt-5 w-100"
          onClick={(e) => handleEditProduct(e, product, 1)}
        >
          {translate("products.update_pricing")}
        </button>

        {product?.schedule?.booking_type !== 3 && (
          <button
            className="button-secondary close-button mt-2 w-100"
            onClick={(e) => handleEditProduct(e, product, 2)}
          >
            {translate("products.update_availability")}
          </button>
        )}
      </div>
    </div>
  );
}

export default ProductDetails;
