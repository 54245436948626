import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import toastr from "toastr";

import ProfileTemplate from "./ProfileTemplate";

import styles from "./Profile.module.scss";
import { handleErrors, seeHidePassword } from "../utils/helper";
import API, { headers } from "../utils/API";
import Helmet from "react-helmet";
import is from "is_js";

class ChangePassword extends Component {
  state = {
    currPassword: "",
    newPassword: "",
    newPassConfirm: "",
    seePass: { currPass: false, newPass: false, newPassConfirm: false },
    isSending: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.newPassword !== this.state.newPassConfirm) {
      toastr.clear();
      toastr.error(
        this.props.translate("change_password.passwords_dont_match"),
        "",
        {
          progressBar: true,
        }
      );
      return false;
    }

    this.setState({
      isSending: true,
    });

    const params = {
      old_password: this.state.currPassword,
      new_password: this.state.newPassword,
    };

    const config = {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    };

    API.post("password/change", params, config)
      .then((response) => {
        toastr.clear();
        toastr.success(response.data.info.message, "", {
          progressBar: true,
        });

        this.setState({
          currPassword: "",
          newPassword: "",
          newPassConfirm: "",
          isSending: false,
        });
      })
      .catch((error) => {
        handleErrors(error);
        this.setState({ isSending: false });
      });
  };

  render() {
    const { translate } = this.props;
    return (
      <ProfileTemplate>
        <Helmet>
          <title>{translate("change_password.change_password")}</title>
        </Helmet>
        <div className={styles.pageContent}>
          <div className="row mb-2">
            <div className="col">
              <h3 className={styles.pageTitle}>
                {translate("change_password.change_password")}
              </h3>
            </div>
          </div>
          <div className="row mt-3 mt-md-5">
            <div className="col">
              <div className="form-group position-relative sliding-label">
                <input
                  type={this.state.seePass.currPass ? `text` : `password`}
                  name="currPassword"
                  value={this.state.currPassword}
                  onChange={this.handleChange}
                  className={`form-control ${
                    this.state.currPassword !== "" && `filled`
                  } ${is.safari() ? `safari-fix` : ``}`}
                  id="currPassword"
                />
                <label htmlFor="currPassword" className="position-absolute">
                  <span>{translate("change_password.current_password")}</span>
                </label>
                <button
                  className="btn position-absolute"
                  style={{ bottom: "7px", right: 0 }}
                  type="button"
                  onClick={() =>
                    this.setState({
                      seePass: seeHidePassword(this.state.seePass, "currPass"),
                    })
                  }
                >
                  <i
                    className={`far ${
                      this.state.seePass.currPass ? `fa-eye-slash` : `fa-eye`
                    } text-muted`}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group position-relative sliding-label">
                <input
                  type={this.state.seePass.newPass ? `text` : `password`}
                  name="newPassword"
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  className={`form-control ${
                    this.state.newPassword !== "" && `filled`
                  } ${is.safari() ? `safari-fix` : ``}`}
                  id="newPassword"
                />
                <label htmlFor="newPassword" className="position-absolute">
                  <span>{translate("change_password.new_password")}</span>
                </label>
                <button
                  className="btn position-absolute"
                  style={{ bottom: "7px", right: 0 }}
                  type="button"
                  onClick={() =>
                    this.setState({
                      seePass: seeHidePassword(this.state.seePass, "newPass"),
                    })
                  }
                >
                  <i
                    className={`far ${
                      this.state.seePass.newPass ? `fa-eye-slash` : `fa-eye`
                    } text-muted`}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group position-relative sliding-label">
                <input
                  type={this.state.seePass.newPassConfirm ? `text` : `password`}
                  name="newPassConfirm"
                  value={this.state.newPassConfirm}
                  onChange={this.handleChange}
                  className={`form-control ${
                    this.state.newPassConfirm !== "" && `filled`
                  } ${is.safari() ? `safari-fix` : ``}`}
                  id="newPassConfirm"
                />
                <label htmlFor="newPassConfirm" className="position-absolute">
                  <span>{translate("change_password.new_password_again")}</span>
                </label>
                <button
                  className="btn position-absolute"
                  style={{ bottom: "7px", right: 0 }}
                  type="button"
                  onClick={() =>
                    this.setState({
                      seePass: seeHidePassword(
                        this.state.seePass,
                        "newPassConfirm"
                      ),
                    })
                  }
                >
                  <i
                    className={`far ${
                      this.state.seePass.newPassConfirm
                        ? `fa-eye-slash`
                        : `fa-eye`
                    } text-muted`}
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col">
              <section className={styles.thingsToConsiderForPassword}>
                <strong className="selectable">
                  {translate("change_password.guide_title")}
                </strong>
                {translate("change_password.guide_content")}
              </section>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6 offset-3">
              <button
                className="button-primary w-100"
                onClick={this.handleSubmit}
                disabled={
                  this.state.isSending ||
                  !this.state.currPassword ||
                  !this.state.newPassword
                }
              >
                {translate("globalTranslations.save")}
              </button>
            </div>
          </div>
        </div>
      </ProfileTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(ChangePassword));
