import React from "react";

export default function CompletePopup(props) {
  const {
    positions,
    translate,
    confirmationCode,
    bookingID,
    bookingStatus,
    handleChange,
    handleCloseAllPopups,
    handleConfirmAction
  } = props;

  return (
    <div
      className="cancel-popup position-fixed"
      style={{
        top: positions.approve.top,
        left: positions.approve.left,
        transform: `translateX(calc(-100% + ${positions.approve.width}px))`
      }}
    >
      <div
        className="overlay"
        onClick={e => handleCloseAllPopups(e, "completeModal", true)}
      ></div>
      <div className="popup-holder">
        <div className="row mb-3">
          <div className="col">
            <h6 className="text-center">
              {translate("bookings.complete_title")}
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="confirmationCode"
                value={confirmationCode}
                onChange={handleChange}
                className={`form-control ${confirmationCode !== "" &&
                  `filled`}`}
                id="confirmationCode"
                required
              />
              <label htmlFor="reason" className="position-absolute">
                <span>{translate("bookings.confirmation_code")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <button
              className="button-primary w-100"
              onClick={e =>
                handleConfirmAction("complete", {
                  id: bookingID,
                  code: confirmationCode,
                  status_id: bookingStatus
                })
              }
            >
              {translate("bookings.complete")}
            </button>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <button
              className="button-transparent w-100"
              onClick={e => handleCloseAllPopups(e, "completeModal", true)}
            >
              {translate("bookings.do_nothing")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
