import App from "./App";
import HelpCenter from "../help-center/HelpCenter";
import Products from "../product/Products";
import LandingPage from "./LandingPage";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import CreatePassword from "../auth/CreatePassword";
import Profile from "../profile/Profile";
import NotificationSettings from "../profile/NotificationSettings";
import ChangePassword from "../profile/ChangePassword";
import Documents from "../profile/Documents";
import Insurance from "../profile/Insurance";
import CreateProduct from "../product/create/CreateProduct";
import Bookings from "../bookings/Bookings";
import HelpDetail from "../help-center/HelpDetail";
import BookingDetails from "../bookings/BookingDetails";
import BookingTypeTab from "../bookings/BookingTypeTab";
import ProductTypeTab from "../product/ProductTypeTab";
import ProductDetails from "../product/ProductDetails";
import Feedbacks from "../profile/Feedbacks";
import Notifications from "../profile/Notifications";
import Reviews from "../reviews/Reviews";
import Finance from "../finance/Finance";
import ContactUs from "../contact-us/ContactUs";
import CompleteAccount from "../profile/CompleteAccount";
import ContractPage from "../profile/ContractPage";
import Information from "../account-completion/pages/Information/Information";
import Checks from "../account-completion/pages/Checks/Checks";
import Agreement from "../account-completion/pages/Agreement/Agreement";
import Tickets from "../product/Tickets";

export const route = {
  home: {
    id: 1,
    property: LandingPage,
    links: { en: "/", tr: "/" },
    name: "home",
    exact: true,
    onNav: false,
    noNav: true,
  },
  helpCenter: {
    id: 2,
    property: HelpCenter,
    links: { en: "/help-center", tr: "/yardim-merkezi" },
    name: "help_center",
    exact: false,
    onNav: false,
    noNav: true,
    noMobileNav: true,
  },
  helpDetail: {
    id: 3,
    property: HelpDetail,
    links: { en: "/help-center/:cat/:name", tr: "/yardim-merkezi/:cat/:name" },
    name: "help_detail",
    exact: false,
    onNav: false,
    noNav: true,
  },
  contactUs: {
    id: 4,
    property: ContactUs,
    links: { en: "/contact-us", tr: "/bize-ulasin" },
    exact: true,
    onNav: false,
  },
};

export const loggedRoute = {
  dashboard: {
    id: 1,
    property: App,
    links: { en: "/dashboard", tr: "/ozet" },
    name: "dashboard",
    exact: false,
    onNav: true,
  },
  bookings: {
    id: 2,
    property: Bookings,
    links: { en: "/bookings", tr: "/rezervasyonlar" },
    name: "bookings",
    exact: false,
    onNav: true,
  },
  createProduct: {
    id: 3,
    property: CreateProduct,
    links: {
      en: "/products/add-product/:id/:step?",
      tr: "/urunler/urun-ekle/:id/:step?",
    },
    name: "add_new_experience",
    exact: true,
    onNav: false,
    onWith: ["products"],
    noNav: true,
    icon: (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 0C19.4034 0 25 5.59657 25 12.5C25 19.4034 19.4034 25 12.5 25C5.59657 25 0 19.4034 0 12.5C0 5.59657 5.59657 0 12.5 0ZM12.5153 6.12245C12.9295 6.12245 13.2653 6.45824 13.2653 6.87245V11.7347H18.1276C18.5418 11.7347 18.8776 12.0705 18.8776 12.4847V12.5153C18.8776 12.9295 18.5418 13.2653 18.1276 13.2653H13.2653V18.1276C13.2653 18.5418 12.9295 18.8776 12.5153 18.8776H12.4847C12.0705 18.8776 11.7347 18.5418 11.7347 18.1276V13.2653H6.87245C6.45824 13.2653 6.12245 12.9295 6.12245 12.5153V12.4847C6.12245 12.0705 6.45824 11.7347 6.87245 11.7347H11.7347V6.87245C11.7347 6.45824 12.0705 6.12245 12.4847 6.12245H12.5153Z"
          fill="white"
        />
      </svg>
    ),
  },
  editProduct: {
    id: 4,
    property: CreateProduct,
    links: {
      en: "/products/edit-product/:id/:step?",
      tr: "/urunler/urun-duzenle/:id/:step?",
    },
    name: "edit_experience",
    exact: true,
    onNav: false,
    noNav: true,
  },
  cloneProduct: {
    id: 5,
    property: CreateProduct,
    links: {
      en: "/products/copy-product/:id/:step?",
      tr: "/urunler/urun-kopyala/:id/:step?",
    },
    name: "clone_experience",
    exact: true,
    onNav: false,
    noNav: true,
  },
  sendProduct: {
    id: 6,
    property: CreateProduct,
    links: { en: "thanks", tr: "tesekkurler" },
    name: "send_product",
    exact: true,
    onNav: false,
  },
  productDetails: {
    id: 7,
    property: ProductDetails,
    links: {
      en: "/products/details/:id/:product",
      tr: "/urunler/detaylar/:id/:product",
    },
    name: "product_details",
    exact: false,
    onNav: false,
  },
  products: {
    id: 8,
    property: Products,
    links: { en: "/products", tr: "/urunler" },
    name: "products",
    exact: false,
    onNav: true,
  },
  tickets: {
    id: "auth-tickets",
    property: Tickets,
    links: { en: "/tickets", tr: "/talepler" },
    name: "tickets",
    exact: true,
    onNav: false,
    onWith: ["products"],
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_0_892)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.38293 0 0 5.38293 0 12C0 18.6171 5.38293 24 12 24C18.6171 24 24 18.6171 24 12C24 5.38293 18.6171 0 12 0ZM11.9999 18.9999C11.4479 18.9999 11 18.552 11 17.9999C11 17.4479 11.4479 17 11.9999 17C12.552 17 12.9999 17.4479 12.9999 17.9999C12.9999 18.552 12.552 18.9999 11.9999 18.9999V18.9999ZM13.5829 12.642C13.229 12.805 12.9999 13.162 12.9999 13.5511V14.0001C12.9999 14.552 12.553 15.0001 12 15.0001C11.447 15.0001 11.0001 14.552 11.0001 14.0001V13.5511C11.0001 12.384 11.686 11.3141 12.7451 10.8251C13.764 10.3561 14.4999 9.11102 14.4999 8.5C14.4999 7.12213 13.379 6.00006 12 6.00006C10.621 6.00006 9.50006 7.12213 9.50006 8.5C9.50006 9.05206 9.05292 9.50012 8.49994 9.50012C7.94696 9.50012 7.5 9.05206 7.5 8.5C7.5 6.0191 9.51892 4 12 4C14.4811 4 16.5 6.0191 16.5 8.5C16.5 9.85114 15.3279 11.8371 13.5829 12.642Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_0_892"
            x1="56.386"
            y1="5.60737"
            x2="5.54157"
            y2="-27.9992"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEB984" />
            <stop offset="1" stopColor="#E75B2B" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  createTicket: {
    id: "auth-create-ticket",
    property: CreateProduct,
    links: { en: "/tickets/create/:id?", tr: "/talepler/yeni/:id?" },
    name: "create_ticket",
    exact: true,
    onNav: false,
    noNav: true,
  },
  reviews: {
    id: 9,
    property: Reviews,
    links: { en: "/reviews", tr: "/degerlendirmeler" },
    name: "reviews",
    exact: false,
    onNav: true,
  },
  finance: {
    id: 10,
    property: Finance,
    links: { en: "/finance", tr: "/finans" },
    name: "finance",
    exact: false,
    onNav: true,
  },
  profile: {
    id: 11,
    property: Profile,
    links: { en: "/my-account", tr: "/hesabim" },
    name: "my_profile",
    exact: false,
    onNav: true,
  },
  profilePage: {
    id: 19,
    property: Profile,
    links: { en: "/my-account/:page", tr: "/hesabim/:page" },
    name: "profile_page",
    exact: false,
    onNav: false,
  },
  notificationSettings: {
    id: 12,
    property: NotificationSettings,
    links: { en: "/notification-settings", tr: "/bildirim-ayarlari" },
    name: "notification_settings",
    exact: false,
    onNav: false,
  },
  changePassword: {
    id: 13,
    property: ChangePassword,
    links: { en: "/change-password", tr: "/sifre-degistir" },
    name: "change_password",
    exact: false,
    onNav: false,
  },
  documents: {
    id: 14,
    property: Documents,
    links: { en: "/docs", tr: "/belgeler" },
    name: "docs",
    exact: false,
    onNav: false,
  },
  insurance: {
    id: 15,
    property: Insurance,
    links: { en: "/insurance", tr: "/sigorta" },
    name: "insurance",
    exact: false,
    onNav: false,
  },

  bookingDetails: {
    id: 16,
    property: BookingDetails,
    links: {
      en: "/bookings/details/:booking",
      tr: "/rezervasyonlar/detaylar/:booking",
    },
    name: "booking_details",
    exact: false,
    onNav: false,
  },

  feedbacks: {
    id: 17,
    property: Feedbacks,
    links: { en: "/feedback", tr: "/geri-bildirim" },
    name: "feedback",
    exact: false,
    onNav: false,
  },

  notifications: {
    id: 18,
    property: Notifications,
    links: { en: "/notifications", tr: "/bildirimler" },
    name: "all_notifications",
    exact: false,
    onNav: false,
  },
  completeAccount: {
    id: 20,
    property: CompleteAccount,
    links: { en: "/complete-account", tr: "/hesap-tamamla" },
    name: "complete_account",
    exact: false,
    onNav: false,
  },
  contractPage: {
    id: 21,
    property: ContractPage,
    links: { en: "/contract", tr: "/sozlesme" },
    name: "contract_page",
    exact: false,
    onNav: false,
  },
};

export const inactiveLoggedRoute = {
  completion: {
    id: 1,
    property: Information,
    links: { en: "/account-completion/:step?", tr: "/hesap-tamamlama/:step?" },
    name: "account_completion",
    exact: false,
    onNav: false,
    noNav: true,
    noMobileNav: true,
  },
  checking: {
    id: 2,
    property: Checks,
    links: { en: "/account-checking", tr: "/hesap-kontrolu" },
    name: "account_checking",
    exact: false,
    onNav: false,
    noNav: true,
    noMobileNav: true,
  },
  agreement: {
    id: 3,
    property: Agreement,
    links: { en: "/agreement-approval", tr: "/sozlesme-onayi" },
    name: "agreement_approval",
    exact: false,
    onNav: false,
    noNav: true,
    noMobileNav: true,
  },
};

export const guestRoute = {
  login: {
    id: 1,
    property: Login,
    links: { en: "/login", tr: "/giris" },
    name: "login",
    exact: false,
    onNav: false,
    noNav: true,
  },
  register: {
    id: 2,
    property: Register,
    links: { en: "/register", tr: "/kaydol" },
    name: "register",
    exact: false,
    onNav: false,
    noNav: true,
  },
  forgotPassword: {
    id: 3,
    property: ForgotPassword,
    links: { en: "/forgot-password", tr: "/sifre-unuttum" },
    name: "Forgot Password",
    exact: false,
    onNav: false,
    noNav: true,
  },
  resetPassword: {
    id: 4,
    property: ResetPassword,
    links: {
      en: "/reset-password/:email/:token/",
      tr: "/sifre-sifirlama/:email/:token/",
    },
    name: "Reset Password",
    exact: false,
    onNav: false,
    noNav: true,
  },
  createPassword: {
    id: 5,
    property: CreatePassword,
    links: {
      en: "/create-password/:email/:token",
      tr: "/sifre-olustur/:email/:token",
    },
    name: "Create Password",
    exact: false,
    onNav: false,
    noNav: true,
  },
};

export const externalRoute = {
  bookingTypes: {
    id: 1,
    property: BookingTypeTab,
    links: { en: "/bookings/:type", tr: "/rezervasyonlar/:type" },
    name: "booking_types",
    exact: false,
    onNav: false,
  },
  productTypes: {
    id: 2,
    property: ProductTypeTab,
    links: { en: "/products/:type", tr: "/urunler/:type" },
    name: "product_types",
    exact: false,
    onNav: false,
  },
};
