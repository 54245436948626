import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import authReducer from "./auth/authReducer";
import { localizeReducer } from "react-localize-redux";
import notificationReducer from "./notification/notificationReducer";
import bookingReducer from "./bookings/bookingReducer";
import createProductReducer from "./product/create/createProductReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  booking: bookingReducer,
  localize: localizeReducer,
  routing: routerReducer,
  createProduct: createProductReducer,
});

export default rootReducer;
