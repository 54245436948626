export const get = tabs => {
  return {
    type: "GET",
    tabs
  };
};

export const updateCount = (oldStatus, newStatus) => {
  return {
    type: "UPDATE_COUNT",
    oldStatus,
    newStatus
  };
};

export const search = bookings => {
  return {
    type: "SEARCH",
    bookings
  };
};
