import React from "react";

export default function ErrorArea(props) {
  return (
    <div
      className="alert alert-danger d-flex align-items-center justify-content-start"
      role="alert"
      style={{ ...props.styles }}
    >
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.575 11.3214C17.1196 12.29 17.1161 13.4488 16.568 14.421C16.0165 15.4003 15.0356 15.9851 13.943 15.9851H3.02389C1.93477 15.9851 0.960848 15.4075 0.41629 14.4461C-0.13525 13.4739 -0.138741 12.308 0.405818 11.3286L5.87933 1.58869C6.4204 0.616492 7.39781 0.0317383 8.49391 0.0317383C9.59001 0.0317383 10.5674 0.612904 11.1085 1.59228L16.575 11.3214ZM13.9395 14.5752C14.5399 14.5752 15.0775 14.256 15.3812 13.7178C15.6814 13.1833 15.6849 12.5519 15.3916 12.0282L9.92163 2.29183C9.62491 1.7573 9.09083 1.43802 8.49391 1.43802C7.8935 1.43802 7.3629 1.75371 7.06619 2.28824L1.59268 12.0246C1.29247 12.5627 1.29596 13.2012 1.59617 13.7358C1.89288 14.2631 2.42697 14.5752 3.0204 14.5752H13.9395ZM8.49042 11.2425C8.01567 11.2425 7.61773 11.6515 7.61773 12.1394C7.61773 12.6273 8.01567 13.0362 8.49042 13.0362C8.94771 13.0362 9.36311 12.6273 9.34216 12.1609C9.36311 11.6479 8.96865 11.2425 8.49042 11.2425ZM7.60027 5.92591C7.60027 5.45595 7.85859 5.06851 8.27399 4.94654C8.68939 4.84609 9.10479 5.05057 9.28282 5.45237C9.34216 5.59586 9.36311 5.73936 9.36311 5.9008C9.35263 6.12501 9.3378 6.34923 9.32296 6.57344C9.30813 6.79766 9.29329 7.02187 9.28282 7.24609C9.26362 7.59228 9.24355 7.93847 9.22348 8.28466C9.2034 8.63084 9.18333 8.97703 9.16413 9.32322C9.14319 9.54923 9.14319 9.75371 9.14319 9.97613C9.12574 10.3456 8.84647 10.629 8.49042 10.629C8.13436 10.629 7.85859 10.3636 7.83764 9.99766C7.80797 9.45775 7.7783 8.92322 7.74863 8.38869C7.71896 7.85416 7.68929 7.31963 7.65962 6.77972C7.64914 6.63802 7.63954 6.49542 7.62994 6.35281C7.62035 6.21021 7.61075 6.06761 7.60027 5.92591Z"
          fill="#EA3325"
        />
      </svg>

      <span
        style={{ color: "#EA3325", width: "calc(100% - 20px - 1rem)" }}
        className="ml-3"
      >
        {props.children}
      </span>
    </div>
  );
}
