import React from "react";

export default function SettingsNav(props) {
  const { translate } = props;
  return (
    <div className="row">
      <div className="col">
        <h3 className={props.styles.pageTitle}>
          {translate("profile.editing_company_profile")}
        </h3>
        <div className={`mt-5 ${props.styles.profileNavArea}`}>
          <nav className={props.styles.profileNav}>
            <a
              href="#!"
              className={`w-100 d-flex justify-content-between align-items-center ${props.styles.profileNavLink}`}
              onClick={e => props.handleSettingChange(e, "profile")}
            >
              <div className="setting-name">
                {translate("profile.editing_profile")}
              </div>
              <div className="arrow-icon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.40624 2.11523L12.1875 8.57358L5.40624 15.0319"
                    stroke="#B6B6B6"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
            <a
              href="#!"
              className={`w-100 d-flex justify-content-between align-items-center ${props.styles.profileNavLink}`}
              onClick={e => props.handleSettingChange(e, "authorized_person")}
            >
              <div className="setting-name">
                {translate("profile.authorized_person")}
              </div>
              <div className="arrow-icon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.40624 2.11523L12.1875 8.57358L5.40624 15.0319"
                    stroke="#B6B6B6"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
            <a
              href="#!"
              className={`w-100 d-flex justify-content-between align-items-center ${props.styles.profileNavLink}`}
              onClick={e => props.handleSettingChange(e, "billing_info")}
            >
              <div className="setting-name">
                {translate("profile.company_billing_info")}
              </div>
              <div className="arrow-icon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.40624 2.11523L12.1875 8.57358L5.40624 15.0319"
                    stroke="#B6B6B6"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
            <a
              href="#!"
              className={`w-100 d-flex justify-content-between align-items-center ${props.styles.profileNavLink}`}
              onClick={e => props.handleSettingChange(e, "bank_info")}
            >
              <div>{translate("profile.company_bank_info")}</div>
              <div className="arrow-icon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.40624 2.11523L12.1875 8.57358L5.40624 15.0319"
                    stroke="#B6B6B6"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
