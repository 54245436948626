import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import "./Auth.scss";

import authEN from "../translations/en/auth.json";
import authTR from "../translations/tr/auth.json";

import { guestRoute } from "../app/routes";
import Modal from "../utils/components/Modal";
import Helmet from "react-helmet";
import Form from "./Register/Form";

class Register extends Component {
  state = {
    completed: false,
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(authEN, "en");
    this.props.addTranslationForLanguage(authTR, "tr");
  }

  componentDidMount = () => {};

  handleModalToggle = (e) => {
    e.preventDefault();

    this.setState((prevState) => {
      return { completed: !prevState.completed };
    });

    if (!this.state.completed) {
      setTimeout(() => {
        this.props.history.push("/");
      }, 500);
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="container">
        <Helmet>
          <title>{translate("nav.apply")}</title>
        </Helmet>
        <div className="row">
          <div className="col col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
            <h3 className="page-title">{translate("register.page_title")}</h3>
            <section className="page-area">
              <Form
                translate={translate}
                sending={this.state.sending}
                setCompleted={() => this.setState({ completed: true })}
                history={this.props.history}
                lang={this.props.lang}
              />
              <div className="row">
                <div className="col">
                  <p className="applyLink mb-0 mb-md-3 mt-4 mt-md-5 w-100 text-center">
                    {translate("register.already_registered", {
                      link: (
                        <Link
                          to={
                            guestRoute["login"].links[
                              this.props.activeLanguage.code
                            ]
                          }
                        >
                          {translate("globalTranslations.click_here")}.
                        </Link>
                      ),
                    })}
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <CSSTransition
          in={this.state.completed}
          timeout={300}
          unmountOnExit
          classNames="modal"
        >
          <Modal handleToggle={this.handleModalToggle} id="successful">
            <div className="bl-modal-content apply-fix d-flex flex-column justify-content-center align-items-center">
              <div className="icon">
                <svg
                  width="108"
                  height="108"
                  viewBox="0 0 108 108"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.9 107.8C83.6681 107.8 107.8 83.6681 107.8 53.9C107.8 24.1318 83.6681 0 53.9 0C24.1318 0 0 24.1318 0 53.9C0 83.6681 24.1318 107.8 53.9 107.8Z"
                    fill="url(#paint0_linear)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M69.5655 28.2579L46.8906 68.1367L35.6027 52.3314L13.5596 53.0908C20.2432 60.2184 24.743 64.4322 31.5502 71.4457C36.6688 76.7248 40.736 83.9305 49.252 81.1526C56.124 78.9107 82.1817 47.0512 90.4696 37.7941L69.5655 28.2579Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="253.267"
                      y1="25.1864"
                      x2="24.8909"
                      y2="-125.763"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FEB984" />
                      <stop offset="1" stopColor="#E75B2B" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h4 className="title mt-3 text-center">
                {translate("register.application_confirmation_title")}
              </h4>
              <p
                className="content text-center mw-100"
                style={{ width: "350px" }}
              >
                {translate("register.application_confirmation_content")}
              </p>
            </div>

            <button
              className="button-primary close-button"
              onClick={(e) => this.handleModalToggle(e, "successful")}
            >
              {translate("globalTranslations.close")}
            </button>
          </Modal>
        </CSSTransition>
      </div>
    );
  }
}

export default withLocalize(Register);
