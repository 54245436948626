import React from "react";

export default function NoResult(props) {
  return (
    <div className="search-no-result">
      <svg
        width="125"
        height="125"
        viewBox="0 0 125 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.695 18.3047C131.102 42.7111 131.102 82.2889 106.695 106.695C82.2889 131.102 42.7111 131.102 18.3047 106.695C-6.10158 82.2889 -6.10158 42.7111 18.3047 18.3047C42.7111 -6.10158 82.2889 -6.10158 106.695 18.3047ZM59.2523 83.3528C45.9267 83.3528 35.1562 72.5067 35.1562 59.2545C35.1562 46.0024 46.0014 35.1562 59.2523 35.1562C72.5033 35.1562 83.3484 46.0024 83.3484 59.2545C83.3484 72.5067 72.5779 83.3528 59.2523 83.3528ZM59.2504 39.5273C48.4159 39.5273 39.5968 48.4209 39.5968 59.2564C39.5968 70.092 48.4159 78.9119 59.2504 78.9119C70.0849 78.9119 78.9777 70.092 78.9777 59.2564C78.9777 48.4209 70.0849 39.5273 59.2504 39.5273ZM85.2884 89.065L76.9742 80.8064C75.9111 79.7432 75.9111 78.0412 76.9742 76.9781C78.0373 75.9149 79.7391 75.9149 80.8022 76.9781L89.0464 85.1668C90.1095 86.2299 90.1095 87.932 89.0464 88.9951C88.6174 89.5593 87.9087 89.8438 87.2 89.8438C86.4913 89.8438 85.7826 89.5593 85.2884 89.065Z"
          fill="#E7E8E9"
        />
      </svg>
      <h3>{props.translate("bookings.no_search_result_title")}</h3>
      <p>{props.translate("bookings.no_search_result_content")}</p>
    </div>
  );
}
