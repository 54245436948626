import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import toastr from "toastr";
import { CSSTransition } from "react-transition-group";

import ProfileTemplate from "./ProfileTemplate";
import API, { headers } from "../utils/API";
import { getBase64, handleErrors } from "../utils/helper";

import Modal from "../utils/components/Modal";

import styles from "./Profile.module.scss";
import NewDocument from "./DocumentComponents/NewDocument";
import DocumentLists from "./DocumentComponents/DocumentLists";
import Helmet from "react-helmet";
import Axios from "axios";
import { update } from "../auth/authActions";

class Documents extends Component {
  state = {
    companyName: "",
    documentType: "",
    documentFile: "",
    documentName: "",
    documents: {},
    loading: true,
    newDocument: false,
    documentDetail: "",
    isSending: false,
    isDocumentDetailOn: false,
    isSaveButtonActive: false,
    uploadProgress: 0,
    isProgressActive: false,
    types: [],
  };

  cancelSource = Axios.CancelToken.source();

  APIconfigs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.setState({ uploadProgress: percentCompleted });
    },
  };

  componentDidMount() {
    API.get("docs", this.APIconfigs)
      .then((response) => {
        const { data } = response.data;

        const documents = data.map((data) => {
          return {
            id: data.id,
            name: data.name,
            url: data.file_url,
            fileType: data.file_type,
            date: data.date_created,
            type: data.type,
          };
        });

        this.setState({ documents, loading: false });
      })
      .catch((error) => handleErrors(error));

    API.get("docs/types", this.APIconfigs)
      .then(({ data: res }) => {
        const types = res.data.map((data) => ({ ...data, type: data.id }));

        this.setState({ types });
      })
      .catch((err) => handleErrors(err));
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => this.checkInputs(this.state.selectedPage)
    );
  };

  handleFileSelect = (e) => {
    const { files } = e.target;

    if (files.length) {
      const file = files[0];

      const k = 1024;
      const i = Math.floor(Math.log(file.size) / Math.log(k));
      const size =
        parseFloat((file.size / Math.pow(k, i)).toFixed(2)) +
        " " +
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][i];

      getBase64(file)
        .then((value) => {
          this.setState({
            documentFile: value,
            documentName: `${file.name} - ${size}`,
          });
        })
        .then(() => this.checkInputs(this.state.selectedPage));
    }
  };

  handleFileReset = () => {
    this.setState({ documentFile: "", documentName: "" }, () =>
      this.checkInputs(this.state.selectedPage)
    );
  };

  checkInputs = () => {
    let isSaveButtonActive = false;

    if (
      String(this.state.documentType) &&
      (String(this.state.documentType) !== "0" || this.state.companyName) &&
      this.state.documentFile
    )
      isSaveButtonActive = true;
    else isSaveButtonActive = false;

    setTimeout(() => this.setState({ isSaveButtonActive }), 20);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSending: true, isProgressActive: true });

    this.cancelSource = Axios.CancelToken.source();

    const documentParams = {
      name: this.state.companyName,
      type: parseInt(this.state.documentType),
      cover_file: this.state.documentFile,
    };

    if (
      String(documentParams.type) &&
      (String(documentParams.type) !== "0" || documentParams.name) &&
      documentParams.cover_file
    ) {
      API.post("docs", documentParams, {
        ...this.APIconfigs,
        cancelToken: this.cancelSource.token,
      })
        .then((response) => {
          API.get("account", { ...this.APIconfigs })
            .then((response) => {
              const { data } = response.data;

              const user = {
                ...this.props.user,
                approvedAccount: data.approved_account,
                approvalProgress: data.account_checklist,
              };

              this.props.update(user);
            })
            .catch((err) => handleErrors(err));

          const { data } = response.data;
          const documents = { ...this.state.documents };
          documents[data.id] = {
            id: data.id,
            name: data.name,
            url: data.file_url,
            date: data.date_created,
            type: data.type,
          };

          toastr.clear();
          toastr.success(response.data.info.message, "", {
            progressBar: true,
          });

          this.handleCloseModal(null, "newDocument");

          setTimeout(
            () =>
              this.setState({
                documents,
              }),
            750
          );
        })
        .catch((error) => {
          handleErrors(error);
          this.setState({ isSending: false, isProgressActive: false });
        });
    } else {
      toastr.error(this.props.translate("documents.new_doc_empty_err"));
      this.setState({ isSending: false, isProgressActive: false });
    }
  };

  seeDocumentDetail = (e, documentDetail) => {
    e.preventDefault();
    this.setState({ documentDetail });
  };

  handleCloseOnEsc = (e, type) => {
    if (e.keyCode === 27) this.handleCloseModal(e, type);
  };

  handleShowModal = (e = null, type, content = null) => {
    if (type === "documentDetail") {
      this.setState({ documentDetail: content });
      setTimeout(() => {
        this.setState({ isDocumentDetailOn: true });
      }, 310);
    }

    if (type === "newDocument") this.setState({ newDocument: true });

    document.addEventListener(
      "keydown",
      (e) => this.handleCloseOnEsc(e, type),
      false
    );
  };

  handleCloseModal = (e = null, type) => {
    if (e) e.preventDefault();

    this.cancelSource.cancel("Operation canceled by the user.");

    if (type === "documentDetail") {
      this.setState({
        isDocumentDetailOn: false,
      });

      setTimeout(() => {
        if (type === "documentDetail") this.setState({ documentDetail: "" });
      }, 310);
    }

    if (type === "newDocument") this.setState({ newDocument: false });

    setTimeout(
      () =>
        this.setState({ isSending: false }, () =>
          this.setState(
            {
              documentType: "",
              documentFile: "",
              documentName: "",
            },
            () =>
              this.setState({
                isSaveButtonActive: false,
                uploadProgress: 0,
                isProgressActive: false,
              })
          )
        ),
      750
    );

    document.removeEventListener("keydown", this.handleCloseOnEsc, false);
  };

  render() {
    const { translate } = this.props;

    const mutualProps = {
      styles: styles,
      translate: translate,
      handleSubmit: this.handleSubmit,
      handleFileSelect: this.handleFileSelect,
      handleChange: this.handleChange,
      isSending: this.state.isSending,
      handleFileReset: this.handleFileReset,
      uploadProgress: this.state.uploadProgress,
      isSaveButtonActive: this.state.isSaveButtonActive,
      isProgressActive: this.state.isProgressActive,
    };

    const listMutualProps = {
      styles: styles,
      translate: translate,
      handleToggle: this.handleShowModal,
      loading: this.state.loading,
      seeDocumentDetail: this.handleShowModal,
    };

    return (
      <ProfileTemplate>
        <Helmet>
          <title>{translate("documents.documents_and_insurances")}</title>
        </Helmet>
        <div className={styles.pageContent}>
          <div className="row mb-md-2">
            <div className="col">
              <h3 className={styles.pageTitle}>
                {translate("documents.documents_and_insurances")}
              </h3>
            </div>
          </div>
          <div className="row mt-1 mt-md-3">
            <DocumentLists
              {...listMutualProps}
              files={this.state.documents}
              id="newDocument"
              types={this.state.types}
            />
          </div>
        </div>

        <CSSTransition in={this.state.newDocument} timeout={300} unmountOnExit>
          <Modal
            handleToggle={(e) => this.handleCloseModal(e, "newDocument")}
            id="newDocument"
            classes="bl-document-modals"
            maxWidth={"630px"}
          >
            <NewDocument
              {...mutualProps}
              inputProps={{
                companyName: this.state.companyName,
                documentType: this.state.documentType,
                documentName: this.state.documentName,
              }}
              types={this.state.types.filter(
                (type) => this.props.user.supplierType in type.required_in
              )}
            />
          </Modal>
        </CSSTransition>

        <CSSTransition
          in={this.state.isDocumentDetailOn}
          timeout={300}
          unmountOnExit
        >
          <Modal
            handleToggle={(e) => this.handleCloseModal(e, "documentDetail")}
            id="documentDetail"
          >
            <div className="bl-modal-content scrollable">
              <img
                src={this.state.documentDetail}
                alt="Document Detail"
                className="w-100"
              />
            </div>
            <button
              className="button-primary close-button w-100 mt-4 mx-auto"
              onClick={(e) => this.handleCloseModal(e, "documentDetail")}
            >
              {translate("globalTranslations.close")}
            </button>
          </Modal>
        </CSSTransition>
      </ProfileTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (user) => dispatch(update(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Documents));
