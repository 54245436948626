import Axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { inactiveLoggedRoute } from "../../../../app/routes";
import API, { headers } from "../../../../utils/API";
import { handleErrors } from "../../../../utils/helper";
import { STEPS } from "../Information";

const TypeStep = ({
  translate,
  history,
  lang,
  information,
  setInformation,
  user,
}) => {
  const [types, setTypes] = React.useState([]);

  React.useEffect(() => {
    const cancelSource = Axios.CancelToken.source();

    API.get("account/types", {
      headers: {
        ...headers,
        Authorization: `${user._tokenType} ${user._token}`,
      },
      cancelToken: cancelSource.token,
    })
      .then(({ data: res }) => {
        const { data } = res;

        setTypes(data);
      })
      .catch((err) => handleErrors(err));

    return () => {
      cancelSource.cancel("Operation canceled by the user.");
    };
  }, [user._token, user._tokenType]);

  return (
    <div className="content-holder">
      <div className="row">
        <div className="col-12">
          <div className="types">
            {types
              .filter((type) => type.list)
              .map((type) => (
                <button
                  key={type.id}
                  className={`type d-flex justify-content-center align-items-center bg-transparent ${
                    information.type === type.id ? "selected" : ""
                  }`}
                  onClick={() => {
                    setInformation((prevInformation) => ({
                      ...prevInformation,
                      type: type.id,
                    }));
                  }}
                >
                  {type.name}
                </button>
              ))}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="other-type-text text-center">
            {translate(
              "account_completion.information.supplier_type.personal_account_link",
              {
                link: (
                  <Link
                    to={inactiveLoggedRoute.completion.links[lang].replace(
                      ":step?",
                      STEPS[1].slug[lang]
                    )}
                    onClick={() =>
                      setInformation((prevInformation) => ({
                        ...prevInformation,
                        type: 5,
                      }))
                    }
                  >
                    {translate(
                      "account_completion.information.supplier_type.personal_account_link_text"
                    )}
                  </Link>
                ),
              }
            )}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <button
            className="button-primary w-100"
            disabled={!information.type || information.type === 0}
            onClick={() =>
              history.push(
                inactiveLoggedRoute.completion.links[lang].replace(
                  ":step?",
                  STEPS[1].slug[lang]
                )
              )
            }
          >
            {translate("globalTranslations.continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TypeStep;
