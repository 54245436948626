import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  responseType: "json",
});

export const headers = {
  "Content-Type": "application/json",
};
