import React, { Component } from "react";

export default class Detail extends Component {
  render() {
    const { styles, content } = this.props;
    return (
      <div className={styles.Detail}>
        <h2 className={styles.detailTitle}>{content.title}</h2>
        <div
          className={styles.detailContent}
          dangerouslySetInnerHTML={{ __html: content.content }}
        ></div>
      </div>
    );
  }
}
