import React from "react";
import supplierTypes, { inputs } from "../../utils/db/supplierTypes";

const BillingInfo = ({
  styles,
  translate,
  inputProps,
  user,
  handleSettingChange,
  handleChange,
  cities,
  districts,
  inputValidation,
  handleSubmit,
  isSending,
  types,
  inputErrors = {},
}) => {
  const [selectedSupplierType, setSelectedSupplierType] = React.useState(null);

  React.useEffect(() => {
    if (inputProps.company_type) {
      setSelectedSupplierType(
        supplierTypes.find(
          (type) => String(type.id) === String(inputProps.company_type)
        )
      );
    }
  }, [inputProps.company_type]);

  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h3
              className={`d-flex align-items-end justify-content-between ${styles.pageTitle}`}
            >
              {translate("profile.company_billing_info")}
              <span
                className={styles.backButton}
                onClick={(e) => handleSettingChange(e, "")}
              >
                {translate("globalTranslations.go_back")}
              </span>
            </h3>
          </div>
        </div>
        <div className="row mt-3 mt-md-5">
          <div className="col">
            <div className="form-group position-relative sliding-label">
              <select
                name="company_type"
                id="company_type"
                value={inputProps.company_type}
                onChange={handleChange}
                className={`form-control custom-select ${
                  inputProps.company_type !== "" && `filled`
                }`}
                required
              >
                <option value={0} hidden>
                  &nbsp;
                </option>
                {types.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
              <label htmlFor="company_type" className="position-absolute">
                <span> {translate("profile.company_type")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          {selectedSupplierType?.inputs
            .filter((input) => inputs[input].relevancy.includes(2))
            .map((input, i) => {
              input = inputs[input];

              const props = {
                name: input.name,
                id: input.name,
                value: inputProps[input.name] || "",
                onChange: handleChange,
                required: !selectedSupplierType?.optional?.includes(input.id),
                tabIndex: i,
                onBlur:
                  "validation" in input
                    ? (e) => inputValidation(e, input.name)
                    : null,
              };

              let options = [];
              if (input.type === "select") {
                if (input.name === "country_id")
                  options = [{ id: "212", name: "Türkiye" }];
                else if (input.name === "city_id") options = cities;
                else if (input.name === "state_id") options = districts;
              }

              return (
                <div
                  key={input.id}
                  className={`col-12 ${`half` in input ? `col-md-6` : ``}`}
                >
                  <div className="form-group position-relative sliding-label">
                    {input.type && input.type === "select" ? (
                      <select
                        {...props}
                        className={`form-control custom-select ${
                          inputProps[input.name] &&
                          inputProps[input.name] !== "" &&
                          `filled`
                        } ${input.name in inputErrors ? `error` : ""}`}
                        disabled={!options.length || input.disabled}
                      >
                        <option value="" hidden>
                          &nbsp;
                        </option>
                        {options.map((option) => (
                          <option key={option.id} value={String(option.id)}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        {...props}
                        className={`w-100 form-control ${
                          inputProps[input.name] &&
                          inputProps[input.name] !== "" &&
                          `filled`
                        } ${input.name in inputErrors ? `error` : ""}`}
                      />
                    )}
                    <label htmlFor={input.name} className="position-absolute">
                      <span>
                        {translate(`profile.${input.name}`)}{" "}
                        {selectedSupplierType?.optional?.includes(input.id) &&
                          `(${translate("globalTranslations.optional")})`}
                      </span>
                    </label>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="row">
          <div className="col-12">
            <p className={`not-selectable ${styles.postCodeInfo}`}>
              {translate("profile.post_code_info", {
                link: (
                  <a
                    href="https://www.turkiye.gov.tr/ptt-posta-kodu-sorgulama"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translate("globalTranslations.click_here")}
                  </a>
                ),
              })}
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-6 offset-3">
            <button
              className="button-primary w-100"
              onClick={(e) => handleSubmit(e, "billing")}
              disabled={
                isSending ||
                !selectedSupplierType?.inputs
                  .filter(
                    (input) =>
                      inputs[input].relevancy.includes(2) &&
                      !selectedSupplierType?.optional?.includes(input)
                  )
                  ?.every((input) => inputProps[inputs[input].name] !== "")
              }
            >
              {translate("globalTranslations.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingInfo;
