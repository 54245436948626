import React from "react";

import styles from "./Switch.module.scss";

export default function Switch(props) {
  return (
    <div
      className={`d-flex align-items-start flex-column ${styles.switchHolder}`}
    >
      <div
        className={`d-flex align-items-center justify-content-between ${styles.settingsSwitch}`}
      >
        <p className="d-flex flex-column p-0 m-0">{props.children}</p>
        <input {...props.inputProps} className={styles.switcher} />
        <label
          htmlFor={props.inputProps.id}
          className={`position-relative d-flex align-items-center justify-content-center m-0 p-0 ${styles.switchHolder}`}
        >
          &nbsp;
        </label>
      </div>
      {props.sub ? (
        <small className={`mt-3 pt-2 ${styles.sub}`}>{props.sub}</small>
      ) : null}
    </div>
  );
}
