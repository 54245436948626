import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";

import { logout } from "../auth/authActions";
import { loggedRoute, route } from "../app/routes";

import styles from "./Profile.module.scss";

import supplierProfile from "./assets/supplier_profile.svg";
import notification from "./assets/notification.svg";
import password from "./assets/password.svg";
import document from "./assets/document.svg";
import helpCenter from "./assets/help_center.svg";
import feedback from "./assets/feedback.svg";
import logoutIcon from "./assets/logout.svg";
import { approvalProgressCalculator } from "../utils/helper";

const ProfileNav = (props) => {
  const { translate } = props;

  const navLinks = [
    {
      id: 1,
      name: translate("profile.editing_company_profile"),
      icon: supplierProfile,
      url: loggedRoute["profile"].links[props.activeLanguage.code],
    },
    {
      id: 4,
      name: translate("documents.documents_and_insurances"),
      icon: document,
      url: loggedRoute["documents"].links[props.activeLanguage.code],
    },
    {
      id: 2,
      name: translate("notification_settings.notification_settings"),
      icon: notification,
      url: loggedRoute["notificationSettings"].links[props.activeLanguage.code],
    },
    {
      id: 3,
      name: translate("change_password.change_password"),
      icon: password,
      url: loggedRoute["changePassword"].links[props.activeLanguage.code],
    },
    {
      id: 5,
      name: translate("nav.help_center"),
      icon: helpCenter,
      url: route["helpCenter"].links[props.activeLanguage.code],
    },
    {
      id: 6,
      name: translate("feedback.send_a_feedback"),
      icon: feedback,
      url: loggedRoute["feedbacks"].links[props.activeLanguage.code],
    },
  ];

  const items = navLinks.map((data) => {
    return (
      <NavLink
        key={data.id}
        to={data.url}
        className={`w-100 d-flex justify-content-start align-items-center ${styles.profileNavLink}`}
        activeClassName={styles.active}
        onClick={(e) => props.handleToggleNav(null)}
      >
        <div
          className={`d-flex align-items-center justify-content-center mr-2 ${styles.icon}`}
        >
          <img src={data.icon} alt={`${data.name} Icon`} />
        </div>
        <div className="w-100">{data.name}</div>
        <div className="arrow-icon">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={styles.arrowIcon}
              d="M5.40624 2.11523L12.1875 8.57358L5.40624 15.0319"
              stroke="#B6B6B6"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </NavLink>
    );
  });

  const approvalProgress = !props.user.approvedAccount
    ? approvalProgressCalculator(props.user.approvalProgress)
    : {};

  return (
    <div className={`${styles.profileNavArea}`}>
      <div
        className={`d-flex flex-column align-items-center ${styles.userArea}`}
      >
        <div
          className={`${styles.avatar} ${
            props.isProgressActive ? `request-progress` : ``
          }`}
          data-request-progress={`${props.uploadProgress}%`}
        >
          <img
            src={props.user.avatar}
            alt={`${props.user.supplierName} Avatar`}
            className={`h-100 ${props.isProgressActive ? `d-none` : `d-flex`}`}
          />
        </div>
        <h4 className={`mt-3 ${styles.supplierName}`}>
          {props.user.supplierName}
        </h4>
        <p className={`${styles.supplierDesc}`}>
          {/* #Company Profile Descriptions# */}
        </p>
      </div>
      {!props.user.approvedAccount ? (
        <div
          className={`d-flex justify-content-between align-items-center position-relative ${styles.approvalProgress}`}
          data-completed={String(approvalProgress.percentage === 100)}
        >
          <div
            className={`d-flex flex-column justify-content-center align-items-start ${styles.infoArea}`}
          >
            <div
              className={`text-uppercase d-flex justify-content-start align-items-center w-100 text-left ${styles.approvalText}`}
            >
              <Link
                to={
                  loggedRoute.completeAccount.links[props.activeLanguage.code]
                }
                className="stretched-link"
                onClick={(e) => props.handleToggleNav(null)}
              >
                {translate(
                  `globalTranslations.${
                    props.user.approvalStatus === 7
                      ? `account_inspection`
                      : `complete_account`
                  }`
                )}
              </Link>
            </div>
            <div
              className={`d-flex position-relative ${styles.progressBar}`}
              data-progress={approvalProgress.percentage}
            ></div>
          </div>
        </div>
      ) : (
        ``
      )}
      <nav className={`mt-2 d-flex flex-column w-100 ${styles.profileNav}`}>
        {items}
        <NavLink
          to="#!"
          className={`w-100 d-flex justify-content-start align-items-center ${styles.profileNavLink}`}
          onClick={(e) => {
            e.preventDefault();
            props.logout();
            props.history.push("/");
          }}
        >
          <div
            className={`d-flex align-items-center justify-content-center mr-2 ${styles.icon}`}
          >
            <img src={logoutIcon} alt="Logout Icon" />
          </div>
          {translate("nav.logout")}
        </NavLink>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(ProfileNav)));
