import React from "react";
import PropTypes from "prop-types";

import styles from "./Checkbox.module.scss";

export default function Checkbox(props) {
  return (
    <div className={styles.BucketlistCheckbox}>
      <input
        type="checkbox"
        {...props.inputProps}
        className={styles.Checkbox}
      />
      <label
        htmlFor={props.inputProps.id}
        className={`${styles.Label} d-flex align-items-center`}
      >
        <span>{props.children}</span>
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  inputProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  children: PropTypes.any.isRequired,
};
