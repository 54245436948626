import React from "react";
import { NavLink } from "react-router-dom";

export default function SidebarItem(props) {
  const { styles, route, title, slug, lang, cat } = props;

  const link = route.helpDetail.links[lang]
    .replace(":cat", cat)
    .replace(":name", slug);

  return (
    <div className={styles.content}>
      <NavLink to={link} activeClassName={styles.active}>
        {title}
      </NavLink>
    </div>
  );
}
