import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import toastr from "toastr";
import SingleProduct from "./SingleProduct";
import API, { headers } from "../utils/API";
import Axios from "axios";
import Pagination from "../utils/components/Pagination";

import { loggedRoute } from "../app/routes";

import "./Product.scss";
import ProductDetails from "./ProductDetails";
import Modal from "../utils/components/Modal";
import moment from "moment";
import { clear, create } from "./create/createProductActions";
import { handleErrors } from "../utils/helper";

class ProductTypeTab extends Component {
  state = {
    products: [],
    isLoading: true,
    page: 1,
    pageCount: 1,
    limit: 15,
    selectedProduct: 0,
    product: {},
    isProductDetailLoading: true,
    showTicketModal: false,
    ticketProductId: null,
    ticketInfo: {
      title: "",
      description: "",
    },
    isCreatingTicket: false,
  };

  source = Axios.CancelToken.source();

  APIconfigs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
    cancelToken: this.source.token,
  };

  componentDidMount() {
    this.getProducts();
    this.props.clear();
    localStorage.removeItem("product");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.type !== this.state.type ||
      prevState.page !== this.state.page
    ) {
      this.setState({ isLoading: true });
      this.getProducts();
    }

    if (
      prevState.showTicketModal !== this.state.showTicketModal &&
      !this.state.showTicketModal
    ) {
      this.setState({
        ticketInfo: { title: "", description: "" },
        ticketProductId: null,
      });
    }
  }

  componentWillUnmount() {
    this.source.cancel("Operation canceled by the user.");
  }

  getProducts = () => {
    const typeInText = this.props.match.params.type;
    const type =
      typeInText === "yayinda" || typeInText === "published"
        ? 1
        : typeInText === "inceleniyor" || typeInText === "on-hold"
        ? 3
        : 0;

    this.setState((prevState) => {
      return (
        prevState.type !== typeInText && {
          type: typeInText,
          page: 1,
          type_id: type,
        }
      );
    });
    API.get(
      `products/?status=${type}&limit=${this.state.limit}&page=${this.state.page}`,
      this.APIconfigs
    )
      .then((response) => {
        const { data, meta } = response.data;

        const pageCount = meta.last_page;

        this.setState({ products: data, pageCount });
      })
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.info("Request canceled:", error.message);
        } else {
          console.warn(error);
          this.setState({ isLoading: false });
        }
      });
  };

  handlePageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { page, action } = e.target.dataset;

    let newPage;
    if (page) {
      newPage = page;
    } else if (action) {
      newPage = action === "next" ? this.state.page + 1 : this.state.page - 1;
    } else if (e.target.nodeName === "svg") {
      const linkAction = e.target.parentElement.dataset.action;
      newPage =
        linkAction === "next" ? this.state.page + 1 : this.state.page - 1;
    } else if (e.target.nodeName === "path") {
      const linkAction = e.target.parentElement.parentElement.dataset.action;
      newPage =
        linkAction === "next" ? this.state.page + 1 : this.state.page - 1;
    }

    if (newPage) {
      this.setState({ page: parseInt(newPage) });
    }
  };

  handleModalToggle = (e, isClosing = false) => {
    e.preventDefault();

    if (isClosing) {
      this.setState({ selectedProduct: 0 }, () => {
        setTimeout(() => this.setState({ isProductDetailLoading: true }), 350);
      });
      return true;
    }

    const { dataset } = e.target;

    const selectedProduct = dataset && dataset.id ? parseInt(dataset.id) : 0;

    this.setState({ selectedProduct }, () => {
      if (this.state.selectedProduct !== 0) {
        API.get(
          `products/${this.state.selectedProduct}?encoded_image=1`,
          this.APIconfigs
        )
          .then(async (response) => {
            const { data } = response.data;

            toastr.clear();

            this.setState({ product: data, isProductDetailLoading: false });
          })
          .catch((error) => {
            if (error.response) {
              const { info } = error.response.data;
              toastr.clear();
              toastr.error(info.message);
            } else {
              console.log(error);
            }

            this.setState({ selectedProduct: 0 });
          });
      } else {
        setTimeout(() => this.setState({ isProductDetailLoading: true }), 350);
      }
    });
  };

  handleEditProduct = async (e, product, type) => {
    if (e) e.preventDefault();

    const questions = {};
    product.questions_answers.map((data) => {
      questions[data.id] = data.value;
      return true;
    });

    const map_coordinates = {
      lat: product.map_coordinates.latitude,
      lng: product.map_coordinates.longitude,
    };

    let index = 0;
    const priceInfo = product.price_options.map((data) => {
      return {
        id: index++,
        type: data.participant_type,
        price: data.price,
        label: data.custom_label,
        participantCount: data.custom_participant_count,
        isDiscounted: data.is_discount,
        discountedPrice: data.discounted_price,
        currency: data.currency,
      };
    });

    index = 0;
    const timeRanges =
      parseInt(product.schedule.time_range_type) === 3
        ? product.schedule.fixed_times.map((data) => {
            index++;
            return ([index] = data);
          })
        : "";

    const pictures = {};

    for (let i = 0; i < product.images.length; i++) {
      let img = product.images[i];
      pictures[img.id] = {
        id: img.id,
        base64: img.encoded,
        currPic: true,
      };
    }

    const params = {
      id: product.id,
      name: product.product_name,
      mainCategory: product.main_category.id,
      subCategory: product.sub_category.id,
      questions,
      howLong: product.duration,
      keywords: product.tags,
      pictures,
      video: product.video_url,
      address: product.location.address_line,
      country: "212",
      city: product.location.city.id,
      district: product.location.district.id,
      postalCode: product.location.zip,
      mapLocation: map_coordinates,
      priceInfo,
      reservationType: parseInt(product.schedule.booking_type),
      dateRange: parseInt(product.schedule.date_range_type),
      dateRanges: {
        start: moment(product.schedule.booking_start_date, "DD-MM-YYYY"),
        end: moment(product.schedule.booking_expiry_date, "DD-MM-YYYY"),
      },
      timeRange: product.schedule.time_range_type,
      timeRanges,
      isEditing: true,
      code: product.product_code,
      schedule: product.schedule,
    };

    this.props.create(params);
    localStorage.setItem(
      "product",
      JSON.stringify({
        id: params.id,
        code: params.code,
        isEditing: true,
        isCloning: false,
      })
    );

    this.props.history.push(
      loggedRoute.editProduct.links[this.props.activeLanguage.code]
        .replace(":id", product.product_code)
        .replace(":step", type === 1 ? "5" : "6")
    );
  };

  handleCloneProduct = async (e, product) => {
    if (e) e.preventDefault();

    const c = window.confirm(
      this.props.translate("products.are_you_sure_you_want_to_clone")
    );
    if (c) {
      const questions = {};
      product.questions_answers.map((data) => {
        questions[data.id] = data.value;
        return true;
      });

      const map_coordinates = {
        lat: product.map_coordinates.latitude,
        lng: product.map_coordinates.longitude,
      };

      let index = 0;
      const priceInfo = product.price_options.map((data) => {
        return {
          id: index++,
          type: data.participant_type,
          price: data.price,
          label: data.custom_label,
          participantCount: data.custom_participant_count,
          isDiscounted: data.is_discount,
          discountedPrice: data.discounted_price,
          currency: data.currency,
        };
      });

      index = 0;
      const timeRanges =
        parseInt(product.schedule.time_range_type) === 3
          ? product.schedule.fixed_times.map((data) => {
              index++;
              return ([index] = data);
            })
          : "";

      const pictures = {};

      for (let i = 0; i < product.images.length; i++) {
        let image = product.images[i];
        pictures[image.id] = {
          id: image.id,
          base64: image.encoded,
          currPic: true,
        };
      }

      const params = {
        id: product.id,
        name: product.product_name,
        mainCategory: product.main_category.id,
        subCategory: product.sub_category.id,
        questions,
        howLong: product.duration,
        keywords: product.tags,
        pictures,
        video: product.video_url,
        address: product.location.address_line,
        country: "212",
        city: product.location.city.id,
        district: product.location.district.id,
        postalCode: product.location.zip,
        mapLocation: map_coordinates,
        priceInfo,
        reservationType: parseInt(product.schedule.booking_type),
        dateRange: parseInt(product.schedule.date_range_type),
        dateRanges: {
          start: moment(product.schedule.booking_start_date, "DD-MM-YYYY"),
          end: moment(product.schedule.booking_expiry_date, "DD-MM-YYYY"),
        },
        timeRange: product.schedule.time_range_type,
        timeRanges,
        isCloning: true,
        code: product.product_code,
      };

      this.props.create(params);
      localStorage.setItem(
        "product",
        JSON.stringify({
          id: params.id,
          code: params.code,
          isCloning: true,
          isEditing: false,
        })
      );

      this.props.history.push(
        loggedRoute.cloneProduct.links[this.props.activeLanguage.code]
          .replace(":id", product.product_code)
          .replace(":step", 1)
      );
    }
  };

  handlePageChange = (e) => {
    e.preventDefault();

    const { dataset } = e.target;

    if (dataset.page && parseInt(this.state.page) !== parseInt(dataset.page))
      this.setState({ page: dataset.page });
    else if (dataset.action) {
      if (dataset.action === "prev" && this.state.page > 1)
        this.setState((prevState) => {
          return { page: prevState.page - 1 };
        });
      else if (
        dataset.action === "next" &&
        this.state.page < this.state.pageCount
      )
        this.setState((prevState) => {
          return { page: prevState.page + 1 };
        });
    }
  };

  handleToggleTicketModal = (e, id = null) => {
    e.preventDefault();
    this.setState((prevState) => ({
      showTicketModal: !prevState.showTicketModal,
      ticketProductId: id,
    }));
  };

  handleChangeTicketInfo = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ticketInfo: {
        ...prevState.ticketInfo,
        [name]: value,
      },
    }));
  };

  handleCreateTicket = (e) => {
    e.preventDefault();

    const { title, description } = this.state.ticketInfo;
    const product_id = this.state.ticketProductId;

    if (title && description && product_id) {
      this.setState({ isCreatingTicket: true });
      API.post("tickets", { title, description, product_id }, this.APIconfigs)
        .then(({ data: res }) => {
          if (res.info?.message) {
            toastr.clear();
            toastr.success(res.info.message);
          }
          this.setState({ showTicketModal: false });
        })
        .catch((err) => handleErrors(err))
        .finally(() => {
          this.setState({ isCreatingTicket: false });
        });
    }
  };

  render() {
    const { translate } = this.props;

    const products = this.state.isLoading ? (
      <p className="px-4 p-md-0">
        {translate("globalTranslations.loading")}
        ...
      </p>
    ) : this.state.products.length ? (
      this.state.products.map((product) => {
        return (
          <SingleProduct
            key={product.id}
            product={product}
            handleModalToggle={this.handleModalToggle}
            translate={translate}
            lang={this.props.activeLanguage?.code}
            handleToggleTicketModal={this.handleToggleTicketModal}
          />
        );
      })
    ) : (
      <p className="px-4 p-md-0">
        {this.state.type_id === 1
          ? translate("products.no_approved_product")
          : this.state.type_id === 3
          ? translate("products.no_on_hold_product")
          : translate("globalTranslations.no_record_found")}
      </p>
    );

    const productDetail = this.state.isProductDetailLoading ? (
      <p className="px-4 p-md-0">
        {translate("globalTranslations.loading")}
        ...
      </p>
    ) : (
      <ProductDetails
        product={this.state.product}
        translate={translate}
        handleEditProduct={this.handleEditProduct}
        handleCloneProduct={this.handleCloneProduct}
      />
    );
    return (
      <div className="row">
        <div className="col">{products}</div>
        <Pagination
          page={this.state.page}
          pageCount={this.state.pageCount}
          handlePageChange={this.handlePageChange}
        />

        <CSSTransition
          in={Boolean(this.state.selectedProduct)}
          timeout={0}
          unmountOnExit
        >
          <Modal
            handleToggle={this.handleModalToggle}
            id="product-detail"
            classes="product-detail-modal"
          >
            {productDetail}
          </Modal>
        </CSSTransition>

        <CSSTransition
          in={this.state.showTicketModal}
          timeout={0}
          unmountOnExit
        >
          <Modal
            handleToggle={this.handleToggleTicketModal}
            id="ticket-modal"
            classes="ticket-modal"
          >
            <form onSubmit={this.handleCreateTicket}>
              <div className="col-12">
                <div className="row">
                  <div className="col">
                    <h4 style={{ fontWeight: 700, fontSize: "18px" }}>
                      {translate("products.tickets.create_ticket")}
                    </h4>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="form-group position-relative sliding-label">
                      <input
                        type="text"
                        name="title"
                        value={this.state.ticketInfo.title}
                        onChange={this.handleChangeTicketInfo}
                        className={`form-control ${
                          this.state.ticketInfo.title !== "" && `filled`
                        }`}
                        id="ticketTitle"
                        required
                      />
                      <label
                        htmlFor="ticketTitle"
                        className="position-absolute"
                      >
                        <span>{translate("products.tickets.title")}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="form-group position-relative sliding-label">
                      <textarea
                        name="description"
                        value={this.state.ticketInfo.description}
                        onChange={this.handleChangeTicketInfo}
                        className={`form-control ${
                          this.state.ticketInfo.description !== "" && `filled`
                        }`}
                        id="ticketDescription"
                        required
                        style={{ height: "275px" }}
                      />
                      <label
                        htmlFor="ticketDescription"
                        className="position-absolute"
                      >
                        <span>{translate("products.tickets.description")}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      type="submit"
                      className="button-primary w-100"
                      disabled={
                        this.state.isCreatingTicket ||
                        !this.state.ticketInfo.title ||
                        !this.state.ticketInfo.description
                      }
                    >
                      {translate("products.tickets.create_button")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </CSSTransition>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    createdProduct: state.createProduct.product,
    oldProductState: state.createProduct.oldProductState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (product) => dispatch(create(product)),
    clear: () => dispatch(clear()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ProductTypeTab));
