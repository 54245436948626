import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import API, { headers } from "../utils/API";
import ProfileTemplate from "./ProfileTemplate";

import styles from "./Profile.module.scss";
import { approvalProgressCalculator, handleErrors } from "../utils/helper";
import { update } from "../auth/authActions";
import Loader from "../app/components/Loader";
import { Link, useHistory } from "react-router-dom";
import { loggedRoute } from "../app/routes";
import toastr from "toastr";
import { CSSTransition } from "react-transition-group";
import Modal from "../utils/components/Modal";

import modalDone from "./assets/modal-done.svg";

const ContractPage = ({ user, translate, update, activeLanguage }) => {
  const history = useHistory();
  const [contractContent, setContractContent] = useState("");
  const [isConfirmModalOn, setIsConfirmModalOn] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [response, setResponse] = useState(
    "Hesabınızı doğrudan incelemeye gönderebilir veya bilgileriniz kontrol ettikten sonra bu işlemi gerçekleştirebilirsiniz."
  );

  const source = Axios.CancelToken.source();

  const configs = {
    headers: {
      ...headers,
      Authorization: `${user._tokenType} ${user._token}`,
      cancelToken: source.token,
    },
  };

  useEffect(() => {
    if (!contractContent) {
      API.get("account/contract", configs)
        .then((r) =>
          setTimeout(() => setContractContent(r.data.data.contract), 200)
        )
        .catch((error) => handleErrors(error));
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  });

  const handleAcceptContract = () => {
    API.post(
      "account/contract",
      {},
      {
        headers: {
          ...headers,
          Authorization: `${user._tokenType} ${user._token}`,
        },
      }
    )
      .then((r) => {
        API.get("account", {
          headers: {
            ...headers,
            Authorization: `${user._tokenType} ${user._token}`,
          },
        })
          .then((r) => {
            const nUser = user;

            user["approvedAccount"] = r.data.data.approved_account;
            user["approvalProgress"] = r.data.data.account_checklist;

            update(nUser);
            setIsConfirmModalOn(true);
          })
          .catch((err) => handleErrors(err));
      })
      .catch((err) => handleErrors(err));
  };

  const approvalProgress = !user.approvedAccount
    ? approvalProgressCalculator(user.approvalProgress)
    : {};

  const sendForApproval = () => {
    if (approvalProgress.percentage === 100)
      API.post(
        "/account/send-to-review",
        {},
        {
          headers: {
            ...headers,
            Authorization: `${user._tokenType} ${user._token}`,
          },
        }
      )
        .then((r) => {
          const newUser = user;
          newUser["approvalStatus"] = 7;
          update(user);

          toastr.success(r.data.info.message);

          history.push(loggedRoute.completeAccount.links[activeLanguage.code]);
        })
        .catch((err) => handleErrors(err));
  };

  return contractContent === "" ? (
    <Loader />
  ) : (
    <>
      <ProfileTemplate>
        <Helmet>
          <title>{translate("complete_account.page_title")}</title>
        </Helmet>

        <div className={`${styles.pageContent} ${styles.completeAccount}`}>
          <div className="row mb-2">
            <div className="col">
              <h3 className={styles.pageTitle}>
                {translate("complete_account.contract_page_title")}
              </h3>
            </div>
          </div>
          <div className="row mt-3">
            <div
              className={`col selectable ${styles.contractContent}`}
              dangerouslySetInnerHTML={{ __html: contractContent }}
            ></div>
          </div>
          {!user.approvalProgress.is_contract_approved ? (
            <>
              <div className="row mt-3">
                <div className="col d-flex justify-content-center">
                  <button
                    className={`button-secondary close-button w-100 mt-4 ${styles.acceptContractButton}`}
                    onClick={handleAcceptContract}
                  >
                    {translate("complete_account.contract_modal_close_button")}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <Link
                    to={loggedRoute.completeAccount.links[activeLanguage.code]}
                    className={`button-transparent text-center cancel-button w-100 mt-4 ${styles.acceptContractButton}`}
                  >
                    {translate("globalTranslations.cancel")}
                  </Link>
                </div>
              </div>
            </>
          ) : (
            ``
          )}
        </div>
        <CSSTransition in={isConfirmModalOn} timeout={300} unmountOnExit>
          <Modal handleToggle={() => setIsConfirmModalOn(false)}>
            <div className="bl-modal-content product-fix">
              <div className="icon text-center">
                <img src={modalDone} alt="" />
              </div>
              <h4 className="title text-center mt-3 mb-3">
                {translate("complete_account.confirm_modal_title")}
              </h4>
              <p
                className="content text-center mb-2"
                style={{ fontSize: "16px" }}
              >
                {response}
              </p>
              <button
                className="button-primary opposite close-button mb-3 mt-3 pr-md-5 pl-md-5 w-100"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    loggedRoute.completeAccount.links[activeLanguage.code]
                  );
                }}
              >
                {translate("complete_account.check_info")}
              </button>
              <button
                className="button-primary close-button pl-md-5 pr-md-5 w-100"
                onClick={sendForApproval}
              >
                {translate("complete_account.modal_send_for_inspection")}
              </button>
            </div>
          </Modal>
        </CSSTransition>
      </ProfileTemplate>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (user) => dispatch(update(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ContractPage));
