import React from "react";

export default function InfoArea(props) {
  return (
    <div
      className="alert alert-info d-flex align-items-center justify-content-start"
      role="alert"
      style={{
        background: "#FFF6F3",
        borderColor: "#FFB399",
        borderWidth: "0.5px",
      }}
    >
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8.50005C0 3.81315 3.8131 0 8.5 0C13.1869 0 17 3.81315 17 8.50005C17 13.187 13.1869 17 8.5 17C3.8131 17 0 13.187 0 8.50005ZM1.54545 8.50005C1.54545 12.3348 4.66521 15.4545 8.5 15.4545C12.3347 15.4545 15.4545 12.3348 15.4545 8.50005C15.4545 4.66532 12.3348 1.54545 8.5 1.54545C4.66521 1.54545 1.54545 4.66532 1.54545 8.50005ZM8.4999 3.60606C7.93189 3.60606 7.4698 4.06846 7.4698 4.63683C7.4698 5.20468 7.93189 5.66667 8.4999 5.66667C9.0679 5.66667 9.52999 5.20468 9.52999 4.63683C9.52999 4.06846 9.0679 3.60606 8.4999 3.60606ZM7.72727 7.98485C7.72727 7.5581 8.07325 7.21212 8.5 7.21212C8.92675 7.21212 9.27273 7.5581 9.27273 7.98485V12.6212C9.27273 13.048 8.92675 13.3939 8.5 13.3939C8.07325 13.3939 7.72727 13.048 7.72727 12.6212V7.98485Z"
          fill="#E75B2B"
        />
      </svg>

      <span
        style={{ color: "#E75B2B", width: "calc(100% - 20px - 1rem)" }}
        className="ml-3"
      >
        {props.children}
      </span>
    </div>
  );
}
