import React, { Component } from "react";

import { login } from "./authActions";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";

import { seeHidePassword, validateInput } from "../utils/helper";
import API, { headers } from "../utils/API";

import "./Auth.scss";

import authEN from "../translations/en/auth.json";
import authTR from "../translations/tr/auth.json";

import { guestRoute, inactiveLoggedRoute, loggedRoute } from "../app/routes";
import ErrorArea from "../utils/components/ErrorArea";
import Helmet from "react-helmet";
import is from "is_js";

class Login extends Component {
  state = {
    username: "",
    password: "",
    _token: "",
    loginError: "",
    seePass: false,
    isSending: false,
    isCompleted: false,
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(authEN, "en");
    this.props.addTranslationForLanguage(authTR, "tr");
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.username !== this.state.username ||
      prevState.password !== this.state.password
    )
      this.setState({ isCompleted: this.handleCheckInputs() });
  };

  handleCheckInputs = () => {
    const { username, password } = this.state;
    if (username.length > 0 && password.length > 0) {
      if (validateInput("email", username)) {
        return true;
      }
    }
    return false;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isSending: true, loginError: "" });

    API.post(
      "user/login",
      {
        email: this.state.username,
        password: this.state.password,
      },
      { headers: { ...headers } }
    )
      .then((response) => {
        const { data } = response;

        const token = data.data.access_token;
        const token_type = data.data.token_type;
        const refresh_token = data.data.refresh_token;

        const config = {
          headers: { ...headers, Authorization: `${token_type} ${token}` },
        };

        API.get("account", config)
          .then((response) => {
            const { data } = response;

            const user = {
              id: data.data.id,
              firstName: data.data.authorized_first_name,
              lastName: data.data.authorized_last_name,
              email: data.data.authorized_email,
              avatar: data.data.avatar_url,
              lang: data.data.account_language,
              slug: data.data.slug,
              supplierName: data.data.supplier_name,
              notificationCount: data.data.notification_count,
              approvedAccount: data.data.approved_account,
              approvalProgress: data.data.account_checklist,
              approvalStatus: data.data.status_id,
              supplierType: data.data.contact.company_type,
              _tokenType: token_type,
              _token: token,
              _r_token: refresh_token,
            };

            this.props.login({
              ...user,
            });

            this.props.history.push(
              user.approvedAccount
                ? loggedRoute.dashboard.links[data.data.account_language]
                : inactiveLoggedRoute.completion.links[
                    data.data.account_language
                  ].replace(":step?", "saglayici-turu")
            );
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              if (error.response.data.status_code === 500) {
                this.setState({
                  loginError: <p>You are not authorized!</p>,
                });
              } else
                this.setState({
                  loginError: <p>{error.response.data.message}</p>,
                });
            }

            this.setState({ isSending: false });
          });
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            loginError: error.response.data.info.message,
          });
        } else {
          console.log(error);
        }

        this.setState({ isSending: false });
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    event.target.classList.remove("error");

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { translate } = this.props;
    const error = this.state.loginError ? (
      <ErrorArea>{this.state.loginError}</ErrorArea>
    ) : null;
    return (
      <div className="container">
        <Helmet>
          <title>{translate("nav.login")}</title>
        </Helmet>
        <div className="row">
          <div className="col col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
            <h3 className="page-title">{translate("login.page_title")}</h3>
            <section className="page-area">
              <form onSubmit={this.handleSubmit} className="d-flex flex-column">
                <div className="form-group position-relative sliding-label">
                  <input
                    type="email"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChange}
                    onBlur={(e) => {
                      if (!validateInput("email", e.target.value)) {
                        e.target.classList.add("error");
                      }
                    }}
                    className={`form-control ${
                      this.state.username !== "" && `filled`
                    }`}
                    id="username"
                    required
                  />
                  <label htmlFor="username" className="position-absolute">
                    <span>{translate("auth.email_address")}</span>
                  </label>
                </div>
                <div className="form-group position-relative sliding-label">
                  <input
                    type={this.state.seePass ? `text` : `password`}
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    className={`form-control ${
                      this.state.password !== "" && `filled`
                    } ${is.safari() ? `safari-fix` : ``}`}
                    id="password"
                    required
                  />
                  <label htmlFor="password" className="position-absolute">
                    <span>{translate("auth.password")}</span>
                  </label>
                  <button
                    className="btn position-absolute"
                    style={{ bottom: "7px", right: 0 }}
                    type="button"
                    onClick={() =>
                      this.setState({
                        seePass: seeHidePassword(this.state.seePass),
                      })
                    }
                  >
                    <i
                      className={`far ${
                        this.state.seePass ? `fa-eye-slash` : `fa-eye`
                      } text-muted`}
                    />
                  </button>
                </div>
                <div className="forgotPassLink text-center mt-2 mb-4">
                  <Link
                    to={
                      guestRoute["forgotPassword"].links[
                        this.props.activeLanguage.code
                      ]
                    }
                  >
                    {translate("auth.forgot_password")}
                  </Link>
                </div>

                {error}

                <button
                  className="button-primary"
                  disabled={this.state.isSending || !this.state.isCompleted}
                >
                  {translate("login.login")}
                </button>

                <p className="applyLink mb-0 mb-md-3 mt-4 mt-md-5 w-100 text-center">
                  {translate("login.dont_have_a_supplier_hub_account", {
                    link: (
                      <Link
                        to={
                          guestRoute["register"].links[
                            this.props.activeLanguage.code
                          ]
                        }
                      >
                        {translate("globalTranslations.click_here")}
                      </Link>
                    ),
                  })}
                </p>
              </form>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
  };
};

export default connect(null, mapDispatchToProps)(withLocalize(Login));
