import React from "react";

import "./Footer.scss";
import ccProviders from "../assets/images/cc_provider_logo.svg";

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="mt-1 mt-md-4 position-relative">
      <div className="container-fluid">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col">
            <span className="copyright">&copy; {year} Bucketlist</span>
          </div>
          <div className="col col-12 col-md-8 col-lg-7">
            <nav className="navbar navbar-expand-sm">
              <div className="navbar-collapse d-flex justify-content-center">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      href={`${process.env.REACT_APP_BUCKETLIST_URL}/im-kullanim-sartlari`}
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kullanım Şartları
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href={`${process.env.REACT_APP_BUCKETLIST_URL}/gizlilik-politikasi/kisisel-verilerin-korunmasi`}
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gizlilik Politikası
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href={`${process.env.REACT_APP_BUCKETLIST_URL}/gizlilik-politikasi/cerezler`}
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Çerez Kullanımı
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="col d-flex ccs">
            <img src={ccProviders} alt="Credit Card Providers" />
          </div>
        </div>
      </div>
    </footer>
  );
}
