import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import API, { headers } from "../utils/API";

import dashboardTR from "../translations/tr/dashboard.json";
import dashboardEN from "../translations/en/dashboard.json";

import "./App.scss";
import Experience from "./components/Experience";
import Helmet from "react-helmet";
import { approvalProgressCalculator, handleErrors } from "../utils/helper";

class App extends Component {
  state = {
    products: {},
    page: 1,
    isLoading: true,
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(dashboardEN, "en");
    this.props.addTranslationForLanguage(dashboardTR, "tr");
  }

  componentDidMount() {
    const { user } = this.props;
    const config = {
      headers: {
        ...headers,
        Authorization: `${user._tokenType} ${user._token}`,
      },
    };

    API.get("reports/most-viewed-products", config)
      .then((response) => {
        const { data } = response.data;
        this.setState({ products: data, isLoading: false });
      })
      .catch((err) => handleErrors(err));
  }

  approvalProgress =
    this.props.user.isLoggedIn && !this.props.user.approvedAccount
      ? approvalProgressCalculator(this.props.user.approvalProgress)
      : {};

  render() {
    const { translate } = this.props;
    const { products } = this.state;

    const productsArr = Object.keys(products);
    const experiences = this.state.isLoading
      ? translate("globalTranslations.loading")
      : productsArr.length
      ? productsArr.map((key) => {
          return (
            <Experience
              key={products[key].id}
              cover_image_url={products[key].cover_image_url}
              product_name={products[key].product_name}
              hit={products[key].hit.toLocaleString(this.props.user.lang)}
              translate={translate}
            />
          );
        })
      : translate("dashboard.no_record");
    return (
      <div className="pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app">
        <Helmet>
          <title>{translate("nav.dashboard")}</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <section className="page-area">
                <h6 className="sub-title">
                  {translate("dashboard.most_viewed_experiences")}
                </h6>
                <div className="experiences mt-4 d-flex flex-column">
                  {experiences}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(App));
