import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";

import { login, logout, changeLang, update } from "./auth/authActions";
import { check } from "./notification/notificationActions";

import globalTranslations from "./translations/global.json";

import Header from "./app/Header";
import Router from "./app/Router";
import Footer from "./app/Footer";

// offline state cache
import offlineImage from "./app/assets/no-internet-connection.png";
import logo from "./app/assets/logo.svg";

import "normalize.css/normalize.css";
import "bootstrap/scss/bootstrap.scss";
import "./utils/assets/app.scss";
import "./utils/assets/transitions.scss";
import "toastr/toastr.scss";
import "animate.css/animate.min.css";
import API, { headers } from "./utils/API";
import Cookies from "universal-cookie/es6";
import tawkTo from "tawkto-react";
import translation from "./translations/offline.json";

import OfflineState from "./app/OfflineState";

import { confetti } from "dom-confetti";

class Main extends Component {
  state = {
    isOnline: true,
    congrats: false,
  };
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Turkish", code: "tr" },
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: "tr",
      },
    });
  }

  cookie = new Cookies();

  async componentDidMount() {
    this.checkOnline();
    window.addEventListener("online", this.checkOnline, false);
    window.addEventListener("offline", this.checkOnline, false);

    const user = this.cookie.get("user");
    if (user) {
      this.props.login(user);

      const config = {
        headers: {
          ...headers,
          Authorization: `${user._tokenType} ${user._token}`,
        },
      };

      API.get("account", config)
        .then(async (response) => {
          const {
            notification_count,
            approved_account,
            account_checklist,
            status_id,
          } = response.data.data;

          const user = this.props.user;

          if (notification_count !== user.notificationCount) {
            user.notificationCount = notification_count;
          }

          if (user.lang) {
            this.props.setActiveLanguage(user.lang);
          }

          user.approvedAccount = approved_account;
          user.approvalProgress = account_checklist;
          user.approvalStatus = status_id;

          this.props.update(user);
        })
        .catch((err) => {
          if (err.response)
            if (err.response.data)
              if (err.response.data.info)
                if (err.response.data.info.statusCode === 401) {
                  this.logoutHandle();
                }
        });
    } else if (this.cookie.get("guest_lang")) {
      this.props.setActiveLanguage(this.cookie.get("guest_lang"));
    }

    if (
      process.env.REACT_APP_TAWKTO_APP_ID &&
      process.env.REACT_APP_TAWKTO_APP_KEY
    ) {
      const tawkToPropertyId = process.env.REACT_APP_TAWKTO_APP_ID;
      const tawkToKey = process.env.REACT_APP_TAWKTO_APP_KEY;
      tawkTo(tawkToPropertyId, tawkToKey);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.user.approvalStatus === 8 &&
      this.props.user.approvalStatus === 1
    ) {
      this.setState({ congrats: true }, () => {
        setTimeout(() => {
          confetti(document.getElementById("confetti-container"), {
            angle: "90",
            spread: "360",
            startVelocity: "40",
            elementCount: 70,
            dragFriction: "0.15",
            duration: 3000,
            stagger: 3,
            width: "10px",
            height: "10px",
            perspective: "520px",
            colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
          });
        }, 20);

        setTimeout(() => {
          this.setState({ congrats: false });
        }, 3020);
      });
    }
  };

  logoutHandle = () => {
    this.props.logout();
    this.props.history.push("/");
  };

  handleLanguageChangeForUser = (code) => {
    if (this.props.user.isLoggedIn) {
      this.props.changeLang(code);
    } else {
      this.cookie.set("guest_lang", code);
    }
  };

  checkOnline = () => {
    this.setState({ isOnline: navigator.onLine });
  };

  render() {
    return this.state.isOnline ? (
      <div className="site">
        <Helmet
          titleTemplate={`%s | ${this.props.translate(
            "globalTranslations.bucketlist_supplier_hub"
          )}`}
          defaultTitle={this.props.translate(
            "globalTranslations.bucketlist_supplier_hub"
          )}
        >
          <html
            lang={
              this.props.activeLanguage ? this.props.activeLanguage.code : "tr"
            }
          />
          {/* ! Remove before finalizing production*/}
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />

          {(window.location.pathname !== "/" ||
            window.location.origin.indexOf("itsbucketlist.dev") > -1) && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>
        <Header
          logout={this.logoutHandle}
          user={this.props.user}
          notifications={this.props.notifications}
          handleLanguageChangeForUser={this.handleLanguageChangeForUser}
        />
        <main className="pb-2 pb-md-4">
          <Router
            user={this.props.user}
            lang={
              this.props.activeLanguage ? this.props.activeLanguage.code : "tr"
            }
          />
        </main>
        <Footer />
        <div className="d-none">
          <img src={logo} alt="Bucketlist Logo" />
          <img src={offlineImage} alt="Offline state cloud" />
        </div>
        {(this.props.user?.approvalStatus === 8 ||
          this.props.user?.approvalStatus === 1) &&
          this.state.congrats && (
            <div
              id="confetti-container"
              className="fixed-top top-left vw-100 vh-100 d-flex justify-content-center align-items-center"
            />
          )}
      </div>
    ) : (
      <OfflineState
        translation={translation}
        lang={this.props.activeLanguage ? this.props.activeLanguage.code : "tr"}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    notifications: state.notification.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
    update: (user) => dispatch(update(user)),
    logout: () => dispatch(logout()),
    check: (notifications) => dispatch(check(notifications)),
    changeLang: (lang) => dispatch(changeLang(lang)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(withRouter(Main)));
