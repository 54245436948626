import React from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter, matchPath } from "react-router-dom";

import styles from "./NotFound.module.scss";
import { loggedRoute, guestRoute } from "./routes";

function NotFound(props) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    Object.keys(loggedRoute).map(key => {
      const currLocation = Object.keys(loggedRoute).filter(key => {
        return matchPath(window.location.pathname, {
          path: loggedRoute[key].links[props.lang],
          exact: loggedRoute[key].exact,
          strict: false
        });
      })[0];

      if (currLocation) {
        Object.keys(loggedRoute).map(key => {
          if (key === currLocation) {
            props.history.push(guestRoute.login.links[props.lang]);
            return false;
          }
          return true;
        });
      }

      return true;
    });
  } else {
    Object.keys(guestRoute).map(key => {
      const currLocation = Object.keys(guestRoute).filter(key => {
        return matchPath(window.location.pathname, {
          path: guestRoute[key].links[props.lang],
          exact: guestRoute[key].exact,
          strict: false
        });
      })[0];

      if (currLocation) {
        Object.keys(guestRoute).map(key => {
          if (key === currLocation) {
            props.history.push(loggedRoute.dashboard.links[props.lang]);
            return false;
          }
          return true;
        });
      }

      return true;
    });
  }

  return (
    <div
      className={`${
        props.isLoggedIn
          ? `pl-2 pr-2 pl-lg-5 pr-lg-5 pt-2`
          : styles.notFoundContainer
      }`}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <section
              className={`page-area p-0 ${
                !props.isLoggedIn ? styles.notFoundFix : ""
              }`}
            >
              <div
                className={`${styles.NotFound} ${
                  !props.isLoggedIn ? styles.guest : ""
                }`}
              >
                <div className={styles.icon}>
                  <svg
                    width="175"
                    height="175"
                    viewBox="0 0 175 175"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M87.5 175C135.825 175 175 135.825 175 87.5C175 39.1751 135.825 0 87.5 0C39.1751 0 0 39.1751 0 87.5C0 135.825 39.1751 175 87.5 175Z"
                      fill="url(#paint0_linear)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M38 44V130.775H136.304V44H38ZM130.544 49.76V67.0584H43.76V49.76H130.544ZM43.76 72.8184V125.015H130.544V72.8184H43.76ZM49.5269 55.5292H55.2915V61.2892H49.5269V55.5292ZM66.8207 55.5292H61.0561V61.2892H66.8207V55.5292ZM72.5853 55.5292H78.3499V61.2892H72.5853V55.5292ZM78.5074 113.326H95.7966V84.5074H78.5074V113.326ZM84.2674 90.2674H90.0366V107.566H84.2674V90.2674ZM66.9782 96.0366H61.209V84.5051H55.449V101.797H66.9782V113.328H72.7382V84.5051H66.9782V96.0366ZM107.326 96.0366H113.095V84.5051H118.855V113.328H113.095V101.797H101.566V84.5051H107.326V96.0366Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="411.148"
                        y1="40.887"
                        x2="40.4073"
                        y2="-204.161"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FEB984" />
                        <stop offset="1" stopColor="#E75B2B" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className={styles.title}>
                  {props.translate("globalTranslations.not_found_title")}
                </h3>
                <p className={styles.text}>
                  {props.translate("globalTranslations.not_found_content")}
                </p>
                <button
                  className="button-primary"
                  onClick={e => {
                    e.preventDefault();
                    props.isLoggedIn
                      ? props.history.push(
                          loggedRoute.dashboard.links[props.lang]
                        )
                      : props.history.push("/");
                  }}
                >
                  {props.translate("globalTranslations.go_back_to_homepage")}
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withLocalize(withRouter(NotFound));
