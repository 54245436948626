import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import moment from "moment";

import FifthStep from "./Steps/FifthStep";
import SixthStep from "./Steps/SixthStep";

import { loggedRoute } from "../../app/routes";

import is from "is_js";

import "./CreateProduct.scss";

import createProductEn from "../../translations/en/createProduct.json";
import createProductTr from "../../translations/tr/createProduct.json";
import Modal from "../../utils/components/Modal";
import API, { headers } from "../../utils/API";
import { handleErrors } from "../../utils/helper";
import Helmet from "react-helmet";
import { clear, create, save } from "./createProductActions";
import { isEmpty } from "lodash";
import Loader from "../../app/components/Loader";
import { Prompt } from "react-router-dom";

import toastr from "toastr";

class CreateProduct extends Component {
  // initial state of the new product
  initialProduct = {
    id: "",
    name: "",
    mainCategory: "",
    subCategory: "",
    questions: [],
    pictures: {},
    video: "",
    address: "",
    country: "212",
    city: "",
    district: "",
    postalCode: "",
    mapLocation: {},
    priceInfo: {},
    reservationType: "",
    dateRange: "",
    dateRanges: {},
    timeRange: "",
    timeRanges: {},
    isLoading: true,
    isProductLoaded: false,
  };

  configs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
  };

  constructor(props) {
    super(props);

    // get translation props
    this.props.addTranslationForLanguage(createProductEn, "en");
    this.props.addTranslationForLanguage(createProductTr, "tr");

    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleSaveProductState = this.handleSaveProductState.bind(this);
    this.handleSendProduct = this.handleSendProduct.bind(this);

    this.state = {
      product: null,
      step: 5,
      id: this.props.match.params.id,
      isDone: false,
      isLoading: true,
      sent: false,
      isSending: false,
      isEditing: false,
      isCloning: false,
      updated: false,
      error: "",
      response: "",
      highestStep: 5,
      pricingTypes: [],
      canceling: false,
      leaveWithoutAsking: false,
      isButtonDisabled: false,
    };
  }

  componentDidMount() {
    let product = {};
    if (this.props.match.params && this.props.match.params.id) {
      const { match, createdProduct } = this.props;

      if (isEmpty(createdProduct)) {
        const localProduct = JSON.parse(localStorage.getItem("product"));

        if (localProduct && localProduct.code === match.params.id) {
          API.get(`products/${localProduct.id}?encoded_image=1`, this.configs)
            .then(async (r) => {
              const { data } = r.data;

              const questions = {};
              data.questions_answers.map((data) => {
                questions[data.id] = data.value;
                return true;
              });

              const map_coordinates = {
                lat: data.map_coordinates.latitude,
                lng: data.map_coordinates.longitude,
              };

              let index = 0;
              const priceInfo = data.price_options.map((data) => {
                return {
                  id: index++,
                  type: data.participant_type,
                  price: data.price,
                  label: data.custom_label,
                  participantCount: data.custom_participant_count,
                  isDiscounted: data.is_discount,
                  discountedPrice: data.discounted_price,
                  currency: data.currency,
                };
              });

              index = 0;
              const timeRanges =
                parseInt(data.schedule.time_range_type) === 3
                  ? data.schedule.fixed_times.map((data) => {
                      index++;
                      return ([index] = data);
                    })
                  : "";

              const pictures = {};

              for (let i = 0; i < data.images.length; i++) {
                let image = data.images[i];
                pictures[image.id] = {
                  id: image.id,
                  base64: image.encoded,
                  currPic: true,
                };
              }

              const params = {
                id: data.id,
                name: data.product_name,
                mainCategory: data.main_category.id,
                subCategory: data.sub_category.id,
                questions,
                howLong: data.duration,
                keywords: data.tags,
                pictures,
                video: data.video_url,
                address: data.location.address_line,
                country: "212",
                city: data.location.city.id,
                district: data.location.district.id,
                postalCode: data.location.zip,
                mapLocation: map_coordinates,
                priceInfo,
                reservationType: parseInt(data.schedule.booking_type),
                dateRange: parseInt(data.schedule.date_range_type),
                dateRanges: {
                  start: moment(data.schedule.booking_start_date, "DD-MM-YYYY"),
                  end: moment(data.schedule.booking_expiry_date, "DD-MM-YYYY"),
                },
                timeRange: data.schedule.time_range_type,
                timeRanges,
                isEditing: localProduct.isEditing,
                isCloning: localProduct.isCloning,
                code: data.product_code,
              };

              product = this.props.create(params).product;

              this.setState(
                {
                  product,
                  isEditing: localProduct.isEditing,
                  isCloning: localProduct.isCloning,
                },
                () =>
                  setTimeout(
                    () => this.setState({ isProductLoaded: true }),
                    200
                  )
              );
            })
            .catch((err) => handleErrors(err));
        } else {
          product = this.props.create(this.initialProduct).product;

          setTimeout(() => this.setState({ isProductLoaded: true }), 200);
        }
      } else {
        product =
          match.params.id === createdProduct.code
            ? this.props.createdProduct
            : this.props.create(this.initialProduct).product;

        setTimeout(() => this.setState({ isProductLoaded: true }), 200);
      }
    } else {
      product = this.props.create(this.initialProduct).product;

      setTimeout(() => this.setState({ isProductLoaded: true }), 200);
    }

    this.setState({
      product,
      step: 5,
      id: this.props.match.params.id,
      isDone: false,
      isLoading: true,
      sent: false,
      isSending: false,
      isEditing: product.isEditing ? true : false,
      isCloning: product.isCloning ? true : false,
      updated: false,
      error: "",
      response: "",
      highestStep: 5,
      pricingTypes: [],
      canceling: false,
      leaveWithoutAsking: false,
    });

    const leaveWithoutAsking =
      (/Macintosh/i.test(navigator.userAgent) &&
        navigator.maxTouchPoints > 2) ||
      is.ios();

    this.setState({ leaveWithoutAsking });

    const { step } = this.props.match.params;

    if (step === "5" || step === "6") {
      this.setState(
        {
          step: parseInt(step),
          highestStep: parseInt(step),
        },
        () => setTimeout(() => this.setState({ isLoading: false }), 200)
      );
    } else {
      this.setState(
        {
          step: 5,
          highestStep: 5,
        },
        () => setTimeout(() => this.setState({ isLoading: false }), 200)
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.step !== this.state.step ||
      nextState.isDone !== this.state.isDone ||
      nextState.sent !== this.state.sent ||
      nextState.pricingTypes.length !== this.state.pricingTypes
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.product?.id !== this.state.product?.id) {
      const { id } = this.state.product;

      API.get(`products/${id}/pricing`, this.configs)
        .then(({ data: res }) => {
          const pricingTypes = res.data.map((data) =>
            data.packages.map((singlePackage) => ({
              id: singlePackage.id,
              name: singlePackage.title,
              currency: data.currency,
              prices: singlePackage.categories.map((price) => ({
                id: price.id,
                type: price.title,
                price: price.price,
                label: price.title,
                currency: data.currency,
              })),
            }))
          );

          this.setState({ pricingTypes });
        })
        .catch((err) => {
          handleErrors(err);
        });
    }
    // console.log(prevState.step, this.state.step);
    // if (
    //   prevState.isDone !== this.state.isDone &&
    //   prevState.step === this.state.step
    // ) {
    //   if (this.state.step !== this.state.highestStep) {
    //     this.setState({ highestStep: this.state.step });
    //   }
    // }
    // ask before leaving the page
    // window.onbeforeunload = () => true;
  }

  componentWillUnmount() {
    if (this.state.leaveWithoutAsking) {
      return true;
    }
    // remove the event listener
    window.onbeforeunload = null;
  }

  /**
   * change the step and url if user changes the step
   * @param e event parameter
   * @param step which step is selected
   */
  async handleStepChange(e, step = null) {
    e.preventDefault();
    const { action } = e.target.dataset;

    this.setState({ error: "" });

    if (action) {
      if (action === "next" && this.state.step !== 6)
        await this.setState((prevState) => {
          return {
            step: parseInt(prevState.step) + 1,
          };
        });
      else if (action === "prev" && this.state.step !== 1)
        await this.setState((prevState) => {
          return {
            step: parseInt(prevState.step) - 1,
          };
        });
      else if (action === "add" && this.state.step === 6)
        await this.setState({
          step: loggedRoute["sendProduct"].links[
            this.props.activeLanguage.code
          ],
        });
    } else if (step) {
      await this.setState({
        step: parseInt(step),
      });
    }

    if (this.state.step > this.state.highestStep) {
      this.setState({ highestStep: parseInt(this.state.step) });
    }

    const link = this.state.isEditing
      ? loggedRoute.editProduct
      : this.state.isCloning
      ? loggedRoute.cloneProduct
      : loggedRoute.createProduct;

    this.props.history.push(
      link.links[this.props.activeLanguage.code]
        .replace(":id", this.state.id)
        .replace(":step", this.state.step)
    );
  }

  /**
   * Save the product state when step is changed
   * @param {*} productParams get product from step component
   */
  async handleSaveProductState(productParams, step) {
    if (step !== "final" && !this.state.canceling) {
      const product = { ...this.state.product, ...productParams };

      await this.setState({ product });

      this.props.save(product);
    }
  }

  handleUpdateProduct = async (e) => {
    if (e) e.preventDefault();

    const isEmpty = this.state.pricingTypes[0].some((type) =>
      type.prices.some((price) => price.price === "")
    );

    if (isEmpty) {
      toastr.error("Fiyat alanları boş bırakılamaz");
      return;
    }

    // this.setState({ isSending: true, error: "" });

    const { id } = this.state.product;

    let prices = [];

    this.state.pricingTypes.forEach((type) => {
      type.forEach((sP) => {
        sP.prices.forEach((price) => {
          prices.push({
            currency: price.currency,
            package_id: sP.id,
            price_type_id: price.id,
            price: price.price,
          });
        });
      });
    });

    API.patch(`products/${id}/pricing`, { prices }, this.configs)
      .then(({ data: res }) => {
        this.props.clear();

        const { info } = res;

        this.setState({ response: info.message, leaveWithoutAsking: true });

        this.handleModalToggle();
      })
      .catch(() => {
        this.setState({ isSending: false });
      });
  };

  async handleSendProduct(e, productState) {
    await this.handleSaveProductState(productState, 6);

    this.setState({ isSending: true, error: "" });

    const { product } = this.state;

    const question_answers = Object.keys(product.questions).map((key) => {
      return {
        id: parseInt(key),
        value: product.questions[key],
      };
    });

    if (!product.questions[10] || product.questions[10].value === "") {
      question_answers[9] = { id: 10, value: "-" };
    }

    const image_data = Object.keys(product.pictures).map((key) => {
      return product.pictures[key].base64;
    });

    const price_options = Object.keys(product.priceInfo).map((key) => {
      const data = product.priceInfo[key];
      return {
        participant_type_id: parseInt(data.type),
        custom_label: data.label,
        custom_participant_count: data.participantCount,
        price: parseFloat(data.price.replace(/,/g, "")),
        currency: data.currency.toLowerCase(),
        is_discount: data.isDiscounted,
        discounted_price: data.isDiscounted
          ? parseFloat(data.discountedPrice.replace(/,/g, ""))
          : 0,
      };
    });

    const fixed_times = Object.keys(product.timeRanges).map((key) => {
      return product.timeRanges[key];
    });

    const params = {
      product_name: product.name,
      main_category_id: parseInt(product.mainCategory),
      sub_category_id: parseInt(product.subCategory),
      question_answers: question_answers,
      map_coordinates: {
        latitude: product.mapLocation.lat,
        longitude: product.mapLocation.lng,
      },
      location: {
        address_line: product.address,
        country_id: parseInt(product.country),
        city_id: parseInt(product.city),
        district_id: parseInt(product.district),
        zip: parseInt(product.postalCode),
      },
      image_data: image_data,
      video_url: product.video,
      price_options: price_options,
      schedule: {
        date_range_type: parseInt(product.dateRange),
        booking_start_date:
          product.dateRanges &&
          parseInt(product.dateRange) !== 1 &&
          product.dateRanges.start
            ? moment(product.dateRanges.start).format("DD/MM/YYYY")
            : "",
        booking_expiry_date:
          product.dateRanges &&
          parseInt(product.dateRange) !== 1 &&
          product.dateRanges.end
            ? moment(product.dateRanges.end).format("DD/MM/YYYY")
            : "",
        time_range_type: parseInt(product.timeRange),
        fixed_times: fixed_times,
      },
    };

    API.post("products", params, this.configs)
      .then(async (response) => {
        this.props.clear();

        const { info } = response.data;

        this.setState({ response: info.message, leaveWithoutAsking: true });

        this.handleModalToggle();
      })
      .catch((error) => {
        handleErrors(error);
        this.setState({ isSending: false });
      });
  }

  checkIfFilled = async (params, custom = null) => {
    if (custom) {
      if (custom === "questions") {
        if (Object.keys(params).length < 9) {
          this.setState({ isDone: false });
        } else {
          const check = Object.keys(params).map((key) => {
            return parseInt(key) === 10
              ? true
              : params && params[key] !== "" && params[key] !== undefined
              ? true
              : false;
          });

          this.setState({ isDone: !check.includes(false) });
        }
      }
    } else {
      if (params.length) {
        const check = params.map((data) => {
          return (
            Boolean(data) &&
            (data === parseInt(data, 10) || Boolean(data.length))
          );
        });

        const isDone = !check.includes(false);

        if (isDone !== this.state.isDone)
          await this.setState({
            isDone,
          });
      } else {
        await this.setState({ isDone: true });
      }
    }
  };

  handleModalToggle = async (e = null) => {
    if (e) e.preventDefault();

    await this.setState((prevState) => {
      return { sent: !prevState.sent };
    });

    if (!this.state.sent) {
      this.props.history.push(
        loggedRoute["products"].links[this.props.activeLanguage.code]
      );
    }
  };

  handleConfirmLeave = (location) => {
    if (
      this.state.leaveWithoutAsking ||
      location.pathname ===
        loggedRoute.createProduct.links[this.props.activeLanguage.code]
          .replace(":id", this.state.id)
          .replace(":step?", this.state.step) ||
      location.pathname ===
        loggedRoute.editProduct.links[this.props.activeLanguage.code]
          .replace(":id", this.state.id)
          .replace(":step?", this.state.step) ||
      location.pathname ===
        loggedRoute.cloneProduct.links[this.props.activeLanguage.code]
          .replace(":id", this.state.id)
          .replace(":step?", this.state.step)
    )
      return true;

    const c = window.confirm(
      this.props.translate("create_product.leaving_page_confirmation")
    );

    if (c) {
      return true;
    } else {
      return false;
    }
  };

  handleCancel = () => {
    this.setState({ canceling: true });

    this.props.clear();

    setTimeout(
      () =>
        this.props.history.push(
          loggedRoute.products.links[this.props.activeLanguage.code]
        ),
      10
    );
  };

  render() {
    const { translate, activeLanguage } = this.props;
    let getStep = "";

    const props = {
      handleSaveProductState: this.handleSaveProductState,
      product: this.state.product,
      translate,
      activeLangCode: activeLanguage.code,
      handleStepChange: this.handleStepChange,
      checkIfFilled: this.checkIfFilled,
      user: this.props.user,
    };

    // get the corresponding step component
    if (!this.state.isLoading) {
      switch (this.state.step) {
        // case 1:
        //   getStep = <FirstStep {...props} />;
        //   break;
        // case 2:
        //   getStep = <SecondStep {...props} />;
        //   break;
        // case 3:
        //   getStep = <ThirdStep {...props} />;
        //   break;
        // case 4:
        //   getStep = <FourthStep {...props} />;
        //   break;
        case 5:
          getStep = (
            <FifthStep
              {...props}
              pricingTypes={this.state.pricingTypes}
              handleUpdatePricingTypes={(pricingTypes) =>
                this.setState({ pricingTypes })
              }
              handleDisableButton={(state) =>
                this.setState({ isButtonDisabled: state })
              }
            />
          );
          break;
        case 6:
          getStep = (
            <SixthStep
              {...props}
              handleSendProduct={this.handleSendProduct}
              handleUpdateProduct={this.handleUpdateProduct}
              isDone={this.state.isDone}
              isSending={this.state.isSending}
              isEditing={this.state.isEditing}
              isCloning={this.state.isCloning}
              error={this.state.error}
            />
          );
          break;
        default:
          getStep = (
            <FifthStep
              {...props}
              pricingTypes={this.state.pricingTypes}
              handleUpdatePricingTypes={(pricingTypes) =>
                this.setState({ pricingTypes })
              }
              handleUpdateProduct={this.handleUpdateProduct}
              isDone={this.state.isDone}
              isSending={this.state.isSending}
              isEditing={this.state.isEditing}
              isCloning={this.state.isCloning}
              error={this.state.error}
              handleDisableButton={(state) =>
                this.setState({ isButtonDisabled: state })
              }
            />
          );
          break;
      }
    }

    return this.state.isLoading || !this.state.isProductLoaded ? (
      <Loader />
    ) : (
      <div className="container">
        <Helmet>
          <title>
            {this.props.translate(
              `create_product.${
                this.state.isEditing
                  ? `edit_experience`
                  : this.state.isCloning
                  ? `clone_experience`
                  : `add_a_new_experience`
              }`
            )}
          </title>
        </Helmet>
        <div className="row">
          <div
            className={`col col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 ${
              this.state.step === 6
                ? `col-xl-10 offset-xl-1`
                : `col-xl-6 offset-xl-3`
            }`}
          >
            <h3 className="page-title createProductFix">
              {this.state.isEditing
                ? translate("create_product.edit_experience")
                : this.state.isCloning
                ? translate("create_product.clone_experience")
                : translate("create_product.add_a_new_experience")}
            </h3>
            <section
              className={`page-area createProductFix ${
                this.state.step === 1 ? `firstStepFix` : ``
              }`}
            >
              {getStep}
              {this.state.step === 5 ? (
                <button
                  onClick={this.handleUpdateProduct}
                  data-action="update"
                  className="mt-5 button-primary w-100"
                  disabled={this.state.isSending || this.state.isButtonDisabled}
                >
                  {translate("globalTranslations.update")}
                </button>
              ) : null}
              <button
                onClick={this.handleCancel}
                data-action="cancel"
                className="mt-3 button-secondary opposite w-100"
                disabled={this.state.isSending}
              >
                {translate("globalTranslations.go_back")}
              </button>
            </section>
          </div>
        </div>
        <CSSTransition in={this.state.sent} timeout={300} unmountOnExit>
          <Modal handleToggle={this.handleModalToggle}>
            <div className="bl-modal-content product-fix">
              <div className="icon text-center">
                <svg
                  width="108"
                  height="108"
                  viewBox="0 0 108 108"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.9 107.8C83.6681 107.8 107.8 83.6681 107.8 53.9C107.8 24.1318 83.6681 0 53.9 0C24.1318 0 0 24.1318 0 53.9C0 83.6681 24.1318 107.8 53.9 107.8Z"
                    fill="url(#paint0_linear)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M69.5655 28.2583L46.8906 68.1372L35.6027 52.3319L13.5596 53.0913C20.2432 60.2189 24.743 64.4327 31.5502 71.4461C36.6688 76.7253 40.736 83.931 49.252 81.1531C56.124 78.9111 82.1817 47.0517 90.4696 37.7946L69.5655 28.2583Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="-76.9827"
                      y1="69.3149"
                      x2="61.6472"
                      y2="201.551"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#80D88C" />
                      <stop offset="0.260817" stopColor="#6DD498" />
                      <stop offset="1" stopColor="#38CABB" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h4 className="title text-center mt-3 mb-3">
                {this.state.isEditing
                  ? translate("create_product.update_success_response")
                  : this.state.isCloning
                  ? translate("create_product.clone_success_response")
                  : translate("create_product.success_response")}
              </h4>
              <p
                className="content text-center mb-5"
                style={{ fontsize: "16px" }}
              >
                {this.state.response}
              </p>
              <button
                className="button-primary opposite close-button mb-3 mt-3 pr-md-5 pl-md-5 w-100"
                onClick={(e) => {
                  e.preventDefault();
                  window.location = loggedRoute["createProduct"].links[
                    this.props.activeLanguage.code
                  ]
                    .replace(
                      ":id",
                      btoa(Math.random().toString(36).substr(2, 11)).substr(
                        0,
                        11
                      )
                    )

                    .replace(":step", 1);
                }}
              >
                {translate("create_product.create_new_experience")}
              </button>
              <button
                className="button-primary close-button pl-md-5 pr-md-5 w-100"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    loggedRoute["products"].links[
                      this.props.activeLanguage.code
                    ]
                  );
                }}
              >
                {translate("create_product.show_experiences")}
              </button>
            </div>
          </Modal>
        </CSSTransition>
        <Prompt
          message={(location) => {
            return this.handleConfirmLeave(location);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    createdProduct: state.createProduct.product,
    oldProductState: state.createProduct.oldProductState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (product) => dispatch(create(product)),
    save: (product) => dispatch(save(product)),
    clear: () => dispatch(clear()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(CreateProduct));
