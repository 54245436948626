import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";
import "./index.scss";

import Main from "./Main";

import store from "./store";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";

import ErrorBoundary from "./app/ErrorBoundary";

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: `GTM-${process.env.REACT_APP_GTM_ID}`,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </LocalizeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
