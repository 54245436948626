import React from "react";
import { withLocalize } from "react-localize-redux";
import AccountCompletion from "../../AccountCompletion";

import API, { headers } from "../../../utils/API";
import { handleErrors } from "../../../utils/helper";
import Axios from "axios";
import Loader from "../../../app/components/Loader";
import { update } from "../../../auth/authActions";
import { connect } from "react-redux";
import { loggedRoute } from "../../../app/routes";

const Agreement = ({ translate, lang, user, update, history }) => {
  const [agreement, setAgreement] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [isSending, setIsSending] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    const cancelSource = Axios.CancelToken.source();

    API.get("account/contract", {
      headers: {
        ...headers,
        "Accept-Language": lang,
        Authorization: `${user._tokenType} ${user._token}`,
        cancelToken: cancelSource.token,
      },
    })
      .then(({ data: res }) => {
        const { contract } = res.data;

        setAgreement(contract);

        setLoading(false);

        const pageEl = document.querySelector(".AccountCompletion .page-area");

        if (pageEl) pageEl.classList.add("agreement-fix");
      })
      .catch((err) => {
        handleErrors(err);
        setLoading(false);
      });

    return () => {
      cancelSource.cancel("Operation canceled by the user.");

      const pageEl = document.querySelector(".AccountCompletion .page-area");
      if (pageEl) pageEl.classList.remove("agreement-fix");
    };
  }, [user, lang]);

  React.useEffect(() => {
    const handleSetButtonActive = (e) => {
      if (
        e.target.scrollHeight - e.target.clientHeight <=
          e.target.scrollTop + 40 &&
        !isActive
      ) {
        setIsActive(true);
      }
    };

    if (!loading) {
      if (agreement) {
        const el = document.getElementById("agreement-text");

        if (el) el.addEventListener("scroll", handleSetButtonActive, false);
      }
    }

    return () => {
      const el = document.getElementById("agreement-text");

      if (el) el.removeEventListener("scroll", handleSetButtonActive, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement, loading, isActive]);

  const handleAccept = (e) => {
    e.preventDefault();

    setIsSending(true);

    API.post(
      "account/contract",
      {},
      {
        headers: {
          ...headers,
          Authorization: `${user._tokenType} ${user._token}`,
        },
      }
    )
      .then(() => {
        update({ ...user, approvedAccount: true, approvalStatus: 1 });

        history.push(loggedRoute.dashboard.links[user.lang]);
      })
      .catch((err) => {
        handleErrors(err);
        setIsSending(false);
      });
  };

  return loading ? (
    <Loader />
  ) : (
    <AccountCompletion
      pageTitle={translate(`account_completion.steps.checking`)}
      noDomTitle
      user={user}
      lang={lang}
    >
      <>
        <div className="row">
          <div className="col-12">
            <h4 className="agreement-page-title text-center">
              {translate("account_completion.agreement.page_title")}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className="position-relative agreement-text overflow-auto"
              id="agreement-text"
              dangerouslySetInnerHTML={{ __html: agreement }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <button
              className="button-primary px-4"
              onClick={handleAccept}
              disabled={isSending || !isActive}
            >
              {translate("account_completion.agreement.button_text")}
            </button>
          </div>
        </div>
      </>
    </AccountCompletion>
  );
};

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(update(data)),
});

export default connect(null, mapDispatchToProps)(withLocalize(Agreement));
