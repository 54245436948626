import React from "react";
import downloadIcon from "../../assets/images/download-file.svg";
import { currencyFormatter } from "../../utils/helper";

export default function SingleFinance(props) {
  const { styles, data } = props;

  const handleDownloadFile = () => {
    if (props.type === 2)
      fetch(data.file, { mode: "cors" })
        .then((response) => {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            let name = data.file.split("/");
            name = name.pop();
            const fileType = name.split(".").pop();
            a.href = url;
            a.download = `${data.period}.${fileType}`;
            a.click();
            a.remove();
          });
        })
        .catch((err) => console.log(err));
  };

  return (
    <div className={styles.SingleFinance}>
      <div className="d-flex align-items-center">
        {props.type === 2 && (
          <button
            className={`${styles.downloadButton} btn btn-empty d-flex p-0 mr-2`}
            onClick={handleDownloadFile}
            title="Dosyayı indir"
          >
            <img
              src={downloadIcon}
              alt="File download icon"
              className="w-100 h-100"
            />
          </button>
        )}
        <div className={styles.info}>
          <h3 className={styles.title}>{data.period}</h3>
          <p className={styles.date}>
            <span>
              {props.translate(
                `finance.${props.type === 1 ? `date_paid` : `invoice_date`}`
              )}
              :
            </span>{" "}
            {data.created_at}
          </p>
        </div>
      </div>
      <div className={styles.price}>
        {currencyFormatter(data.currency, data.amount, props.lang)}
      </div>
    </div>
  );
}
