import React from "react";

export default function SuccessArea(props) {
  return (
    <div
      className="alert alert-success d-flex align-items-center justify-content-start"
      role="alert"
      style={{
        borderWidth: "0.5px",
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 16 16"
        class="bi bi-check2"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className="rounded-circle"
        style={{
          border: "1px solid #155724",
          padding: "3px",
        }}
      >
        <path
          fillRule="evenodd"
          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        />
      </svg>

      <span className="ml-3" style={{ width: "calc(100% - 20px - 1rem)" }}>
        {props.children}
      </span>
    </div>
  );
}
