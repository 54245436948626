import "@fortawesome/fontawesome-free/css/all.min.css";
import { useState } from "react";
import SinglePriceType from "./components/SinglePriceType";
import { BsCaretDownFill } from "react-icons/bs";

const FifthStep = ({
  translate,
  pricingTypes,
  handleUpdatePricingTypes,
  index,
  handleDisableButton,
}) => {
  const [show, setShow] = useState(false);

  const handleUpdatePriceType = (e, index, packageId, priceId) => {
    const priceTypes = [...pricingTypes];

    const priceTypeIndex = priceTypes[index].findIndex(
      (priceType) => priceType.id === packageId
    );

    const priceIndex = priceTypes[index][priceTypeIndex].prices.findIndex(
      (price) => price.id === priceId
    );

    priceTypes[index][priceTypeIndex].prices[priceIndex].price = e.target.value;

    if (handleDisableButton) {
      const hasEmpty = priceTypes[0].some((priceType) =>
        priceType.prices.some((price) => price.price === "")
      );

      if (hasEmpty) {
        handleDisableButton(true);
      } else {
        handleDisableButton(false);
      }
    }

    handleUpdatePricingTypes(priceTypes);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mt-5">
          <div className="col">
            <h3 className="step-title">
              {translate("create_product.price_info")}
            </h3>
            <p className="step-desc">
              {translate("create_product.price_info_desc")}
            </p>
            <p className="step-desc sub selectable">
              {translate("create_product.price_info_sub_desc")}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col prices-holder">
            {pricingTypes?.length > 0 &&
              pricingTypes[0]?.map((data, i) => (
                <SinglePriceType
                  key={i}
                  data={data}
                  index={i}
                  mainIndex={index}
                  handleUpdatePriceType={handleUpdatePriceType}
                  isDefault
                />
              ))}

            {pricingTypes?.length > 1 && (
              <button
                onClick={() => setShow((prevState) => !prevState)}
                className="show-more-button button-secondary opposite px-3 py-2 mb-4"
                data-state={show ? "show" : "hide"}
              >
                Diğer birimleri {show ? "gizle" : "göster"}{" "}
                <BsCaretDownFill className="icon" />
              </button>
            )}
            <div className="rest" data-hidden={!show}>
              {pricingTypes
                ?.slice(1)
                ?.map((currencies, i) =>
                  currencies.map((data) => (
                    <SinglePriceType
                      key={i + 1}
                      data={data}
                      index={i + 1}
                      mainIndex={index}
                      handleUpdatePriceType={handleUpdatePriceType}
                    />
                  ))
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthStep;
