import React from "react";

const SingleStep = ({ step, translate }) => {
  return (
    <div className="step d-flex justify-content-start align-items-center">
      <div
        className="icon d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
        data-status={Boolean(step.status)}
      >
        {step.status === 1 ? (
          <svg
            width="9"
            height="11"
            viewBox="0 0 9 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.49999 2.21268V1.24751C8.79743 1.08855 8.99998 0.796189 8.99998 0.458326V0.229174C8.99998 0.102502 8.88816 0 8.74997 0H0.250007C0.11182 0 0 0.102502 0 0.229174V0.458348C0 0.796189 0.202546 1.08855 0.499991 1.24753V2.21268C0.499991 3.12084 0.922357 3.99006 1.65869 4.59744L2.75268 5.5L1.65869 6.40256C0.922357 7.00994 0.499991 7.87916 0.499991 8.78732V9.75247C0.202546 9.91145 0 10.2038 0 10.5417V10.7708C0 10.8975 0.11182 11 0.250007 11H8.74999C8.88818 11 9 10.8975 9 10.7708V10.5417C9 10.2038 8.79745 9.91145 8.50001 9.75247V8.78732C8.50001 7.87916 8.07764 7.00994 7.34131 6.40256L6.2473 5.5L7.34129 4.59744C8.07762 3.99006 8.49999 3.12082 8.49999 2.21268ZM6.67236 3.91619L5.16552 5.15939C5.06005 5.24623 4.99998 5.36998 4.99998 5.5C4.99998 5.63002 5.06005 5.7538 5.16552 5.84061L6.67236 7.08381C7.19822 7.51775 7.49998 8.13878 7.49998 8.78732V9.625H6.87486L4.69993 6.96676C4.60569 6.85128 4.39426 6.85128 4.30002 6.96676L2.12512 9.625H1.5V8.78732C1.5 8.13875 1.80175 7.51773 2.32764 7.08379L3.83448 5.84059C3.93995 5.75375 4.00002 5.63 4.00002 5.49998C4.00002 5.36996 3.93995 5.24618 3.83448 5.15937L2.32764 3.91617C1.80175 3.48225 1.5 2.86122 1.5 2.21268V1.375H7.49998V2.21268C7.49998 2.86122 7.19822 3.48225 6.67236 3.91619ZM2.77661 3.20833H6.22313C6.32201 3.20833 6.41162 3.26182 6.45162 3.34462C6.49168 3.42766 6.47483 3.52434 6.40817 3.59148L4.66771 5.06361C4.62011 5.10321 4.56004 5.12291 4.49999 5.12291C4.43994 5.12291 4.37987 5.10323 4.33227 5.06361L2.59155 3.59148C2.52489 3.52434 2.50806 3.42766 2.54809 3.34462C2.58813 3.26182 2.67773 3.20833 2.77661 3.20833Z"
              fill="#E75A2B"
            />
          </svg>
        ) : step.status === 2 ? (
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8515 0.204213L3.99958 7.32242L1.14836 4.39305C0.885947 4.12007 0.45972 4.12007 0.197308 4.39305C-0.0657692 4.66603 -0.0657692 5.10794 0.197308 5.38022L3.52668 8.80037C3.79972 9.0524 4.20801 9.08032 4.47773 8.80037L11.8032 1.19139C12.0656 0.919104 12.0656 0.476497 11.8032 0.204213C11.5402 -0.068071 11.1146 -0.068071 10.8515 0.204213Z"
              fill="#E75A2B"
            />
          </svg>
        ) : (
          ""
        )}
      </div>
      <h6 className="name m-0">
        {translate(`account_completion.steps.${step?.name}`)}
      </h6>
    </div>
  );
};

export default SingleStep;
