import React, { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (window.location.hostname.startsWith("isletme.bucketlist.com.tr"))
      window.onerror(
        `Error: ${error}\nError Info: ${errorInfo.componentStack}`,
        window.location.href
      );
    console.log(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <h1>Bir şeyler ters gitti.</h1>;
    }
    return this.props.children;
  }
}
