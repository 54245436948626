import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route, NavLink } from "react-router-dom";

import styles from "../assets/tabs.module.scss";

class Tabs extends Component {
  render() {
    const { tabs } = this.props;
    const tabsArr = Object.keys(tabs);
    const tabsNav = tabsArr.length
      ? tabsArr.map(key => {
          return (
            <li className={styles.TabNavsItem} key={tabs[key].id}>
              <NavLink
                to={
                  this.props.lang
                    ? tabs[key].links[this.props.lang]
                    : tabs[key].link
                }
                className="nav-link d-flex"
                activeClassName={styles.active}
              >
                {tabs[key].names[this.props.lang]}
                <span className={styles.resultsCount}>
                  {tabs[key].resultsCount}
                </span>
              </NavLink>
            </li>
          );
        })
      : null;
    return (
      <div className={`w-100 position-relative ${styles.tabs}`}>
        <div className="row mb-3">
          <div className="col">
            <div className={styles.tabNavHolder}>
              <ul
                className={`${styles.TabsNav} w-100 p-0 pb-2 m-0 d-flex align-items-center justify-content-start`}
              >
                {tabsNav}
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <section>
              <Switch>
                <Route
                  path={this.props.routePath}
                  component={this.props.property}
                  exact={true}
                />
              </Switch>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      links: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      names: PropTypes.object.isRequired
    })
  ).isRequired,
  routePath: PropTypes.string.isRequired,
  property: PropTypes.elementType.isRequired
};

export default Tabs;
