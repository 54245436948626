import React from "react";
import Modal from "../../utils/components/Modal";

import done from "../assets/modal-done.svg";

export default function OnHoldModal(props) {
  const { handleCloseOnHoldSuccessModal, translate } = props;
  return (
    <Modal
      handleToggle={handleCloseOnHoldSuccessModal}
      classes="on-hold-success-fix"
    >
      <div className="bl-modal-content d-flex flex-column justify-content-center align-items-center">
        <div className="icon">
          <img src={done} alt="Done" />
        </div>
        <h4 className="title mt-3 mb-5 text-center">
          {translate("bookings.reservation_on_hold_successfully_title")}
        </h4>
      </div>

      <button
        className="button-primary mt-3 close-button"
        onClick={e => {
          handleCloseOnHoldSuccessModal(e);
        }}
      >
        {translate("globalTranslations.close")}
      </button>
    </Modal>
  );
}
