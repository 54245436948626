import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import InputMask from "react-input-mask";
import toastr from "toastr";

import ProfileTemplate from "./ProfileTemplate";
import API, { headers } from "../utils/API";
import { getBase64 } from "../utils/helper";

class Insurance extends Component {
  state = {
    insuranceCompany: "",
    policyNumber: "",
    documentType: "",
    expiryDate: "",
    deposit: "",
    documentFile: "",
    documentName: "",
    files: {},
    loading: true
  };

  APIconfigs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`
    }
  };

  componentDidMount() {
    API.get("insurance", this.APIconfigs).then(response => {
      const { data } = response.data;

      const files = data.map(data => {
        return {
          id: data.id,
          name: data.cover_file,
          url: data.file_url,
          date: data.date_created,
          type: data.type
        };
      });

      this.setState({ files, loading: false });
    });
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  handleFileSelect = e => {
    const { files } = e.target;

    if (files.length) {
      const file = files[0];

      const k = 1024;
      const i = Math.floor(Math.log(file.size) / Math.log(k));
      const size =
        parseFloat((file.size / Math.pow(k, i)).toFixed(2)) +
        " " +
        ["Bytes", "KB", "MB"][i];

      getBase64(file).then(value => {
        this.setState({
          documentFile: value,
          documentName: `${file.name} - ${size}`
        });
      });
    }
  };

  handleRemoveFile = (e, file, key) => {
    e.preventDefault();

    API.delete(`insurance/${file}`, this.APIconfigs)
      .then(response => {
        toastr.clear();
        toastr.info(response.data.info.message, "", {
          progressBar: true
        });
        const files = { ...this.state.files };

        delete files[key];

        this.setState({ files });
      })
      .catch(error => {
        toastr.clear();
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.errors) {
              const { errors } = error.response.data;
              Object.keys(errors).map(key => {
                toastr.error(errors[key][0], "", {
                  progressBar: true
                });
                return true;
              });
            }
            if (error.response.data.code) {
              const { code } = error.response.data;
              toastr.error(`Error Code: ${code}`);
            }
          }
        } else {
          console.log(error);
        }
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    const date = new Date(this.state.expiryDate);

    const params = {
      company_name: this.state.insuranceCompany,
      policy_number: this.state.policyNumber,
      type: this.state.documentType,
      expiry_date: date.toLocaleString(),
      coverage: this.state.deposit,
      cover_file: this.state.documentFile
    };

    API.post("insurance", params, this.APIconfigs)
      .then(response => {
        if (response.data.status) {
          const { data } = response.data;
          const files = {
            id: data.id,
            name: data.cover_file,
            url: data.file_url,
            date: data.date_created,
            type: data.type
          };

          this.setState({
            files: { ...this.state.files, files },
            insuranceCompany: "",
            policyNumber: "",
            documentType: "",
            expiryDate: "",
            deposit: "",
            documentFile: "",
            documentName: ""
          });
          toastr.clear();
          toastr.info(response.data.info.message, "", {
            progressBar: true
          });
        }
      })
      .catch(error => {
        toastr.clear();
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.errors) {
              const { errors } = error.response.data;
              Object.keys(errors).map(key => {
                toastr.error(errors[key][0], "", {
                  progressBar: true
                });
                return true;
              });
            }
            if (error.response.data.code) {
              const { code } = error.response.data;
              toastr.error(`Error Code: ${code}`);
            }
          }
        } else {
          console.log(error);
        }
      });
  };

  render() {
    const { translate } = this.props;
    const files =
      this.state.files && Object.keys(this.state.files).length ? (
        Object.keys(this.state.files).map(key => {
          const date = new Date(this.state.files[key].date).toLocaleString();
          return (
            <tr key={this.state.files[key].id}>
              <td>
                <a
                  href={this.state.files[key].url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.state.files[key].name}
                </a>
              </td>
              <td>{date}</td>
              <td>{this.state.files[key].type}</td>
              <td className="text-center">
                <button
                  className="btn btn-link p-0"
                  onClick={e =>
                    this.handleRemoveFile(e, this.state.files[key].id, key)
                  }
                >
                  <i className="fas fa-trash-alt" />
                </button>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <th scope="row" className="text-center" colSpan="4">
            {translate("globalTranslations.theres_nothing")}
          </th>
        </tr>
      );
    return (
      <ProfileTemplate>
        <div className="row mb-2">
          <div className="col">
            <h3>{translate("insurance.insurance")}</h3>
            <p>{translate("insurance.insurance_desc")}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="form-group col">
            <label htmlFor="insuranceCompany">
              <small className="text-uppercase">
                {translate("insurance.insurance_company")}
              </small>
            </label>
            <input
              type="text"
              name="insuranceCompany"
              id="insuranceCompany"
              value={this.state.insuranceCompany}
              className="form-control"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="policyNumber">
              <small className="text-uppercase">
                {translate("insurance.policy_number")}
              </small>
            </label>
            <input
              type="number"
              name="policyNumber"
              id="policyNumber"
              value={this.state.policyNumber}
              className="form-control"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="form-group col">
            <label htmlFor="documentType">
              <small className="text-uppercase">
                {translate("insurance.document_type")}
              </small>
            </label>
            <input
              type="text"
              name="documentType"
              id="documentType"
              value={this.state.documentType}
              className="form-control"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="expiryDate">
              <small className="text-uppercase">
                {translate("insurance.expiry_date")}
              </small>
            </label>
            <InputMask
              type="text"
              className="form-control"
              id="expiryDate"
              mask="99/99/9999"
              maskChar="_"
              permanents="[2, 5]"
              value={this.state.expiryDate}
              name="expiryDate"
              alwaysShowMask={false}
              onChange={this.handleChange}
              placeholder={translate("globalTranslations.dd_mm_yyyy")}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="form-group col-6">
            <label htmlFor="deposit">
              <small className="text-uppercase">
                {translate("insurance.deposit")}
              </small>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className={`fas fa-lira-sign`} />
                </span>
              </div>
              <input
                type="number"
                name="deposit"
                id="deposit"
                value={this.state.deposit}
                className="form-control"
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="form-group col-6">
            <label htmlFor="documentFile" className="mb-0">
              <small className="text-uppercase">
                {translate("insurance.insurance_document_file")}
              </small>
            </label>
            <small className="d-block mb-2">
              {translate("insurance.insurance_document_file_desc")}
            </small>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input d-none"
                id="documentFile"
                name="documentFile"
                onChange={this.handleFileSelect}
                accept="image/*"
              />
              <label
                className="custom-file-label"
                htmlFor="documentFile"
                style={{ cursor: "pointer" }}
              >
                {this.state.documentName
                  ? this.state.documentName
                  : translate("globalTranslations.choose_a_file")}
              </label>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="form-group col">
            <button
              className="btn btn-primary text-uppercase"
              onClick={this.handleSubmit}
            >
              {translate("globalTranslations.save")}
            </button>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">{translate("insurance.document_files")}</th>
                  <th scope="col">
                    {translate("globalTranslations.added_date")}
                  </th>
                  <th scope="col">{translate("globalTranslations.type")}</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {this.state.loading ? (
                  <tr>
                    <th scope="row" className="text-center" colSpan="4">
                      {translate("globalTranslations.loading")}...
                    </th>
                  </tr>
                ) : (
                  files
                )}
              </tbody>
            </table>
          </div>
        </div>
      </ProfileTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(withLocalize(Insurance));
