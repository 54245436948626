import React from "react";
import { Link } from "react-router-dom";

export default function Pagination(props) {
  let pages = [];
  for (let i = 1; i <= props.pageCount; i++) {
    pages.push(
      <Link
        key={i}
        to="#!"
        className={`nav-button d-flex align-items-center justify-content-center ${
          parseInt(props.page) === parseInt(i) ? "active" : ""
        }`}
        data-page={i}
        onClick={props.handlePageChange}
      >
        {i}
      </Link>
    );
  }
  return (
    <div className="pagination w-100 d-flex align-items-center justify-content-center mt-4">
      {props.page > 1 && (
        <Link
          className="nav-button d-flex align-items-center justify-content-center"
          to="#!"
          onClick={props.handlePageChange}
          data-action="prev"
        >
          <svg
            width="9"
            height="13"
            viewBox="0 0 9 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.48568 1.51562L1.65234 6.50138L7.48568 11.4871"
              stroke="#656565"
              strokeWidth="2.04387"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      )}
      <div className="pages d-flex">{pages}</div>
      {props.page < props.pageCount && (
        <Link
          to="#!"
          className="nav-button d-flex align-items-center justify-content-center"
          onClick={props.handlePageChange}
          data-action="next"
        >
          <svg
            width="9"
            height="13"
            viewBox="0 0 9 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.65202 1.51367L7.48535 6.49943L1.65202 11.4852"
              stroke="#656565"
              strokeWidth="2.04387"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      )}
    </div>
  );
}
