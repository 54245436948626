import React from "react";
import { CSSTransition } from "react-transition-group";
import { isTouchDevice } from "../../../../utils/helper";
import DocumentRemoveInfo from "./DocumentRemoveInfo";

const DocumentArea = ({
  document: documentItem,
  uploadProgress,
  sentDocuments,
  otherDocuments,
  isSending,
  handleFileSelect,
  handleRemoveDocument,
  handleCustomDocumentChange,
  translate,
  showDeleteInfo,
  closeDeleteInfo,
  ...rest
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="document-area position-relative">
          <div
            className="status"
            data-status={
              !documentItem.id
                ? "false"
                : sentDocuments.find(
                    ({ type }) => String(type) === String(documentItem.type)
                  ) ||
                  otherDocuments.find(
                    ({ id }) => String(id) === String(documentItem.id)
                  )
                ? `true`
                : `false`
            }
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="done-tick position-relative"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9034 27.8069C21.5821 27.8069 27.8069 21.5821 27.8069 13.9034C27.8069 6.22479 21.5821 0 13.9034 0C6.22479 0 0 6.22479 0 13.9034C0 21.5821 6.22479 27.8069 13.9034 27.8069Z"
                fill="url(#paint0_linear_0_942)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.615 8.138L12.2772 17.4676L9.63383 13.7778L4.45953 13.9639C6.02554 15.6272 7.0801 16.6103 8.67519 18.2469C9.87459 19.4788 10.8264 21.1614 12.8264 20.5088C14.4403 19.9821 20.569 12.5256 22.5179 10.3588L17.615 8.138Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_0_942"
                  x1="-19.8576"
                  y1="17.8797"
                  x2="15.9018"
                  y2="51.99"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#80D88C" />
                  <stop offset="0.260817" stopColor="#6DD498" />
                  <stop offset="1" stopColor="#38CABB" />
                </linearGradient>
              </defs>
            </svg>

            {(sentDocuments.find(
              ({ type }) => String(type) === String(documentItem.type)
            ) ||
              otherDocuments.find(
                ({ id }) => String(id) === String(documentItem.id)
              )) &&
              !isTouchDevice() && (
                <span
                  role="button"
                  className="remove-icon position-absolute rounded-circle d-none d-md-flex justify-content-center align-items-center"
                  data-force-show={showDeleteInfo === documentItem.name}
                  onClick={() =>
                    handleRemoveDocument(
                      (
                        sentDocuments.find(
                          ({ type }) =>
                            String(type) === String(documentItem.type)
                        ) ||
                        otherDocuments.find(
                          ({ id }) => String(id) === String(documentItem.id)
                        )
                      )?.id
                    )
                  }
                >
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22,4H16V3a3,3,0,0,0-3-3H11A3,3,0,0,0,8,3V4H2A1,1,0,0,0,2,6H4V20a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V6h2a1,1,0,0,0,0-2ZM10,3a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V4H10ZM9,18a1,1,0,0,1-2,0V10a1,1,0,0,1,2,0Zm4,0a1,1,0,0,1-2,0V10a1,1,0,0,1,2,0Zm4,0a1,1,0,0,1-2,0V10a1,1,0,0,1,2,0Z"
                      fill="#ffffff"
                    />
                  </svg>
                </span>
              )}
            <CSSTransition
              classNames="document-remove-info"
              timeout={300}
              unmountOnExit
              in={showDeleteInfo === documentItem.name}
            >
              <DocumentRemoveInfo onClick={closeDeleteInfo} />
            </CSSTransition>
          </div>
          <h6 className="document-title">
            {`isCustom` in rest
              ? `${translate("globalTranslations.other")} (${translate(
                  "globalTranslations.optional"
                )})`
              : `${documentItem.name} ${
                  documentItem.optional && documentItem.type !== 0
                    ? `(${translate("globalTranslations.optional")})`
                    : ``
                }`}
          </h6>
          <p className="document-desc">
            PDF veya resim formatında yükleyebilirsiniz.
          </p>
          {`isCustom` in rest && (
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="name"
                value={documentItem.name}
                onChange={handleCustomDocumentChange}
                className={`form-control ${
                  documentItem.name !== "" && `filled`
                }`}
                id="customDocName"
              />
              <label htmlFor="customDocName" className="position-absolute">
                <span>Belge adı</span>
              </label>
            </div>
          )}
          <label
            className="dropzone d-flex justify-content-center align-items-center position-relative"
            htmlFor={
              documentItem.type ? `document_${documentItem.type}` : `other`
            }
            data-is-active={
              `isCustom` in rest && documentItem.name === "" ? `false` : `true`
            }
            data-is-static={
              !(`isCustom` in rest) &&
              documentItem.name &&
              documentItem.type === 0
            }
          >
            {`isCustom` in rest && documentItem.name === "" && (
              <div className="disabled-label position-absolute d-flex justify-content-start align-items-center w-100 h-100 pl-2">
                ☝️ Belge adı gerekli
              </div>
            )}
            {((String(uploadProgress.type) === String(documentItem.type) &&
              !documentItem.id) ||
              (String(uploadProgress.type) === String(documentItem.type) &&
                String(documentItem.type) !== "0")) && (
              <div
                className="progress-bar position-absolute"
                style={{ width: `${uploadProgress.progress}%` }}
              ></div>
            )}
            {(isSending === documentItem.type && !documentItem.id) ||
            (isSending === documentItem.type && documentItem.type !== 0) ? (
              <p className="progress-text m-0">{uploadProgress.progress}%</p>
            ) : sentDocuments.find(
                ({ type }) => String(type) === String(documentItem.type)
              ) ||
              otherDocuments.find(
                ({ id }) => String(id) === String(documentItem.id)
              ) ? (
              <p
                className="text overflow-hidden m-0 w-100 text-center"
                data-progress={String(uploadProgress.progress)}
              >
                {
                  (
                    sentDocuments.find(
                      ({ type }) => String(type) === String(documentItem.type)
                    ) ||
                    otherDocuments.find(
                      ({ id }) => String(id) === String(documentItem.id)
                    )
                  ).cover_file
                }
              </p>
            ) : (
              <>
                <span className="icon d-flex">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.5 0C11.642 0 15 3.35794 15 7.5C15 11.642 11.642 15 7.5 15C3.35794 15 0 11.642 0 7.5C0 3.35794 3.35794 0 7.5 0ZM7.50029 3.67367C7.75389 3.67367 7.95947 3.87925 7.95947 4.13285V7.04105H10.8674C11.121 7.04105 11.3266 7.24664 11.3266 7.50024C11.3266 7.75384 11.121 7.95942 10.8674 7.95942H7.95947V10.8675C7.95947 11.1211 7.75389 11.3267 7.50029 11.3267C7.24669 11.3267 7.04111 11.1211 7.04111 10.8675V7.95942H4.13274C3.87914 7.95942 3.67355 7.75384 3.67355 7.50024C3.67355 7.24664 3.87914 7.04105 4.13274 7.04105H7.04111V4.13285C7.04111 3.87925 7.24669 3.67367 7.50029 3.67367Z"
                      fill="#E75B2B"
                    />
                  </svg>
                </span>
                <p className="text m-0">Dosya seç</p>
              </>
            )}
          </label>
          <input
            type="file"
            name={
              documentItem.type || documentItem.id
                ? `document_${documentItem.type || documentItem.id}`
                : `other`
            }
            id={
              documentItem.type || documentItem.id
                ? `document_${documentItem.type || documentItem.id}`
                : `other`
            }
            className="d-none"
            onChange={
              `isCustom` in rest ? handleCustomDocumentChange : handleFileSelect
            }
            accept="application/pdf, image/*"
            disabled={
              isSending === documentItem.type ||
              (`isCustom` in rest && documentItem.name === "") ||
              (!(`isCustom` in rest) &&
                documentItem.name &&
                documentItem.type === 0)
            }
          />
          {(sentDocuments.find(
            ({ type }) => String(type) === String(documentItem.type)
          ) ||
            otherDocuments.find(
              ({ id }) => String(id) === String(documentItem.id)
            )) && (
            <span
              role="button"
              className={`remove-link mx-auto d-flex ${
                !isTouchDevice() ? `d-md-none` : ``
              }`}
              onClick={() =>
                handleRemoveDocument(
                  (
                    sentDocuments.find(
                      ({ type }) => String(type) === String(documentItem.type)
                    ) ||
                    otherDocuments.find(
                      ({ id }) => String(id) === String(documentItem.id)
                    )
                  )?.id
                )
              }
            >
              Belgeyi Sil
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentArea;
