import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import API, { headers } from "../utils/API";

import notificationsEN from "../translations/en/profile.json";
import notificationsTR from "../translations/tr/profile.json";

import styles from "./Notifications.module.scss";
import SingleNotification from "./NotificationComponents/SingleNotification";
import Helmet from "react-helmet";

import { handleErrors } from "../utils/helper";
import { check } from "../notification/notificationActions";
import { update } from "../auth/authActions";
import InfiniteScroll from "react-infinite-scroll-component";
import { confirmAlert } from "react-confirm-alert";

class Notifications extends Component {
  state = {
    page: 0,
    notifications: [],
    isClearing: false,
    isLoadingMore: false,
    hadMore: false,
  };

  limit = 10;

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(notificationsEN, "en");
    this.props.addTranslationForLanguage(notificationsTR, "tr");
  }

  configs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
  };

  componentDidMount = () => this.getNotifications();

  handleClearNotifications = () => {
    this.setState({ isClearing: true });

    API.post("notifications/actions/clear", {}, this.configs)
      .then((response) => {
        this.props.check([]);
        this.props.update({ ...this.props.user, notificationCount: 0 });
        this.setState({ notifications: [], isClearing: false });
      })
      .catch((err) => {
        handleErrors(err);
        this.setState({ isClearing: false });
      });
  };

  loadMore = () => this.getNotifications(true);

  getNotifications = (nextPage = false) => {
    let endpoint = `notifications?limit=${this.limit}`;

    endpoint += nextPage ? `&page=${parseInt(this.state.page) + 1}` : ``;

    if (nextPage) this.setState({ isLoadingMore: true });

    API.get(endpoint, this.configs)
      .then((response) => {
        const { data, meta } = response.data;

        const notifications = this.state.notifications.length
          ? [...this.state.notifications, ...data]
          : data;

        this.setState(
          {
            notifications,
            page: meta.current_page,
            hasMore: meta.current_page < meta.last_page,
          },
          () => {
            if (nextPage) this.setState({ isLoadingMore: false });
            else this.setState({ isLoading: false });
          }
        );
      })
      .catch((errors) => {
        handleErrors(errors);
      });
  };

  handleConfirmClearNotifications = (e) => {
    e.preventDefault();

    const options = {
      message: this.props.translate("notifications.confirm_message"),
      buttons: [
        {
          label: this.props.translate("globalTranslations.no"),
          onClick: () => false,
        },
        {
          label: this.props.translate("globalTranslations.yes"),
          onClick: this.handleClearNotifications,
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  render() {
    const { translate } = this.props;

    const notifications = this.state.notifications
      ? this.state.notifications.map((data, index) => {
          return (
            <SingleNotification
              styles={styles}
              translate={translate}
              notification={data}
              key={index}
            />
          );
        })
      : translate("globalTranslations.empty");

    return (
      <div
        className={`pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app ${styles.notificationsFix}`}
      >
        <Helmet>
          <title>{this.props.translate("nav.notifications")}</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <section className="page-area">
                <h6 className="sub-title d-flex justify-content-between align-items-center">
                  {translate("notifications.notifications")}
                  <a
                    href="#!"
                    className={`${styles.clearBtn} ${
                      this.state.isClearing ? styles.clearing : ``
                    }`}
                    onClick={
                      !this.state.isClearing
                        ? this.handleConfirmClearNotifications
                        : (e) => e.preventDefault()
                    }
                  >
                    {translate("notifications.clear")}
                  </a>
                </h6>
                <div
                  className={`${styles.Notifications} mt-4 d-flex flex-column`}
                >
                  {notifications.length ? (
                    <InfiniteScroll
                      dataLength={this.state.notifications.length}
                      next={this.loadMore}
                      hasMore={this.state.hasMore}
                      loader={
                        <div className="w-100 d-flex justify-content-center mt-4">
                          <div className="loading-dots d-flex">
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      }
                      style={{ width: "100%" }}
                      className="d-flex justify-content-start align-items-start flex-wrap"
                    >
                      {notifications}
                    </InfiniteScroll>
                  ) : (
                    <p className="no-record">
                      {this.props.translate("notifications.no_notification")}
                    </p>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    check: () => dispatch(check([])),
    update: (user) => dispatch(update(user)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    notifications: state.notification.notifications,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Notifications));
