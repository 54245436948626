import React from "react";
import SingleDocument from "./SingleDocument";

export default function DocumentLists(props) {
  const { styles, translate, loading, files } = props;

  const filesArray = files && Object.keys(files);

  const filesList = loading ? (
    <div className="col text-center mb-5">
      {translate("globalTranslations.loading")}...
    </div>
  ) : filesArray && filesArray.length ? (
    filesArray.map((key) => {
      return (
        <SingleDocument
          key={key}
          file={files[key]}
          styles={styles}
          translate={translate}
          seeDocumentDetail={props.seeDocumentDetail}
        />
      );
    })
  ) : (
    translate("documents.no_record_found")
  );

  return (
    <div className={`col ${styles.list}`}>
      <div className="row">
        <div className="col">{filesList}</div>
      </div>
      <div className="row mt-4">
        <div className="col-10 offset-1 col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
          <button
            className="button-primary w-100"
            onClick={(e) => props.handleToggle(e, props.id)}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.25 0.5C19.0153 0.5 24.5 5.98464 24.5 12.75C24.5 19.5153 19.0153 25 12.25 25C5.48464 25 0 19.5153 0 12.75C0 5.98464 5.48464 0.5 12.25 0.5ZM12.25 6.5C12.6642 6.5 13 6.83579 13 7.25V12H17.75C18.1642 12 18.5 12.3358 18.5 12.75C18.5 13.1642 18.1642 13.5 17.75 13.5H13V18.25C13 18.6642 12.6642 19 12.25 19C11.8358 19 11.5 18.6642 11.5 18.25V13.5H6.75C6.33579 13.5 6 13.1642 6 12.75C6 12.3358 6.33579 12 6.75 12H11.5V7.25C11.5 6.83579 11.8358 6.5 12.25 6.5Z"
                fill="white"
              />
            </svg>
            <span className="ml-2">{translate("documents.add_new")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
