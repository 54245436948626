import React, { Component } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withLocalize } from "react-localize-redux";

import Tabs from "../utils/components/Tabs";

import { externalRoute } from "../app/routes";

import productsEn from "../translations/en/products.json";
import productsTr from "../translations/tr/products.json";
import API, { headers } from "../utils/API";
import Helmet from "react-helmet";
import { handleErrors } from "../utils/helper";

class Products extends Component {
  state = {
    tabs: {
      new: {
        id: 1,
        links: { en: "/products/published", tr: "/urunler/yayinda" },
        names: { en: "Published", tr: "Yayında" },
      },
      onHold: {
        id: 3,
        links: { en: "/products/on-hold", tr: "/urunler/inceleniyor" },
        names: { en: "On Hold", tr: "İnceleniyor" },
      },
    },
  };

  APIconfigs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(productsEn, "en");
    this.props.addTranslationForLanguage(productsTr, "tr");
  }

  componentDidMount() {
    this.redirectIfNull();

    API.get("products", this.APIconfigs)
      .then((response) => {
        const { published, pending_approval } = response.data.summary;

        const tabs = { ...this.state.tabs };

        tabs["new"] = { ...tabs["new"], resultsCount: published };
        tabs["onHold"] = { ...tabs["onHold"], resultsCount: pending_approval };

        this.setState({ tabs });
      })
      .catch((err) => handleErrors(err));
  }

  componentDidUpdate() {
    this.redirectIfNull();
  }

  redirectIfNull = () => {
    const link = matchPath(window.location.pathname, {
      path: externalRoute["productTypes"].links[this.props.activeLanguage.code],
    });

    !link &&
      this.props.history.push(
        this.state.tabs["new"].links[this.props.activeLanguage.code]
      );
  };
  render() {
    return (
      <div className="pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app productsFix">
        <Helmet>
          <title>{this.props.translate("nav.products")}</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <section className="page-area">
                <Tabs
                  tabs={this.state.tabs}
                  routePath={
                    externalRoute["productTypes"].links[
                      this.props.activeLanguage.code
                    ]
                  }
                  property={externalRoute["productTypes"].property}
                  lang={this.props.activeLanguage.code}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(Products));
