import React from "react";

export default function AuthorizedPerson(props) {
  const { styles, translate, inputProps } = props;
  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h3
              className={`d-flex align-items-end justify-content-between ${styles.pageTitle}`}
            >
              {translate("profile.authorized_person")}
              <span
                className={styles.backButton}
                onClick={(e) => props.handleSettingChange(e, "")}
              >
                {translate("globalTranslations.go_back")}
              </span>
            </h3>
          </div>
        </div>
        <div className="row mt-3 mt-md-5">
          <div className="col">
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="authorizedFirstName"
                value={inputProps.authorizedFirstName}
                onChange={props.handleChange}
                className={`form-control ${
                  inputProps.authorizedFirstName !== "" && `filled`
                }`}
                id="authorizedFirstName"
                required
              />
              <label
                htmlFor="authorizedFirstName"
                className="position-absolute"
              >
                <span> {translate("profile.first_name")}</span>
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="authorizedLastName"
                value={inputProps.authorizedLastName}
                onChange={props.handleChange}
                className={`form-control ${
                  inputProps.authorizedLastName !== "" && `filled`
                }`}
                id="authorizedLastName"
                required
              />
              <label htmlFor="authorizedLastName" className="position-absolute">
                <span>{translate("profile.last_name")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-md-0">
          <div className="col col-12 col-md-6">
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="authorizedEmail"
                value={inputProps.authorizedEmail}
                onChange={props.handleChange}
                className={`form-control ${
                  inputProps.authorizedEmail !== "" && `filled`
                }`}
                id="authorizedEmail"
                required
                onBlur={(e) => props.inputValidation(e, "email")}
              />
              <label htmlFor="authorizedEmail" className="position-absolute">
                <span>{translate("profile.email")}</span>
              </label>
            </div>
          </div>

          <div className="col col-12 col-md-6 mt-2 mt-md-0">
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="authorizedPhone"
                value={inputProps.authorizedPhone}
                onChange={props.handleChange}
                className={`form-control ${
                  inputProps.authorizedPhone !== "" && `filled`
                }`}
                id="authorizedPhone"
                onBlur={(e) => props.inputValidation(e, "phone")}
                required
              />
              <label htmlFor="authorizedPhone" className="position-absolute">
                <span>{translate("profile.phone")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6 offset-3">
            <button
              className="button-primary w-100"
              onClick={(e) => props.handleSubmit(e, "authorized")}
              disabled={
                props.isSending ||
                !inputProps.authorizedFirstName ||
                !inputProps.authorizedLastName ||
                !inputProps.authorizedEmail ||
                !inputProps.authorizedPhone
              }
            >
              {translate("globalTranslations.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
