import React from "react";

import starFull from "../assets/star-full.svg";
import starHalf from "../assets/star-half.svg";
import starEmpty from "../assets/star-empty.svg";

import styles from "./Stars.module.scss";

export default function Stars(props) {
  const { rating } = props;

  const full = Math.floor(rating);
  const empty = 5 - Math.ceil(rating);
  const half = rating - Math.floor(rating) !== 0 && 1;

  let starsArr = [];

  for (let i = 0; i < full; i++) {
    starsArr.push(1);
  }

  if (half) starsArr.push(2);

  for (let i = 0; i < empty; i++) {
    starsArr.push(3);
  }

  let index = 0;
  const stars =
    starsArr &&
    starsArr.map(data => {
      return data === 1 ? (
        <img src={starFull} alt="Star Full" key={index++} />
      ) : data === 2 ? (
        <img src={starHalf} alt="Star Half" key={index++} />
      ) : (
        <img src={starEmpty} alt="Star Empty" key={index++} />
      );
    });

  return (
    <div className={styles.Stars}>
      <div className={styles.starsHolder}>{stars}</div>

      <span className={styles.rating}>{props.rating}</span>
    </div>
  );
}
