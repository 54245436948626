import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import toastr from "toastr";

import API, { headers } from "../utils/API";

import authEN from "../translations/en/auth.json";
import authTR from "../translations/tr/auth.json";

import { handleErrors, seeHidePassword } from "../utils/helper";

import ErrorArea from "../utils/components/ErrorArea";
import { guestRoute, route } from "../app/routes";
import Helmet from "react-helmet";
import SuccessArea from "../utils/components/SuccessArea";
import is from "is_js";
import Loader from "../app/components/Loader";
import InfoArea from "../utils/components/InfoArea";

class CreatePassword extends Component {
  state = {
    password: "",
    seePass: false,
    passwordAgain: "",
    seePassAgain: false,
    error: false,
    requestResponse: "",
    isSending: false,
    isTokenValid: false,
    isValidating: true,
    tokenSent: false,
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(authEN, "en");
    this.props.addTranslationForLanguage(authTR, "tr");
  }

  componentDidMount = () => {
    const { email, token } = this.props.match.params;
    API.post(
      "password/token/validate",
      { token, email },
      {
        headers: {
          ...headers,
        },
      }
    )
      .then(() => {
        this.setState({ isValidating: false, isTokenValid: true });
      })
      .catch((err) => {
        if (err.response.data.dataCode === 400)
          this.setState({ isValidating: false, isTokenValid: false });
        else handleErrors(err);
      });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    await this.setState({ error: false, requestResponse: "", isSending: true });

    const configs = {
      headers: {
        ...headers,
      },
    };

    const { email, token } = this.props.match.params;

    const params = {
      email,
      token,
      password: this.state.password,
      password_confirmation: this.state.passwordAgain,
    };

    API.post("password/create", params, configs)
      .then((response) => {
        const { message } = response.data.info;
        this.setState({
          requestResponse: message,
          error: false,
          isSending: false,
        });

        const that = this;
        setTimeout(() => {
          that.props.history.push(
            guestRoute.login.links[that.props.activeLanguage.code]
          );
        }, 1750);
      })
      .catch((err) => {
        const { info, errors } = err.response.data;

        if (errors && Object.keys(errors).length)
          Object.keys(errors).map((key) => {
            toastr.error(errors[key]);
            return true;
          });

        this.setState({
          requestResponse: info.message,
          error: true,
          isSending: false,
        });
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleRequestToken = () => {
    this.setState({ isSending: true }, () => {
      const configs = {
        headers: {
          ...headers,
        },
      };

      const { email } = this.props.match.params;

      API.post("password/token/resend", { email }, configs)
        .then((res) => {
          const { message } = res.data.info;

          toastr.success(message);
          this.props.history.push(
            route.home.links[this.props.activeLanguage.code]
          );
        })
        .catch((err) => {
          this.setState({
            isSending: false,
          });

          handleErrors(err);
        });
    });
  };

  render() {
    const { translate } = this.props;
    const response =
      this.state.requestResponse !== "" ? (
        this.state.error ? (
          <ErrorArea>{this.state.requestResponse}</ErrorArea>
        ) : (
          <SuccessArea>{this.state.requestResponse}</SuccessArea>
        )
      ) : null;
    return (
      <div className="container">
        <Helmet>
          <title>{this.props.translate("create_password.page_title")}</title>
        </Helmet>
        <div className="row">
          <div className="col col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
            <h3 className="page-title">
              {translate("create_password.page_title")}
            </h3>
            <section className="page-area">
              {this.state.isValidating ? (
                <Loader />
              ) : this.state.isTokenValid ? (
                <form
                  onSubmit={this.handleSubmit}
                  className="col d-flex flex-column"
                >
                  <InfoArea>{translate("create_password.info")}</InfoArea>
                  <div className="form-group position-relative sliding-label">
                    <input
                      type={this.state.seePass ? `text` : `password`}
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      className={`form-control ${
                        this.state.password !== "" && `filled`
                      } ${is.safari() ? `safari-fix` : ``}`}
                      id="password"
                      required
                    />
                    <label htmlFor="password" className="position-absolute">
                      <span>{translate("auth.password")}</span>
                    </label>
                    <button
                      className="btn position-absolute"
                      style={{ bottom: "7px", right: 0 }}
                      type="button"
                      onClick={() =>
                        this.setState({
                          seePass: seeHidePassword(this.state.seePass),
                        })
                      }
                    >
                      <i
                        className={`far ${
                          this.state.seePass ? `fa-eye-slash` : `fa-eye`
                        } text-muted`}
                      />
                    </button>
                  </div>
                  <div className="form-group position-relative sliding-label">
                    <input
                      type={this.state.seePassAgain ? `text` : `password`}
                      name="passwordAgain"
                      value={this.state.passwordAgain}
                      onChange={this.handleChange}
                      className={`form-control ${
                        this.state.passwordAgain !== "" && `filled`
                      } ${is.safari() ? `safari-fix` : ``}`}
                      id="passwordAgain"
                      required
                    />
                    <label
                      htmlFor="passwordAgain"
                      className="position-absolute"
                    >
                      <span>{translate("auth.confirm_password")}</span>
                    </label>
                    <button
                      className="btn position-absolute"
                      style={{ bottom: "7px", right: 0 }}
                      type="button"
                      onClick={() =>
                        this.setState({
                          seePassAgain: seeHidePassword(
                            this.state.seePassAgain
                          ),
                        })
                      }
                    >
                      <i
                        className={`far ${
                          this.state.seePassAgain ? `fa-eye-slash` : `fa-eye`
                        } text-muted`}
                      />
                    </button>
                  </div>
                  {response}
                  <button
                    className="button-primary mt-4"
                    disabled={this.state.isSending}
                  >
                    {translate("create_password.set_my_password")}
                  </button>
                  {this.state.loginError}
                </form>
              ) : (
                <div className="invalid-token d-flex flex-column align-items-center">
                  <h5 className="title text-center">
                    {translate("create_password.invalid_token")}
                  </h5>
                  <p className="desc text-center">
                    {translate("create_password.invalid_token_desc")}
                  </p>
                  <button
                    className="button-primary w-auto px-4 mt-4"
                    onClick={this.handleRequestToken}
                    disabled={this.state.isSending}
                  >
                    {translate("create_password.send_again")}
                  </button>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(CreatePassword);
