import moment from "moment";
import Switch from "../../../../utils/components/Switch";

const SingleAvailability = ({
  customTitle,
  date,
  index,
  handleSwitchClosed,
  isSaving,
}) => {
  return (
    <div className="row mb-2 pb-2 border-bottom" key={index}>
      <div className="col-7">
        <h6>{customTitle || moment(date?.dateTime).format("HH:mm")}</h6>
      </div>
      <div className="col-5">
        <Switch
          inputProps={{
            type: "checkbox",
            name: `time-${index}`,
            checked: !date?.closed,
            id: `time-${index}`,
            onChange: () => {
              handleSwitchClosed(date);
            },
            disabled: isSaving,
          }}
        >
          {date?.closed ? `Kapalı` : `Açık`}
        </Switch>
      </div>
    </div>
  );
};

export default SingleAvailability;
