import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { matchPath } from "react-router";
import { connect } from "react-redux";

import { get, search } from "../bookingActions";

import "../Bookings.scss";
import API, { headers } from "../../utils/API";
import Axios from "axios";
import { externalRoute } from "../../app/routes";

class Search extends Component {
  state = {
    isSearchOn: false,
    isSearching: false,
    page: 1,
    pageCount: 1,
    limit: 15,
    pathname: window.location.pathname,
    prevTabs: {},
    searchTabs: {},
    q: "",
  };

  componentDidMount = () => {
    this.setState({ prevTabs: this.props.bookingTabs });
  };

  componentDidUpdate = (prevProps) => {
    if (window.location.pathname !== this.state.pathname) {
      this.setState({
        pathname: window.location.pathname,
      });

      if (this.state.isSearchOn) this.handleSearch(true);
    }

    if (
      Boolean(Object.keys(this.props.bookingTabs).length) &&
      !Boolean(Object.keys(prevProps.bookingTabs).length)
    ) {
      this.setState({ prevTabs: this.props.bookingTabs });
    }
  };

  handleSearchBoxToggle = (e) => {
    if (e) e.preventDefault();

    this.setState(
      (prevState) => {
        return { isSearchOn: !prevState.isSearchOn };
      },
      () => {
        const bookingResultsArea = document.getElementById(
          "booking-results-area"
        );
        if (this.state.isSearchOn) {
          bookingResultsArea.classList.add("search-active");
        } else {
          this.props.search(false);
          bookingResultsArea.classList.remove("search-active");
          bookingResultsArea.classList.remove("searching-active");
          bookingResultsArea.classList.remove("search-finished");
          this.updateTabs();
        }
      }
    );
  };

  handleSetValue = (e) => {
    this.setState({ q: e.target.value });
    this.handleSearch();
  };

  searchInterval = "";

  handleSearch = (skipTimeout = false) => {
    const match = matchPath(window.location.pathname, {
      path: externalRoute.bookingTypes.links[this.props.activeLanguage.code],
      exact: externalRoute.bookingTypes.exact,
      strict: false,
    });

    const typeInText = match.params.type;
    const type =
      typeInText === "yeni" || typeInText === "new"
        ? 3
        : typeInText === "beklemede" || typeInText === "on-hold"
        ? 8
        : typeInText === "onaylandi" || typeInText === "approved"
        ? 4
        : typeInText === "tamamlandi" || typeInText === "done"
        ? 6
        : typeInText === "diger" || typeInText === "other"
        ? 7
        : 3;

    if (this.props.searchResults) this.props.search(false);

    clearInterval(this.searchInterval);

    const bookingResultsArea = document.getElementById("booking-results-area");

    if (this.state.q !== "") {
      bookingResultsArea.classList.remove("search-finished");
      bookingResultsArea.classList.add("searching-active");

      this.searchInterval = setTimeout(
        async () => {
          const source = Axios.CancelToken.source();

          const APIconfigs = {
            headers: {
              ...headers,
              Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
            },
            cancelToken: source.token,
          };
          await API.get(
            `bookings/?status=${type}&limit=${this.state.limit}&page=${this.state.page}&q=${this.state.q}`,
            APIconfigs
          ).then((response) => {
            bookingResultsArea.classList.remove("searching-active");
            bookingResultsArea.classList.add("search-finished");
            this.props.search(response.data.data);

            const { pending, confirmed, completed, other } =
              response.data.summary;

            const newTabs = {
              new: response.data.summary.new,
              pending,
              confirmed,
              completed,
              other,
            };

            this.updateTabs(newTabs);
          });
        },
        skipTimeout ? 0 : 800
      );
    } else {
      bookingResultsArea.classList.remove("searching-active");
      bookingResultsArea.classList.remove("search-finished");
    }
  };

  updateTabs = (newTabs = null) => {
    if (!newTabs) {
      newTabs = {
        new: this.state.prevTabs.new.resultsCount,
        pending: this.state.prevTabs.onHold.resultsCount,
        confirmed: this.state.prevTabs.approved.resultsCount,
        completed: this.state.prevTabs.done.resultsCount,
        other: this.state.prevTabs.other.resultsCount,
      };
    }

    const tabs = { ...this.props.bookingTabs };

    tabs["new"] = {
      ...tabs["new"],
      resultsCount: parseInt(newTabs.new),
    };
    tabs["onHold"] = {
      ...tabs["onHold"],
      resultsCount: parseInt(newTabs.pending),
    };
    tabs["approved"] = {
      ...tabs["approved"],
      resultsCount: parseInt(newTabs.confirmed),
    };
    tabs["done"] = {
      ...tabs["done"],
      resultsCount: parseInt(newTabs.completed),
    };
    tabs["other"] = {
      ...tabs["other"],
      resultsCount: parseInt(newTabs.other),
    };

    this.props.get(tabs);
  };

  render() {
    return (
      <div className="bookingSearchArea mt-md-4 d-flex align-items-center justify-content-between">
        <label
          htmlFor="bookingSearchBox"
          onClick={() => {
            if (!this.state.isSearchOn) this.handleSearchBoxToggle();
          }}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.4605 15.5317L21.8068 20.878C22.0605 21.1317 22.0605 21.5512 21.8068 21.8049C21.68 21.9366 21.5093 22 21.3434 22C21.1776 22 21.0068 21.9317 20.88 21.8049L15.5337 16.4585C13.8849 17.8927 11.7337 18.761 9.38244 18.761C4.21171 18.761 0.00195312 14.5512 0.00195312 9.38049C0.00195312 4.20976 4.20683 0 9.38244 0C14.5532 0 18.7629 4.20488 18.7629 9.38049C18.7629 11.7317 17.8946 13.8829 16.4605 15.5317ZM9.37756 1.31707C4.93366 1.31707 1.31415 4.93171 1.31415 9.38049C1.31415 13.8293 4.93366 17.4488 9.37756 17.4488C13.8263 17.4488 17.441 13.8244 17.441 9.38049C17.441 4.93659 13.8263 1.31707 9.37756 1.31707Z"
              fill="white"
            />
          </svg>
        </label>
        <CSSTransition
          in={this.state.isSearchOn}
          timeout={300}
          unmountOnExit
          classNames="search-box"
        >
          <input
            type="text"
            id="bookingSearchBox"
            placeholder={this.props.translate("bookings.search_placeholder")}
            onChange={this.handleSetValue}
            className="selectable"
            autoFocus
          />
        </CSSTransition>
        <a href="#!" onClick={this.handleSearchBoxToggle}>
          {this.state.isSearchOn
            ? this.props.translate("bookings.do_nothing")
            : this.props.translate("bookings.search")}
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchResults: state.booking.searchResults,
    bookingTabs: state.booking.tabs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (bookings) => dispatch(search(bookings)),
    get: (bookings) => dispatch(get(bookings)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(withRouter(Search)));
