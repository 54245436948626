import is from "is_js";
import React from "react";
import { CSSTransition } from "react-transition-group";
import ErrorArea from "../../utils/components/ErrorArea";
import {
  handleErrors,
  seeHidePassword,
  validateInput,
} from "../../utils/helper";
import API, { headers } from "../../utils/API";

import { inactiveLoggedRoute } from "../../app/routes";
import { connect } from "react-redux";
import { login } from "../../auth/authActions";
import Checkbox from "../../utils/components/Checkbox";
import { useEffect } from "react";
import Modal from "../../utils/components/Modal";
import Axios from "axios";

const Form = ({
  translate,
  sending,
  setCompleted,
  login,
  history,
  lang,
  ...rest
}) => {
  const [inputs, setInputs] = React.useState({
    companyName: "",
    authorizedFirstName: "",
    authorizedLastName: "",
    email: "",
    phone: "",
    password: "",
    clarificationText: false,
    termsOfUsage: false,
  });

  const [agreements, setAgreements] = React.useState({
    clarificationText: "",
    termsOfUsage: "",
  });

  const [selectedAgreement, setSelectedAgreement] = React.useState(null);

  const [seePassword, setSeePassword] = React.useState(false);
  const [isFilled, setIsFilled] = React.useState(false);
  const [error, setError] = React.useState({ is: false, message: "" });
  const [isSending, setIsSending] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    const requests = [
      API.get(`legals/im-aydinlatma-metni`, {
        headers: {
          ...headers,
          "Accept-Language": lang,
        },
      }),
      API.get(`legals/im-kullanim-sartlari`, {
        headers: {
          ...headers,
          "Accept-Language": lang,
        },
      }),
    ];

    Axios.all(requests)
      .then(
        Axios.spread((...responses) => {
          const clarificationText = responses[0].data.data;
          const termsOfUsage = responses[1].data.data;

          setAgreements({
            clarificationText: clarificationText?.content || "",
            termsOfUsage: termsOfUsage?.content || "",
          });
        })
      )
      .catch((err) => handleErrors(err));
  }, [lang]);

  React.useEffect(() => {
    const required = [
      { type: "companyName", value: inputs.companyName },
      { type: "firstName", value: inputs.authorizedFirstName },
      { type: "lastName", value: inputs.authorizedLastName },
      { type: "email", value: inputs.email },
      { type: "phone", value: inputs.phone },
      { type: "password", value: inputs.password },
      { type: "checkbox", value: inputs.clarificationText },
      { type: "checkbox", value: inputs.termsOfUsage },
    ];

    const check = required.map((data) => {
      if (data.type === "phone" || data.type === "email")
        return validateInput(data.type, data.value);
      return data.value !== "" && data.value;
    });

    const isFilledCheck = !check.includes(false);

    if (isFilled !== isFilledCheck) setIsFilled(isFilledCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const onChange = (event) => {
    const { type, name, value, checked } = event.target;

    event.target.classList.remove("error");

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleValueCheck = (e, type) => {
    if (!validateInput(type, e.target.value)) {
      e.target.classList.add("error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setError({ is: false, message: "" });
    setIsSending(true);

    const params = {
      supplier_name: inputs.companyName,
      authorized_first_name: inputs.authorizedFirstName,
      authorized_last_name: inputs.authorizedLastName,
      authorized_email: inputs.email,
      authorized_phone: inputs.phone,
      authorized_password: inputs.password,
    };

    API.post("user/register", params, headers)
      .then(() => {
        API.post(
          "user/login",
          {
            email: params.authorized_email,
            password: params.authorized_password,
          },
          headers
        ).then(({ data: res }) => {
          const { access_token: token, token_type, refresh_token } = res.data;

          API.get("account/types", {
            headers: { ...headers, Authorization: `${token_type} ${token}` },
          });

          const config = {
            headers: { ...headers, Authorization: `${token_type} ${token}` },
          };

          API.get("account", config).then(({ data: res }) => {
            const { data } = res;

            const user = {
              id: data.id,
              firstName: data.authorized_first_name,
              lastName: data.authorized_last_name,
              email: data.authorized_email,
              avatar: data.avatar_url,
              lang: data.account_language,
              slug: data.slug,
              supplierName: data.supplier_name,
              notificationCount: data.notification_count,
              approvedAccount: data.approved_account,
              approvalProgress: data.account_checklist,
              approvalStatus: data.status_id,
              supplierType: data.contact.company_type,
              _tokenType: token_type,
              _token: token,
              _r_token: refresh_token,
            };

            setIsSending(false);

            login({
              ...user,
            });

            history.push(
              inactiveLoggedRoute.completion.links[
                data.account_language
              ].replace(":step?", "saglayici-turu")
            );
          });
        });
      })
      .catch((error) => {
        if (error.response) {
          const { errors } = error.response.data;

          const message = errors[Object.keys(errors)[0]];

          setError({ is: true, message });
          setIsSending(false);
        } else {
          console.log(error);
        }
      });
  };

  return (
    <form onSubmit={onSubmit} noValidate className="register-form">
      <div className="row">
        <div className="col">
          <div className="form-group position-relative sliding-label">
            <input
              type="text"
              name="companyName"
              value={inputs.companyName}
              onChange={onChange}
              className={`form-control ${
                inputs.companyName !== "" && `filled`
              }`}
              id="companyName"
              required
              autoFocus
              tabIndex="1"
            />
            <label htmlFor="companyName" className="position-absolute">
              <span>{translate("register.company_name")}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group position-relative sliding-label">
            <input
              type="text"
              name="authorizedFirstName"
              value={inputs.authorizedFirstName}
              onChange={onChange}
              className={`form-control ${
                inputs.authorizedFirstName !== "" && `filled`
              }`}
              id="authorizedFirstName"
              required
              tabIndex="2"
            />
            <label htmlFor="authorizedFirstName" className="position-absolute">
              <span>{translate("register.authorized_first_name")}</span>
            </label>
          </div>
        </div>
        <div className="col">
          <div className="form-group position-relative sliding-label">
            <input
              type="text"
              name="authorizedLastName"
              value={inputs.authorizedLastName}
              onChange={onChange}
              className={`form-control ${
                inputs.authorizedLastName !== "" && `filled`
              }`}
              id="authorizedLastName"
              required
              tabIndex="3"
            />
            <label htmlFor="authorizedLastName" className="position-absolute">
              <span>{translate("register.authorized_last_name")}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group position-relative sliding-label">
            <input
              type="email"
              name="email"
              value={inputs.email}
              onChange={onChange}
              className={`form-control ${inputs.email !== "" && `filled`}`}
              id="email"
              onBlur={(e) => handleValueCheck(e, "email")}
              required
            />
            <label htmlFor="email" className="position-absolute">
              <span>{translate("register.email_address")}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group position-relative sliding-label phone-code">
            <input
              type="tel"
              name="phone"
              value={inputs.phone}
              onChange={onChange}
              onBlur={(e) => handleValueCheck(e, "phone")}
              className={`form-control ${inputs.phone !== "" && `filled`}`}
              id="phone"
              placeholder="+90 123 456 7890"
              required
            />
            <label htmlFor="phone" className="position-absolute">
              <span>{translate("register.phone_number")}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="form-group position-relative sliding-label">
        <input
          type={seePassword ? `text` : `password`}
          name="password"
          value={inputs.password}
          onChange={onChange}
          className={`form-control ${inputs.password !== "" && `filled`} ${
            is.safari() ? `safari-fix` : ``
          }`}
          id="password"
          required
        />
        <label htmlFor="password" className="position-absolute">
          <span>{translate("auth.password")}</span>
        </label>
        <button
          className="btn position-absolute"
          style={{ bottom: "7px", right: 0 }}
          type="button"
          onClick={() => setSeePassword(seeHidePassword(seePassword))}
        >
          <i
            className={`far ${
              seePassword ? `fa-eye-slash` : `fa-eye`
            } text-muted`}
          />
        </button>
      </div>
      <div className="row">
        <div className="col">
          <Checkbox
            inputProps={{
              name: "clarificationText",
              id: "clarificationText",
              checked: inputs.clarificationText,
              onChange: (e) => {
                setInputs({
                  ...inputs,
                  clarificationText: e.target?.checked,
                });
              },
            }}
          >
            <p className="agreements m-0">
              {translate("register.clarification_text_agreement", {
                clarificationText: (
                  <a
                    href={`${process.env.REACT_APP_BUCKETLIST_URL}/gizlilik-politikasi/saglayici-aydinlatma-metni`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedAgreement(agreements.clarificationText);
                      setModalShow(true);
                    }}
                  >
                    {translate("globalTranslations.enlightment_text")}
                  </a>
                ),
              })}
            </p>
          </Checkbox>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Checkbox
            inputProps={{
              name: "termsOfUsage",
              id: "termsOfUsage",
              checked: inputs.termsOfUsage,
              onChange: (e) => {
                setInputs({
                  ...inputs,
                  termsOfUsage: e.target?.checked,
                });
              },
            }}
          >
            <p className="agreements m-0">
              {translate("register.terms_of_usage_agreement", {
                termsOfUsage: (
                  <a
                    href={`${process.env.REACT_APP_BUCKETLIST_URL}/im-kullanim-sartlari`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedAgreement(agreements.termsOfUsage);
                      setModalShow(true);
                    }}
                  >
                    {translate("globalTranslations.terms_of_use")}
                  </a>
                ),
              })}
            </p>
          </Checkbox>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <button
            className="button-primary w-100"
            onClick={isFilled ? onSubmit : null}
            disabled={!isFilled || isSending}
          >
            {translate("auth.register")}

            {!isFilled ? (
              <span className="disabled-tooltip position-absolute w-75">
                {translate("auth.fill_to_register")}
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>

      <CSSTransition in={error.is} timeout={300} unmountOnExit>
        <div className="row mt-5">
          <div className="col">
            <ErrorArea>{error.message}</ErrorArea>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={Boolean(modalShow && selectedAgreement)}
        timeout={300}
        unmountOnExit
        classNames="modal"
      >
        <Modal
          handleToggle={() => setModalShow((prevModalShow) => !prevModalShow)}
          id="agreementModal"
        >
          <div className="bl-modal-content apply-fix d-flex flex-column justify-content-start align-items-center overflow-auto">
            <span dangerouslySetInnerHTML={{ __html: selectedAgreement }} />
            <button
              type="button"
              className="button-primary mt-2 w-50"
              onClick={() => setModalShow((prevModalShow) => !prevModalShow)}
            >
              {translate("globalTranslations.close")}
            </button>
          </div>
        </Modal>
      </CSSTransition>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
});

export default connect(null, mapDispatchToProps)(Form);
