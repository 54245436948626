import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import toastr from "toastr";

import ProfileTemplate from "./ProfileTemplate";
import API, { headers } from "../utils/API";

import styles from "./Profile.module.scss";
import Helmet from "react-helmet";
import { handleErrors } from "../utils/helper";

class Feedbacks extends Component {
  state = {
    email: this.props.user.email,
    feedback: "",
    isSending: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSending: true });

    const params = { feedback: this.state.feedback };

    const config = {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    };

    API.post("feedback", params, config)
      .then((response) => {
        toastr.clear();
        toastr.success(response.data.info.message, "", {
          progressBar: true,
        });

        this.setState({
          feedback: "",
          isSending: false,
        });
      })
      .catch((error) => {
        handleErrors(error);
        this.setState({ isSending: false });
      });
  };
  render() {
    const { translate } = this.props;
    return (
      <ProfileTemplate>
        <Helmet>
          <title>{translate("feedback.send_a_feedback")}</title>
        </Helmet>
        <div className={styles.pageContent}>
          <div className="row mb-2">
            <div className="col">
              <h3 className={styles.pageTitle}>
                {translate("feedback.send_a_feedback")}
              </h3>
              <h5 className={styles.pageDesc}>
                {translate("feedback.feedback_desc")}
              </h5>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col">
              <div className="form-group position-relative sliding-label">
                <textarea
                  type="feedback"
                  name="feedback"
                  value={this.state.feedback}
                  className={`form-control ${
                    this.state.feedback !== "" && `filled`
                  }`}
                  id="feedback"
                  onChange={this.handleChange}
                  autoFocus
                  style={{ height: "320px" }}
                />
                <label htmlFor="feedback" className="position-absolute">
                  <span>{translate("feedback.feedback")}</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6 offset-md-3">
              <button
                className="button-primary w-100"
                onClick={this.handleSubmit}
                disabled={this.state.isSending || this.state.feedback === ""}
              >
                {translate("feedback.send_feedback")}
              </button>
            </div>
          </div>
        </div>
      </ProfileTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(Feedbacks));
