import React from "react";
import { CSSTransition } from "react-transition-group";
import SidebarItem from "./SidebarItem";

export default function SidebarContent(props) {
  const {
    styles,
    handleChange,
    route,
    activeLanguage,
    contents,
    title,
    icon,
    slug,
  } = props;

  const sidebarContentProps = {
    styles: styles,
    route: route,
    lang: activeLanguage.code,
  };

  const sidebarContent =
    contents && contents.length
      ? contents.map((data) => {
          return (
            <SidebarItem
              key={data.slug}
              title={data.title}
              {...sidebarContentProps}
              slug={data.slug}
              cat={slug}
              contents={contents}
            />
          );
        })
      : null;

  return (
    <div className={styles.sidebarList}>
      <input
        type="radio"
        id={slug}
        name="sidebar-checker"
        value={slug}
        onChange={handleChange}
      />
      <label htmlFor={slug}>
        <h4 className={styles.listTitle}>
          <span
            className={styles.icon}
            dangerouslySetInnerHTML={{ __html: icon }}
          ></span>
          <span className={styles.text}>{title}</span>
          <span className={styles.arrow}>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="black"
                strokeWidth="1.44"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </h4>
      </label>
      <CSSTransition
        in={slug === props.openedSidebar}
        timeout={300}
        classNames="sidebar-content"
        unmountOnExit
      >
        <div className={styles.sidebarHolder}>
          <div className={styles.sidebar}>{sidebarContent}</div>
        </div>
      </CSSTransition>
    </div>
  );
}
