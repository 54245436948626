import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router";

import accountCompletionEN from "../translations/en/accountCompletion.json";
import accountCompletionTR from "../translations/tr/accountCompletion.json";
import { redirectLoggedUser } from "../utils/helper";
import Steps from "./components/Steps";

class AccountCompletion extends Component {
  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(accountCompletionEN, "en");
    this.props.addTranslationForLanguage(accountCompletionTR, "tr");
  }

  componentDidMount = async () => {
    this.props.history.push(
      await redirectLoggedUser(this.props.user, this.props.lang, true)
    );
  };

  render() {
    return (
      <div className="AccountCompletion">
        <Helmet>
          <title>{this.props.pageTitle || ""}</title>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Steps
                translate={this.props.translate}
                lang={this.props.activeLanguage?.code || "tr"}
                user={this.props.user}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3 d-flex justify-content-center">
              <section className="page-area">
                {!(`noDomTitle` in this.props) && (
                  <h3 className="content-title text-center">
                    {this.props.pageTitle}
                  </h3>
                )}
                {this.props.children}
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(withRouter(AccountCompletion));
