import React from "react";

import { CSSTransition } from "react-transition-group";
import { registerLocale } from "react-datepicker";
import moment from "moment";
import "moment/locale/tr";

import "react-datepicker/dist/react-datepicker.css";

import "../../../utils/assets/transitions.scss";
import "./Steps.scss";
import ErrorArea from "../../../utils/components/ErrorArea";

import tr from "date-fns/locale/tr";
import API, { headers } from "../../../utils/API";
import { handleErrors } from "../../../utils/helper";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect } from "react";
import { useState } from "react";
import SingleAvailability from "./components/SingleAvailability";

registerLocale("tr", tr);

const SixthStep = ({ translate, activeLangCode, product, error, user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dateRanges, setDateRanges] = useState({
    start: moment(),
    end: moment()
      .endOf("month")
      .add(
        moment().diff(moment().endOf("month"), "days", true) <= 0 ? 1 : 0,
        "day"
      ),
  });
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDateDetails, setSelectedDateDetails] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());

  const [configs] = useState({
    headers: {
      ...headers,
      Authorization: `${user._tokenType} ${user._token}`,
    },
  });

  useEffect(() => {
    if (dateRanges?.start && dateRanges?.end) getAvailabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    moment.locale(activeLangCode);
  }, [activeLangCode]);

  const getAvailabilities = () => {
    setIsLoading(true);
    const { id } = product;
    API.get(
      `products/${id}/availabilities?from_date=${moment(
        dateRanges?.start
      )?.format("YYYY-MM-DD")}&to_date=${moment(dateRanges?.end)?.format(
        "YYYY-MM-DD"
      )}`,
      configs
    )
      .then(({ data: res }) => {
        setDates(res.data?.availabilities);

        if (res.data?.availabilities?.length) {
          setSelectedDate(
            moment(res.data?.availabilities[0]?.dateTime).toDate()
          );
          setSelectedDateDetails(
            res.data?.availabilities.filter((item) =>
              item.dateTime.startsWith(
                moment(res.data?.availabilities[0]?.dateTime).format(
                  "YYYY-MM-DD"
                )
              )
            )
          );
        }

        setIsLoading(false);
      })
      .catch((err) => handleErrors(err));
  };

  const handleSwitchClosed = (date) => {
    setIsSaving(true);
    const newDates = [...dates];

    const index = newDates.findIndex((item) => item.dateTime === date.dateTime);

    const { id, reservationType } = product;

    const payload = {
      closeout_date: moment(date.dateTime).format("YYYY-MM-DD"),
    };

    if (reservationType && newDates[index]?.startTime?.id) {
      payload.product_start_time_id = newDates[index]?.startTime?.id;
    }

    API.post(`products/${id}/availabilities/close-out`, payload, configs).then(
      () => {
        newDates[index].closed = !newDates[index].closed;

        setDates(newDates);
        setIsSaving(false);
      }
    );
  };

  const handleToggleAll = () => {
    setIsSaving(true);
    const toggle = selectedDateDetails?.every((date) => date?.closed)
      ? false
      : true;

    const { id } = product;

    API.post(
      `products/${id}/availabilities/close-out`,
      {
        closeout_date: moment(selectedDate).format("YYYY-MM-DD"),
        toggle_all: toggle ? "close" : "open",
      },
      configs
    ).then(() => {
      const newSelectedDateDetails = [...selectedDateDetails];

      newSelectedDateDetails.forEach((date) => {
        date.closed = toggle;
      });

      setSelectedDateDetails(newSelectedDateDetails);
      setIsSaving(false);
    });
  };

  return (
    <div className="row">
      <div className="col-12 col-xl-5">
        <div className="row">
          <div className="col">
            <h3 className="step-title">
              {translate("create_product.timing_info")}
            </h3>
            <p className="step-desc">
              {translate("create_product.timing_info_desc")}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            {isLoading ? (
              <div className="w-100 text-center">Yükleniyor...</div>
            ) : (
              <Calendar
                locale="tr"
                view="month"
                className="w-100"
                minDetail="month"
                maxDetail="month"
                onChange={(value) => {
                  setSelectedDate(value);
                  setSelectedDateDetails(
                    dates.filter((date) =>
                      date?.dateTime?.startsWith(
                        moment(value).format("YYYY-MM-DD")
                      )
                    )
                  );
                }}
                activeStartDate={dateRanges?.start.toDate()}
                onActiveStartDateChange={(value) => {
                  const start =
                    moment(value.activeStartDate).diff(
                      moment(),
                      "months",
                      true
                    ) <= 0
                      ? moment()
                      : moment(value.activeStartDate).startOf("month");

                  const end = moment(value.activeStartDate)
                    .endOf("month")
                    .add(
                      moment().diff(moment().endOf("month"), "days", true) <= 0
                        ? 1
                        : 0,
                      "day"
                    );

                  setDateRanges({
                    start,
                    end,
                  });

                  setSelectedMonth(moment(value.activeStartDate).month());
                }}
                minDate={new Date()}
                showNeighboringMonth={false}
              />
            )}
          </div>
        </div>

        <CSSTransition in={error !== ""} timeout={300} unmountOnExit>
          <ErrorArea>{error}</ErrorArea>
        </CSSTransition>
      </div>
      <div className="col-12 col-xl-7">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <h5 className="date-title">
              {selectedDate
                ? moment(selectedDate).format("DD MMMM YYYY")
                : `Tarih Seçiniz`}
            </h5>
            {selectedDate && (
              <button
                onClick={handleToggleAll}
                className="button-transparent"
                style={{ fontSize: "14px" }}
                disabled={isSaving || !selectedDateDetails?.length}
              >
                Hepsini{" "}
                {selectedDateDetails?.every((date) => date?.closed)
                  ? `Aç`
                  : `Kapat`}
              </button>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            {selectedDateDetails?.length ? (
              product?.reservationType === 2 ? (
                <SingleAvailability
                  date={selectedDateDetails[0]}
                  index={0}
                  customTitle="Tüm Gün"
                  handleSwitchClosed={handleSwitchClosed}
                  isSaving={isSaving}
                />
              ) : (
                selectedDateDetails?.map((date, index) => (
                  <SingleAvailability
                    key={index}
                    date={date}
                    index={index}
                    handleSwitchClosed={handleSwitchClosed}
                    isSaving={isSaving}
                  />
                ))
              )
            ) : (
              selectedDate && (
                <div className="mb-4">
                  Seçilen tarihte uygun saat bulunamadı.
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixthStep;
