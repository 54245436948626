import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../utils/assets/logo.svg";
import app_store from "../../assets/app_store.svg";
import google_play from "../../assets/google_play.svg";
import { loggedRoute, guestRoute, route } from "../../routes";

export default function Footer(props) {
  const { translate, styles, langCode, user, logout } = props;
  return (
    <section className={`d-flex  ${styles.landingFooter}`}>
      <div className={styles.logo}>
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
          }}
        >
          <img src={logo} alt="Bucketlist Logo" />
        </Link>
      </div>
      <div className={`d-flex flex-wrap ${styles.footerNav}`}>
        <div className={styles.navSec}>
          <h6>{translate("landing.supplier_hub")}</h6>
          <ul>
            <li>
              <Link
                to={
                  user.isLoggedIn
                    ? loggedRoute.profile.links[langCode]
                    : guestRoute.login.links[langCode]
                }
              >
                {user.isLoggedIn
                  ? translate("nav.my_profile")
                  : translate("nav.login")}
              </Link>
            </li>
            <li>
              <Link
                to={
                  user.isLoggedIn
                    ? loggedRoute.bookings.links[langCode]
                    : guestRoute.login.links[langCode]
                }
              >
                {translate("nav.bookings")}
              </Link>
            </li>
            <li>
              <Link
                to={
                  user.isLoggedIn
                    ? loggedRoute.reviews.links[langCode]
                    : guestRoute.login.links[langCode]
                }
              >
                {translate("nav.reviews")}
              </Link>
            </li>
            {user.isLoggedIn ? (
              <li>
                <Link
                  to="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                >
                  {translate("nav.logout")}
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
        <div className={styles.navSec}>
          <h6>{translate("landing.support_services")}</h6>
          <ul>
            <li>
              <Link to={route.helpCenter.links[langCode]}>
                {translate("landing.help_center")}
              </Link>
            </li>
            <li>
              <Link to={route.contactUs.links[langCode]}>
                {translate("landing.contact_us")}
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.navSec}>
          <h6>{translate("landing.follow_us")}</h6>
          <ul>
            <li>
              <a
                href="https://facebook.com/bucketlistcomtr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/bucketlistcomtr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/bucketlistcomtr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://linkedin.com/company/bucketlistcomtr"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.appsArea}>
        <h6>{translate("landing.supplier_hub_apps")}</h6>
        <div className={styles.apps}>
          <a
            href="https://apps.apple.com/tr/app/id1483855482"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate("landing.apps_apple", {
              image: <img src={app_store} alt="App Store" />,
            })}
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.bucketlist.isletmemerkezi"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate("landing.apps_android", {
              image: <img src={google_play} alt="Google Play" />,
            })}
          </a>
        </div>
      </div>
    </section>
  );
}
