import React from "react";

export default function SingleNotification(props) {
  const { styles, notification } = props;

  return (
    <div
      className={`w-100 d-flex flex-column align-items-end ${styles.SingleNotificationHolder}`}
    >
      <div
        className={`w-100 position-relative d-flex justify-content-start align-items-center ${styles.SingleNotification}`}
      >
        <div className={styles.InfoArea}>
          <h6 className={styles.title}>{notification.title}</h6>
          <p className={styles.content}>{notification.detail}</p>
          <span className={styles.date}>{notification.created_at}</span>
        </div>
      </div>
      <div className={`divider list ${styles.divider}`}></div>
    </div>
  );
}
