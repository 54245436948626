import { useState } from "react";
import CurrencyInput from "../../../../utils/components/CurrencyMaskedInput";

const SinglePriceType = ({
  data,
  index,
  mainIndex,
  handleUpdatePriceType,
  isDefault = false,
}) => {
  const [showError, setShowError] = useState([]);

  return (
    <div className="row mb-4">
      {!isDefault && (
        <div className="col-12">
          <div className="currency-info mb-2">
            <p className="m-0">
              <span className="currency">{data.currency}</span>
              <span className="desc ml-1">
                Fiyat girilmediğinde otomatik çevrilir.
              </span>
            </p>
          </div>
        </div>
      )}
      <div className="col-12">
        <div className="price-holder">
          <div className="row">
            <div className="col-12">
              <header>
                <h6 className="m-0">{data.name}</h6>
              </header>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="prices">
                {data.prices?.map((price, j) => (
                  <div className="single-price" key={`${index}_${j}`}>
                    <div className="row align-items-center">
                      <div className="col-6">
                        <p className="m-0">{price.label}</p>
                      </div>
                      <div className="col-6">
                        <div className="input-group align-items-center">
                          <CurrencyInput
                            placeholder=""
                            type="text"
                            name="price"
                            value={price.price}
                            onChange={(e) => {
                              if (isDefault)
                                setShowError((prevState) => {
                                  const newState = [...prevState];
                                  const index = newState.indexOf(j);
                                  if (index > -1) {
                                    newState.splice(index, 1);
                                  }

                                  return newState;
                                });

                              handleUpdatePriceType(
                                e,
                                index,
                                data.id,
                                price.id
                              );
                            }}
                            onBlur={(e) => {
                              e.target.value = isNaN(parseInt(e.target.value))
                                ? ""
                                : e.target.value;

                              if (!isDefault) return;

                              if (e.target.value === "") {
                                setShowError((prevState) => {
                                  const newState = [...prevState];
                                  newState.push(j);
                                  return newState;
                                });
                              } else {
                                setShowError((prevState) => {
                                  const newState = [...prevState];
                                  const index = newState.indexOf(j);
                                  if (index > -1) {
                                    newState.splice(index, 1);
                                  }

                                  return newState;
                                });
                              }
                            }}
                            className={`form-control selectable ${
                              price.price !== "" ? `filled` : ``
                            } ${
                              showError.includes(j) && isDefault
                                ? `border-danger`
                                : ""
                            }`}
                            id={`price_${mainIndex}`}
                            required
                            maskOptions={{
                              includeThousandsSeparator: false,
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              {price.currency}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePriceType;
