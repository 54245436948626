import React from "react";
import { CSSTransition } from "react-transition-group";

import "../assets/MobileMenu.scss";
import MobileNavCatItem from "./MobileNavCatItem";
import MobileNavCat from "./MobileNavCat";

export default function MobileNav(props) {
  // eslint-disable-next-line
  const { translate, lang, selectedCat } = props;

  const contents = props.content.map((data) => {
    const catContents =
      data.contents && data.contents.length
        ? data.contents.map((content) => {
            return (
              <MobileNavCatItem
                key={content.slug}
                data={content}
                cat={content.slug}
                lang={lang}
              />
            );
          })
        : "";

    return (
      <MobileNavCat
        key={data.slug}
        data={data}
        id={data.slug}
        catContents={catContents}
        isActive={selectedCat === data.slug ? true : false}
      />
    );
  });

  return (
    <div className="help-mobile-menu position-relative w-100">
      <button
        type="button"
        className="nav-menu-mobile d-flex justify-content-between align-items-center d-md-none w-100"
        onClick={props.handleToggleMobileMenu}
      >
        <span className="label d-flex justify-content-start align-items-center w-100">
          <span className="help-detail-title">Kategori:</span>{" "}
          <span className="cat-title">{props.selectedCatTitle}</span>
        </span>
        <span className="currPage d-flex align-items-center">
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={props.isMobileMenuActive ? "menu-active" : ""}
          >
            <path
              d="M8.98828 1.01119L4.99968 5.55664L1.01107 1.01119"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>
      <CSSTransition
        in={props.isMobileMenuActive}
        timeout={300}
        unmountOnExit
        classNames="mobile-menu"
      >
        <ul className="nav-menu-mobile-content d-flex flex-column d-md-none">
          {contents}
        </ul>
      </CSSTransition>
    </div>
  );
}
