import React from "react";
import { Link } from "react-router-dom";

export default function Experience(props) {
  return (
    <div className="experience-holder w-100 d-flex flex-column align-items-end">
      <div className="experience w-100 position-relative d-flex justify-content-between align-items-center">
        <div className="cover-image d-flex justify-content-center align-items-center overflow-hidden rounded-circle">
          <img
            src={props.cover_image_url}
            alt={`${props.product_name} Cover`}
            className="rounded-circle h-100"
          />
        </div>

        <div className="info ml-3">
          <h4 className="title mb-2">{props.product_name}</h4>
          <span className="view_count">
            <span className="mr-2">
              {props.translate("dashboard.view_count")}:
            </span>{" "}
            {props.hit}
          </span>
        </div>
        <Link to="/ozet" className="more-link justify-self-end stretched-link">
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.90624 1.61426L11.6875 8.0726L4.90624 14.5309"
              stroke="#B6B6B6"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>

      <div className="divider list"></div>
    </div>
  );
}
