import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./Modal.module.scss";

export default class Modal extends Component {
  state = {
    modalActive: false,
  };

  componentDidMount() {
    document.querySelector("html").classList.add("overflow-hidden");
    document.body.classList.add("overflow-hidden");
    window.addEventListener("keydown", this.closeOnEsc, false);
    this.setState({
      modalActive: true,
    });
  }

  componentWillUnmount() {
    document.querySelector("html").classList.remove("overflow-hidden");
    document.body.classList.remove("overflow-hidden");
    window.removeEventListener("keydown", this.closeOnEsc, false);
    this.setState({ modalActive: false });
  }

  closeOnEsc = (e) => {
    if (parseInt(e.keyCode) === 27) {
      this.props.handleToggle(e, true);
    }
  };

  render() {
    return (
      <div
        className={`${styles.Modal} ${this.props.classes}`}
        id={this.props.id}
      >
        <div
          className={styles.overlay}
          onClick={(e) => {
            if (this.props.preventClose) return;
            e.preventDefault();
            this.props.handleToggle(e, true);
          }}
        ></div>
        <CSSTransition
          in={this.state.modalActive}
          timeout={300}
          unmountOnExit
          mountOnEnter
          classNames="modal-content"
        >
          <div className={styles.ContentHolder}>
            <div className={`${styles.Content} content`}>
              {!this.props.preventClose && (
                <a
                  href="#!"
                  className={styles.MobileCloseBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleToggle(e, true);
                  }}
                >
                  <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M14.35 0.0964355C6.44818 0.0964355 0 6.46598 0 14.2714C0 22.0769 6.44818 28.4464 14.35 28.4464C22.2518 28.4464 28.7 22.0769 28.7 14.2714C28.7 6.46598 22.2518 0.0964355 14.35 0.0964355Z"
                        fill="#11171D"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.4104 9.30811C19.1234 9.02107 18.6574 9.02044 18.3703 9.30748L14.3924 13.2854L10.3533 9.24624C10.0694 8.96236 9.60918 8.96236 9.3253 9.24624C9.04141 9.53012 9.04141 9.99038 9.32529 10.2743L13.3644 14.3134L9.38653 18.2913C9.0995 18.5783 9.10013 19.0443 9.38717 19.3313C9.6742 19.6184 10.1402 19.619 10.4272 19.332L14.4051 15.3541L18.4443 19.3932C18.7281 19.6771 19.1884 19.6771 19.4723 19.3932C19.7562 19.1093 19.7562 18.6491 19.4723 18.3652L15.4332 14.3261L19.411 10.3482C19.6981 10.0612 19.6974 9.59515 19.4104 9.30811Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </a>
              )}
              {this.props.children}
            </div>
            {!this.props.preventClose && (
              <a
                href="#!"
                className={styles.CloseBtn}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleToggle(e, true);
                }}
              >
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.7293 7.29716C21.3192 6.88711 20.6535 6.8862 20.2435 7.29625L14.5608 12.9789L8.7906 7.20877C8.38505 6.80323 7.72753 6.80323 7.32199 7.20877C6.91644 7.61432 6.91644 8.27183 7.32199 8.67738L13.0922 14.4476L7.40947 20.1302C6.99942 20.5403 7.00033 21.206 7.41038 21.6161C7.82043 22.0261 8.48616 22.027 8.89621 21.617L14.5789 15.9343L20.3491 21.7045C20.7546 22.11 21.4121 22.11 21.8177 21.7045C22.2232 21.2989 22.2232 20.6414 21.8177 20.2359L16.0475 14.4657L21.7302 8.78299C22.1402 8.37294 22.1393 7.70721 21.7293 7.29716Z"
                    fill="white"
                  />
                </svg>
              </a>
            )}
          </div>
        </CSSTransition>
      </div>
    );
  }
}
