import { Helmet } from "react-helmet";
import { Translate } from "react-localize-redux";
import DataTable from "react-data-table-component";

import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { useEffect, useState } from "react";
import API, { headers } from "../utils/API";
import { connect } from "react-redux";
import { handleErrors } from "../utils/helper";
import { CSSTransition } from "react-transition-group";
import Modal from "../utils/components/Modal";

import toastr from "toastr";

const Tickets = (props) => {
  const [columns, setColumns] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState({});
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    API.get(`tickets`, {
      headers: {
        ...headers,
        Authorization: `${props.user._tokenType} ${props.user._token}`,
      },
    })
      .then(({ data: res }) => {
        setTickets(
          res.data.map((ticket) => ({
            id: ticket.id,
            product: ticket.product.title,
            productCode: ticket.product.code,
            status: ticket.status.text,
            statusId: ticket.status.type,
            responsible: ticket.operator_name,
            createdAt: ticket.created_at,
            updatedAt: ticket.updated_at,
            title: ticket.title,
            description: ticket.description,
          }))
        );

        setIsLoading(false);
      })
      .catch((err) => {
        handleErrors(err);
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setColumns([
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        width: "100px",
      },
      {
        name: "Ürün",
        selector: (row) => row.product,
        sortable: true,
      },
      {
        name: "Durum",
        selector: (row) => row.status,
        sortable: true,
        width: "120px",
      },
      {
        name: "Sorumlu",
        selector: (row) => row.responsible,
        sortable: true,
      },
      {
        name: "İşlemler",
        cell: (row) => (
          <div className="d-flex justify-content-center align-items-center">
            <div className="mr-2" onClick={() => handleRowClicked(row)}>
              <AiFillEye size={"18px"} />
            </div>
            <div onClick={() => handleDelete(row)} role="button">
              <AiFillDelete size={"18px"} color="#ea3325" />
            </div>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets]);

  const handleRowClicked = (row) => {
    setTicket(tickets.find((ticket) => ticket.id === row.id));
    setModal(true);
  };

  const handleDelete = (row) => {
    if (!window.confirm("Bu kaydı silmek istediğinize emin misiniz?")) {
      return;
    }

    API.delete(`tickets/${row.id}`, {
      headers: {
        ...headers,
        Authorization: `${props.user._tokenType} ${props.user._token}`,
      },
    })
      .then(({ data: res }) => {
        if (res?.info?.message) {
          toastr.clear();
          toastr.success(res.info.message);
        }

        setTickets(tickets.filter((ticket) => ticket.id !== row.id));
      })
      .catch((err) => {
        handleErrors(err);
      });
  };

  return (
    <div className="TicketsPage pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app productsFix">
      <Translate>
        {({ translate }) => (
          <Helmet>
            <title>{translate("nav.tickets")}</title>
          </Helmet>
        )}
      </Translate>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <section className="page-area">
              <DataTable
                columns={columns}
                data={tickets}
                striped
                highlightOnHover
                pointerOnHover
                onRowClicked={handleRowClicked}
                noDataComponent="Kayıt bulunamadı"
                progressComponent="Yükleniyor..."
                progressPending={isLoading}
              />
            </section>
          </div>
        </div>
      </div>

      <CSSTransition in={modal} timeout={0} unmountOnExit>
        <Modal
          handleToggle={() => setModal(false)}
          id="product-detail"
          classes="product-detail-modal"
        >
          <div className="ticket-details p-4 position-relative">
            <span
              className={`status-badge badge position-absolute badge-${
                ticket?.statusId === 1
                  ? "success"
                  : ticket?.statusId === 2
                  ? "warning"
                  : "danger"
              }`}
            >
              {ticket?.status}
            </span>
            <div className="row">
              <div className="col-12">
                <h5 className="product-title mb-0">{ticket?.product}</h5>
                <p className="product-code mb-0">{ticket?.productCode}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="details">
                  <dl>
                    <dd>{ticket?.title}</dd>
                    <dt>Başlık</dt>
                  </dl>
                  <dl>
                    <dd>{ticket?.createdAt}</dd>
                    <dt>Eklenme Tarihi</dt>
                  </dl>
                  {ticket.createdAt !== ticket.updatedAt && (
                    <dl>
                      <dd>{ticket?.updatedAt}</dd>

                      <dt>Güncellenme Tarihi</dt>
                    </dl>
                  )}
                  <dl>
                    <dd>{ticket?.responsible}</dd>
                    <dt>Sorumlu</dt>
                  </dl>
                  <dl>
                    <dd>{ticket?.description}</dd>
                    <dt>Açıklama</dt>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Tickets);
