import React from "react";

export default function Notification(props) {
  // eslint-disable-next-line
  const { translate, notification } = props;

  return (
    <div className="notification position-relative d-flex">
      <div className="content">
        <div className="title">{notification.title}</div>
        <p className="text">{notification.detail}</p>
        <span className="date">{notification.created_at}</span>
      </div>
      <div className="divider position-absolute"></div>
    </div>
  );
}
