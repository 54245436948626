export const login = user => {
  return {
    type: "LOGIN",
    user
  };
};

export const logout = () => {
  return {
    type: "LOGOUT"
  };
};

export const changeLang = lang => {
  return {
    type: "CHANGE_LANG",
    lang: lang
  };
};

export const update = user => {
  return {
    type: "UPDATE",
    user
  };
};
