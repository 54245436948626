import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { route } from "../app/routes";
import { Helmet } from "react-helmet";
import API, { headers } from "../utils/API";

import "./contact_us.scss";

import contactUsEn from "../translations/en/contactUs.json";
import contactUsTr from "../translations/tr/contactUs.json";
import { withLocalize } from "react-localize-redux";
import { handleErrors, validateInput } from "../utils/helper";

const ContactUs = (props) => {
  props.addTranslationForLanguage(contactUsEn, "en");
  props.addTranslationForLanguage(contactUsTr, "tr");

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isFormOkay, setIsFormOkay] = useState(false);
  const [isFormSending, setIsFormSending] = useState(false);
  const [response, setResponse] = useState("");

  useEffect(() => {
    if (fullname !== "" && email !== "" && subject !== "" && message !== "")
      setIsFormOkay(true);
    else setIsFormOkay(false);
  }, [fullname, email, subject, message]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsFormSending(true);

    if (fullname === "" || email === "" || subject === "" || message === "")
      return false;

    const params = {
      name: fullname,
      email: email,
      subject: subject,
      message: message,
    };

    API.post("contact", params, {
      headers: { ...headers },
    })
      .then((response) => {
        setResponse(response.data.info.message);
        setFullname("");
        setEmail("");
        setSubject("");
        setMessage("");
        setIsFormSending(false);
      })
      .catch((err) => {
        handleErrors(err);
        setIsFormSending(false);
      });
  };

  const inputValidation = (e, type) => {
    if (type === "email")
      if (!validateInput("email", e.target.value)) {
        setEmail("");
      }
  };

  const lang = props.activeLanguage
    ? props.activeLanguage.code
    : props.defaultLanguage;

  return (
    <div className="contact-us-page pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app">
      <Helmet>
        <title>{props.translate("nav.contact_us")}</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <section className="page-area">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="left-side">
                    <Link
                      to={route.helpCenter.links[lang]}
                      className="help-center-link d-flex justify-content-start align-items-center"
                    >
                      <span className="icon">
                        <svg
                          width="72"
                          height="72"
                          viewBox="0 0 72 72"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M36 72C55.8823 72 72 55.8823 72 36C72 16.1177 55.8823 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72Z"
                            fill="url(#paint0_linear)"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M55.839 52.1287L53.0243 43.9373C54.3781 41.2007 55.0941 38.1504 55.0989 35.0812C55.1071 29.7959 53.0621 24.7983 49.3405 21.0093C45.6184 17.2196 40.6584 15.0865 35.3744 15.0027C32.606 14.9582 29.9197 15.4683 27.386 16.5171C24.9423 17.5287 22.7508 18.9941 20.8724 20.8724C18.994 22.7507 17.5287 24.9422 16.5171 27.3859C15.4683 29.9196 14.9588 32.6073 15.0026 35.3743C15.0863 40.6582 17.2195 45.6182 21.0092 49.3404C24.7908 53.0546 29.7756 55.099 35.0496 55.0989H35.0811C38.1504 55.0941 41.2006 54.3781 43.9373 53.0243L52.1288 55.839C52.4438 55.9472 52.7667 56 53.0861 56C53.8447 56 54.5827 55.702 55.1423 55.1422C55.9374 54.3471 56.2045 53.1924 55.839 52.1287ZM34.8521 44.0482C34.2163 44.0482 33.6073 44.6072 33.6367 45.2636C33.6661 45.9221 34.1707 46.479 34.8521 46.479C35.4878 46.479 36.0969 45.92 36.0675 45.2636C36.038 44.6051 35.5335 44.0482 34.8521 44.0482ZM28.8049 31.271C28.8049 27.9366 31.5177 25.2239 34.8521 25.2239C38.1865 25.2239 40.8992 27.9366 40.8992 31.271C40.8992 34.1892 38.8213 36.6312 36.0675 37.1953V41.0598C36.0675 41.7312 35.5233 42.2753 34.8521 42.2753C34.1809 42.2753 33.6367 41.7311 33.6367 41.0599V36.1027C33.6367 35.4315 34.1809 34.8873 34.8521 34.8873C36.846 34.8873 38.4684 33.265 38.4684 31.271C38.4684 29.2771 36.846 27.6548 34.8521 27.6548C32.8581 27.6548 31.2358 29.2771 31.2358 31.271C31.2358 31.9422 30.6916 32.4864 30.0204 32.4864C29.3492 32.4864 28.8049 31.9422 28.8049 31.271Z"
                            fill="white"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear"
                              x1="169.158"
                              y1="16.8221"
                              x2="16.6247"
                              y2="-83.9976"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#FEB984" />
                              <stop offset="1" stopColor="#E75B2B" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className="title d-flex justify-content-start align-items-center">
                        {props.translate("nav.help_center")}
                        <svg
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.4 0.800781L7 6.40078L1.4 12.0008"
                            stroke="black"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Link>
                    <dl className="address-area">
                      <dt>{props.translate("contact_us.address")}:</dt>
                      <dd className="selectable">
                        Levent Mah. Çamlık Cad. No:29 34330 Beşiktaş/İstanbul
                      </dd>
                      <dt>{props.translate("contact_us.phone")}:</dt>
                      <dd className="selectable">
                        <a href="tel:+90 (216) 951 02 65">
                          +90 (212) 951 02 65
                        </a>
                      </dd>
                      <dt>{props.translate("contact_us.email")}:</dt>
                      <dd className="selectable">
                        <a href="mailto:isletme@bucketlist.com.tr">
                          isletme@bucketlist.com.tr
                        </a>
                      </dd>
                      <dt>{props.translate("contact_us.working_hours")}:</dt>
                      <dd className="selectable d-flex flex-column">
                        <p className="mb-1">
                          {props.translate(
                            "contact_us.working_hours_weekdays",
                            {
                              times: "10:00 - 17:00",
                            }
                          )}
                        </p>
                        <p>
                          {props.translate("contact_us.working_hours_weekends")}
                        </p>
                      </dd>
                    </dl>
                    <p className="asterisk">
                      *Online sohbet, e-posta veya iletişim formu üzerinden bize
                      ulaşabilirsiniz.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="right-side">
                    <h3 className="title d-flex justify-content-start align-items-center">
                      {props.translate("contact_us.title")}
                    </h3>
                    <form action="#" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div className="form-group position-relative sliding-label">
                            <input
                              type="text"
                              name="fullname"
                              value={fullname}
                              onChange={(e) => setFullname(e.target.value)}
                              className={`form-control ${
                                fullname !== "" && `filled`
                              }`}
                              id="fullname"
                            />
                            <label
                              htmlFor="fullname"
                              className="position-absolute"
                            >
                              <span>
                                {props.translate("contact_us.fullname")}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-group position-relative sliding-label">
                            <input
                              type="email"
                              name="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className={`form-control ${
                                email !== "" && `filled`
                              }`}
                              id="email"
                              onBlur={(e) => inputValidation(e, "email")}
                            />
                            <label
                              htmlFor="email"
                              className="position-absolute"
                            >
                              <span>{props.translate("contact_us.email")}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-group position-relative sliding-label">
                            <input
                              type="text"
                              name="subject"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                              className={`form-control ${
                                subject !== "" && `filled`
                              }`}
                              id="subject"
                            />
                            <label
                              htmlFor="subject"
                              className="position-absolute"
                            >
                              <span>
                                {props.translate("contact_us.subject")}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="form-group position-relative sliding-label">
                            <textarea
                              name="message"
                              id="message"
                              onChange={(e) => setMessage(e.target.value)}
                              value={message}
                              className={`form-control resizable ${
                                message !== "" && `filled`
                              }`}
                            />
                            <label
                              htmlFor="message"
                              className="position-absolute"
                            >
                              <span>
                                {props.translate("contact_us.message")}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <button
                            className="button-primary w-100"
                            action="submit"
                            disabled={!isFormOkay || isFormSending}
                          >
                            {props.translate("contact_us.send")}
                          </button>
                        </div>
                        <div className="col">{response}</div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalize(ContactUs);
