export const create = (product) => {
  return {
    type: "CREATE",
    product,
  };
};

export const save = (product) => {
  return {
    type: "SAVE",
    product,
  };
};

export const clear = () => {
  return {
    type: "CLEAR",
  };
};
