import React from "react";
import { withLocalize } from "react-localize-redux";
import AccountCompletion from "../../AccountCompletion";

import BLCheckIcon from "../../../utils/assets/bl-check-icon.svg";

const Checks = ({ translate, lang, user }) => {
  return (
    <AccountCompletion
      pageTitle={translate(`account_completion.steps.checking`)}
      noDomTitle
      user={user}
      lang={lang}
    >
      <div className="position-relative checking-page d-flex flex-column justify-content-center align-items-center">
        <img src={BLCheckIcon} alt="Check" />
        <h3 className="checking-title text-center">
          {translate("account_completion.checking.title")}
        </h3>
        <p className="checking-text text-center">
          {translate("account_completion.checking.text_line_1")}
        </p>
        <p className="checking-text text-center">
          {translate("account_completion.checking.text_line_2")}
        </p>
      </div>
    </AccountCompletion>
  );
};

export default withLocalize(Checks);
