import React from "react";

export default function MobileNavCat(props) {
  const { data, id, catContents, isActive } = props;

  return (
    <li className="nav-menu-cat-title">
      <input
        type="checkbox"
        name={`cat-${id}`}
        id={`cat-${id}`}
        defaultChecked={isActive}
      />
      <label
        htmlFor={`cat-${id}`}
        className="d-flex justify-content-between align-items-center"
      >
        <span>{data.title}</span>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1L5 5L1 1"
            stroke="black"
            strokeWidth="1.44"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </label>
      <ul className="nav-menu-cat-content">{catContents}</ul>
    </li>
  );
}
