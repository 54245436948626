import React, { Component } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";

import Tabs from "../utils/components/Tabs";
import { externalRoute } from "../app/routes";
import { withLocalize } from "react-localize-redux";

import bookingsEN from "../translations/en/bookings.json";
import bookingsTR from "../translations/tr/bookings.json";
import API, { headers } from "../utils/API";
import { get } from "./bookingActions";
import Helmet from "react-helmet";
import { handleErrors } from "../utils/helper";

class Bookings extends Component {
  state = {
    query: "",
    tabs: {
      new: {
        id: 1,
        links: { en: "/bookings/new", tr: "/rezervasyonlar/yeni" },
        names: { en: "New", tr: "Yeni" },
      },
      onHold: {
        id: 2,
        links: { en: "/bookings/on-hold", tr: "/rezervasyonlar/beklemede" },
        names: { en: "On Hold", tr: "Beklemede" },
      },
      approved: {
        id: 3,
        links: { en: "/bookings/approved", tr: "/rezervasyonlar/onaylandi" },
        names: { en: "Approved", tr: "Onaylandı" },
      },
      done: {
        id: 4,
        links: { en: "/bookings/done", tr: "/rezervasyonlar/tamamlandi" },
        names: { en: "Done", tr: "Tamamlandı" },
      },
      other: {
        id: 5,
        links: { en: "/bookings/other", tr: "/rezervasyonlar/diger" },
        names: { en: "Other", tr: "Diğer" },
      },
    },
  };

  APIconfigs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(bookingsEN, "en");
    this.props.addTranslationForLanguage(bookingsTR, "tr");
  }

  componentDidMount() {
    this.redirectIfNull();

    API.get("bookings", this.APIconfigs)
      .then((response) => {
        const { pending, confirmed, completed, other } = response.data.summary;

        const tabs = { ...this.state.tabs };

        tabs["new"] = {
          ...tabs["new"],
          resultsCount: parseInt(response.data.summary.new),
        };
        tabs["onHold"] = { ...tabs["onHold"], resultsCount: parseInt(pending) };
        tabs["approved"] = {
          ...tabs["approved"],
          resultsCount: parseInt(confirmed),
        };
        tabs["done"] = { ...tabs["done"], resultsCount: parseInt(completed) };
        tabs["other"] = { ...tabs["other"], resultsCount: parseInt(other) };

        this.props.get(tabs);

        this.setState({ tabs });
      })
      .catch((err) => handleErrors(err));
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.props.location.pathname === this.props.match.path
    )
      this.redirectIfNull();
  };

  redirectIfNull = () => {
    const link = matchPath(window.location.pathname, {
      path: externalRoute["bookingTypes"].links[this.props.activeLanguage.code],
    });

    !link &&
      this.props.history.push(
        this.state.tabs["new"].links[this.props.activeLanguage.code]
      );
  };

  handleChange = (e) => {
    const { value } = e.target;

    this.setState({
      query: value,
    });
  };

  render() {
    return (
      <div className="pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app bookingsFix">
        <Helmet>
          <title>{this.props.translate("nav.bookings")}</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <section className="page-area">
                <Tabs
                  tabs={this.props.bookingTabs}
                  routePath={
                    externalRoute["bookingTypes"].links[
                      this.props.activeLanguage.code
                    ]
                  }
                  property={externalRoute["bookingTypes"].property}
                  lang={this.props.activeLanguage.code}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    bookingTabs: state.booking.tabs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get: (bookings) => dispatch(get(bookings)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Bookings));
