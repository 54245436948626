import React from "react";
import { Helmet } from "react-helmet";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import ProfileTemplate from "./ProfileTemplate";

import styles from "./Profile.module.scss";
import {
  approvalProgressCalculator,
  handleErrors,
  makeURL,
} from "../utils/helper";
import { Link } from "react-router-dom";
import { loggedRoute } from "../app/routes";
import API, { headers } from "../utils/API";
import Tooltip from "../utils/components/Tooltip";
import { update } from "../auth/authActions";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";

const CompleteAccount = (props) => {
  const sendForApproval = () => {
    if (approvalProgress.percentage === 100) {
      API.post(
        "/account/send-to-review",
        {},
        {
          headers: {
            ...headers,
            Authorization: `${props.user._tokenType} ${props.user._token}`,
          },
        }
      )
        .then((r) => {
          const user = props.user;
          user["approvalStatus"] = 7;
          props.update(user);

          toastr.success(r.data.info.message);
        })
        .catch((err) => handleErrors(err));
    }
  };

  const checkConfirm = () => {
    const message = props.translate("complete_account.confirmation");
    const options = {
      message,
      buttons: [
        {
          label: props.translate("globalTranslations.no"),
          onClick: () => false,
        },
        {
          label: props.translate("globalTranslations.yes"),
          onClick: sendForApproval,
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  const approvalProgress = !props.user.approvedAccount
    ? approvalProgressCalculator(props.user.approvalProgress)
    : {};

  const checklist = {
    bank_info: {
      title: props.translate("complete_account.bank_info.title"),
      desc: props.translate("complete_account.bank_info.desc"),
      link: loggedRoute.profilePage.links[props.activeLanguage.code].replace(
        ":page",
        makeURL(props.translate("profile.company_bank_info"))
      ),
      isCompleted: props.user.approvalProgress.bank_info,
    },
    billing_info: {
      title: props.translate("complete_account.billing_info.title"),
      desc: props.translate("complete_account.billing_info.desc"),
      link: loggedRoute.profilePage.links[props.activeLanguage.code].replace(
        ":page",
        makeURL(props.translate("profile.company_billing_info"))
      ),
      isCompleted: props.user.approvalProgress.billing_info,
    },
    iban_document: {
      title: props.translate("complete_account.iban_document.title"),
      desc: props.translate("complete_account.iban_document.desc"),
      link: loggedRoute.documents.links[props.activeLanguage.code],
      isCompleted: props.user.approvalProgress.iban_document,
    },
    tax_board: {
      title: props.translate("complete_account.tax_board.title"),
      desc: props.translate("complete_account.tax_board.desc"),
      link: loggedRoute.documents.links[props.activeLanguage.code],
      isCompleted: props.user.approvalProgress.tax_board,
    },
    is_contract_approved: {
      title: props.translate("complete_account.is_contract_approved.title"),
      desc: props.translate("complete_account.is_contract_approved.desc"),
      link: loggedRoute.contractPage.links[props.activeLanguage.code],
      isCompleted: props.user.approvalProgress.is_contract_approved,
      isDisabled: approvalProgress.listCount - 1 > approvalProgress.done,
    },
  };

  return (
    <>
      <ProfileTemplate>
        <Helmet>
          <title>{props.translate("complete_account.page_title")}</title>
        </Helmet>
        <div className={`${styles.pageContent} ${styles.completeAccount}`}>
          <div className="row mb-2">
            <div className="col">
              <h3 className={styles.pageTitle}>
                {props.translate("complete_account.page_title")}
              </h3>
              <h5 className={styles.pageDesc}>
                {props.translate("complete_account.page_desc")}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div
                className={styles.progressHolder}
                data-progress={approvalProgress.percentage}
              ></div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className={styles.progressList}>
                {Object.keys(props.user.approvalProgress).map((key) => (
                  <div
                    key={key}
                    className={styles.singleProgress}
                    data-is-disabled={checklist[key].isDisabled}
                  >
                    {checklist[key].isDisabled ? (
                      <Tooltip
                        type="disabled"
                        classes={`${styles.disabledTooltip} position-absolute text-center`}
                      >
                        {props.translate(
                          "complete_account.is_contract_approved.disabled_text"
                        )}
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    <div className="d-flex align-items-center justify-content-start">
                      <div
                        className={styles.checkmark}
                        data-is-completed={props.user.approvalProgress[key]}
                      >
                        <svg
                          width="58"
                          height="58"
                          viewBox="0 0 58 58"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="29"
                            cy="29"
                            r="29"
                            fill={`url(#paint0_linear_${key})`}
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M36.8423 17.0389L25.7017 36.3933L20.2127 28.755L9.44316 29.158C12.6968 32.6001 14.8882 34.6342 18.2025 38.0208C20.6945 40.5699 22.6699 44.054 26.8344 42.6947C30.1951 41.5977 42.9754 26.1227 47.039 21.6251L36.8423 17.0389Z"
                            fill="white"
                          />
                          <defs>
                            <linearGradient
                              id={`paint0_linear_${key}`}
                              x1="-41.4192"
                              y1="37.2938"
                              x2="33.1683"
                              y2="108.441"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#80D88C" />
                              <stop offset="0.260817" stopColor="#6DD498" />
                              <stop offset="1" stopColor="#38CABB" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <div className={styles.infoArea}>
                        <div className={styles.title}>
                          {checklist[key].title}
                        </div>
                        <div className={styles.desc}>{checklist[key].desc}</div>
                      </div>
                    </div>
                    <Link
                      to={
                        typeof checklist[key].link !== "function"
                          ? checklist[key].link
                          : "#!"
                      }
                      className="stretched-link"
                      onClick={
                        typeof checklist[key].link === "function"
                          ? (e) => {
                              e.preventDefault();
                              checklist[key].link();
                            }
                          : () => {}
                      }
                    >
                      <svg
                        width="33"
                        height="33"
                        viewBox="0 0 33 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.81248 3.22913L23.375 16.1458L9.81248 29.0625"
                          stroke="#B6B6B6"
                          strokeWidth="2.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {approvalProgress.percentage === 100 ? (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <button
                  className={`button-secondary mx-auto w-100 mt-5 ${styles.sendForApprovalButton}`}
                  onClick={checkConfirm}
                  disabled={props.user.approvalStatus === 7}
                >
                  {props.translate(
                    `complete_account.${
                      props.user.approvalStatus === 7
                        ? `inspecting`
                        : `send_for_inspection`
                    }`
                  )}
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </ProfileTemplate>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (user) => dispatch(update(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(CompleteAccount));
