const initState = {
  tabs: {},
  searchResults: false
};

const bookingReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET":
      return { ...state, tabs: { ...action.tabs } };
    case "UPDATE_COUNT":
      state.tabs[action.oldStatus].resultsCount =
        parseInt(state.tabs[action.oldStatus].resultsCount) - 1;
      state.tabs[action.newStatus].resultsCount =
        parseInt(state.tabs[action.newStatus].resultsCount) + 1;
      return state;
    case "SEARCH":
      state.searchResults = action.bookings;
      return state;
    default:
      return state;
  }
};

export default bookingReducer;
