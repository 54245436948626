import React, { Component } from "react";

import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Axios from "axios";
import { CSSTransition } from "react-transition-group";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import SingleBooking from "./SingleBooking";
import API, { headers } from "../utils/API";
import Pagination from "../utils/components/Pagination";
import Modal from "../utils/components/Modal";
import BookingDetails from "./BookingDetails";
import OnHoldModal from "./components/OnHoldModal";
import { updateCount } from "./bookingActions";
import NoResult from "./components/NoResult";

class BookingTypeTab extends Component {
  state = {
    bookings: [],
    isLoading: true,
    page: 1,
    pageCount: 1,
    limit: 15,
    selectedBooking: 0,
    booking: {},
    isBookingDetailLoading: true,
    bookingOnHoldSuccessfulModal: false,
    bookingCompleteSuccessfulModal: false,
  };

  source = Axios.CancelToken.source();

  APIconfigs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
    cancelToken: this.source.token,
  };

  status_id = { 3: "new", 8: "onHold", 4: "approved", 6: "done", 7: "other" };

  componentDidMount() {
    this.getBookings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.type !== this.state.type ||
      prevState.page !== this.state.page
    ) {
      this.setState({ isLoading: true });
      this.getBookings();
    }
  }

  componentWillUnmount() {
    this.source.cancel("Operation canceled by the user.");
  }

  getBookings = async () => {
    const typeInText = this.props.match.params.type;
    const type =
      typeInText === "yeni" || typeInText === "new"
        ? 3
        : typeInText === "beklemede" || typeInText === "on-hold"
        ? 8
        : typeInText === "onaylandi" || typeInText === "approved"
        ? 4
        : typeInText === "tamamlandi" || typeInText === "done"
        ? 6
        : typeInText === "diger" || typeInText === "other"
        ? 7
        : 3;

    this.setState((prevState) => {
      return prevState.type !== typeInText && { type: typeInText, page: 1 };
    });

    if (
      !Object.keys(this.props.bookingTabs).length ||
      this.props.bookingTabs[this.status_id[type]].resultsCount > 0
    ) {
      await API.get(
        `bookings/?status=${type}&limit=${this.state.limit}&page=${this.state.page}`,
        this.APIconfigs
      )
        .then((response) => {
          const { data, meta } = response.data;

          const pageCount = meta.last_page;

          this.setState({ bookings: data, pageCount });
        })
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            console.info("Request canceled:", error.message);
          } else {
            console.warn(error);
            this.setState({ isLoading: false });
          }
        });
    } else {
      this.setState({ isLoading: false, bookings: {}, pageCount: 1 });
    }
  };

  handlePageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { page, action } = e.target.dataset;

    let newPage;
    if (page) newPage = page;
    else if (action)
      newPage = action === "next" ? this.state.page + 1 : this.state.page - 1;
    else if (e.target.nodeName === "svg") {
      const linkAction = e.target.parentElement.dataset.action;
      newPage =
        linkAction === "next" ? this.state.page + 1 : this.state.page - 1;
    } else if (e.target.nodeName === "path") {
      const linkAction = e.target.parentElement.parentElement.dataset.action;
      newPage =
        linkAction === "next" ? this.state.page + 1 : this.state.page - 1;
    }

    if (newPage) this.setState({ page: parseInt(newPage) });
  };

  handleModalToggle = async (e) => {
    e.preventDefault();

    const { dataset } = e.target;

    const selectedBooking = dataset.id ? parseInt(dataset.id) : 0;

    await this.setState({ selectedBooking });

    if (this.state.selectedBooking !== 0) {
      await API.get(`bookings/${this.state.selectedBooking}`, this.APIconfigs)
        .then(async (response) => {
          const { data } = response.data;

          toastr.clear();

          await this.setState({ booking: data, isBookingDetailLoading: false });
        })
        .catch((error) => {
          console.log(error);
          // const { info } = error.response.data;
          // toastr.clear();
          // toastr.error(info.message);
          // this.setState({ selectedBooking: 0 });
        });
    } else {
      setTimeout(() => this.setState({ isBookingDetailLoading: true }), 350);
    }
  };

  handleRejectBooking = (booking, reason, additional = null, status) => {
    const bookings = this.state.bookings.filter((data) => {
      return data.id !== booking;
    });

    const params = {
      code: reason,
      description: additional,
    };

    API.post(`bookings/${booking}/actions/reject`, params, this.APIconfigs)
      .then((response) => {
        const { info } = response.data;

        this.setState({
          bookings,
          selectedBooking: 0,
          isBookingDetailLoading: true,
        });

        this.props.updateCount(this.status_id[status], this.status_id[7]);

        toastr.success(info.message);
      })
      .catch((error) => {
        if (error.response) {
          toastr.error(error.response.data.info.message);

          if (error.response.data.errors) {
            toastr.error(
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ]
            );
          }
        } else {
          console.log(error);
        }
      });
  };

  handleConfirmBooking = (booking, status) => {
    const bookings = this.state.bookings.filter((data) => {
      return data.id !== booking;
    });

    API.post(`bookings/${booking}/actions/confirm`, {}, this.APIconfigs)
      .then((response) => {
        const { info } = response.data;

        this.setState({
          bookings,
          selectedBooking: 0,
          isBookingDetailLoading: true,
        });

        this.props.updateCount(this.status_id[status], this.status_id[4]);

        toastr.success(info.message);
      })
      .catch((error) => {
        if (error.response) {
          toastr.error(error.response.data.info.message);

          if (error.response.data.errors) {
            toastr.error(
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ]
            );
          }
        } else {
          console.log(error);
        }
      });
  };

  handleHoldBooking = (booking, status) => {
    const bookings = this.state.bookings.filter((data) => {
      return data.id !== booking;
    });

    API.post(`bookings/${booking}/actions/wait`, {}, this.APIconfigs)
      .then((response) => {
        const { info } = response.data;

        this.setState({
          bookings,
        });

        this.props.updateCount(this.status_id[status], this.status_id[8]);

        toastr.success(info.message);
      })
      .catch((error) => {
        if (error.response) {
          toastr.error(error.response.data.info.message);

          if (error.response.data.errors) {
            toastr.error(
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ]
            );
          }
        } else {
          console.log(error);
        }
      });
  };

  handleCloseOnHoldSuccessModal = (e) => {
    e.preventDefault();

    this.setState({
      bookingOnHoldSuccessfulModal: false,
    });

    this.handleCloseDetail();
  };

  handleCloseDetail = () => {
    this.setState({
      selectedBooking: 0,
      isBookingDetailLoading: true,
    });
  };

  handleCompleteBooking = (booking, confirm_code, status) => {
    const bookings = this.state.bookings.filter((data) => {
      return data.id !== booking;
    });

    const params = {
      confirm_code,
    };

    API.post(`bookings/${booking}/actions/complete`, params, this.APIconfigs)
      .then((response) => {
        const { info } = response.data;

        this.setState({
          bookings,
        });

        this.props.updateCount(this.status_id[status], this.status_id[6]);

        this.handleCloseDetail();

        toastr.success(info.message);
      })
      .catch((error) => {
        if (error.response) {
          toastr.error(error.response.data.info.message);

          if (error.response.data.errors && error.response.data.errors.length) {
            toastr.error(
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ]
            );
          }
        } else {
          console.log(error);
        }
      });
  };

  handleConfirmAction = (type, params) => {
    let message = "";
    let func;

    if (type === "reject") {
      message = this.props.translate("bookings.reject_confirmation");
      func = () =>
        this.handleRejectBooking(
          params.id,
          params.reason,
          params.desc,
          params.status_id
        );
    } else if (type === "onHold") {
      message = this.props.translate("bookings.on_hold_confirmation");
      func = () => this.handleHoldBooking(params.id, params.status_id);
    } else if (type === "confirm") {
      message = this.props.translate("bookings.confirm_confirmation");
      func = () => this.handleConfirmBooking(params.id, params.status_id);
    } else if (type === "complete") {
      message = this.props.translate("globalTranslations.confirming_message");
      func = () =>
        this.handleCompleteBooking(params.id, params.code, params.status_id);
    }

    const options = {
      message,
      buttons: [
        {
          label: this.props.translate("globalTranslations.no"),
          onClick: () => false,
        },
        {
          label: this.props.translate("globalTranslations.yes"),
          onClick: func,
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  render() {
    const { translate } = this.props;

    const bookings = this.state.isLoading ? (
      <p className="px-4 p-md-0">
        {translate("globalTranslations.loading")}
        ...
      </p>
    ) : this.state.bookings.length ? (
      this.state.bookings.map((booking) => {
        return (
          <SingleBooking
            key={booking.id}
            booking={booking}
            handleModalToggle={this.handleModalToggle}
            translate={translate}
          />
        );
      })
    ) : (
      <p className="px-4 p-md-0">{translate("bookings.no_record")}</p>
    );

    const bookingDetail = this.state.isBookingDetailLoading ? (
      <p>
        {translate("globalTranslations.loading")}
        ...
      </p>
    ) : (
      <BookingDetails
        booking={this.state.booking}
        handleCloseDetail={this.handleCloseDetail}
        handleCompleteBooking={this.handleCompleteBooking}
        user={this.props.user}
        handleConfirmAction={this.handleConfirmAction}
      />
    );

    const searchResults = this.state.isLoading ? (
      <p className="px-4 p-md-0">
        {translate("globalTranslations.loading")}
        ...
      </p>
    ) : this.props.searchResults ? (
      this.props.searchResults.length ? (
        this.props.searchResults.map((booking) => {
          return (
            <SingleBooking
              key={booking.id}
              booking={booking}
              handleModalToggle={this.handleModalToggle}
              translate={translate}
            />
          );
        })
      ) : (
        <NoResult translate={translate} />
      )
    ) : (
      ""
    );

    return (
      <div className="row" id="booking-results-area">
        <CSSTransition
          in={Boolean(this.props.searchResults)}
          timeout={0}
          unmountOnExit
        >
          <div className="col search-results">{searchResults}</div>
        </CSSTransition>
        <div className="col bookings-area">{bookings}</div>
        <Pagination
          page={this.state.page}
          pageCount={this.state.pageCount}
          handlePageChange={this.handlePageChange}
        />
        <CSSTransition
          in={Boolean(this.state.selectedBooking)}
          timeout={0}
          unmountOnExit
        >
          <Modal
            handleToggle={this.handleModalToggle}
            id="booking-detail"
            classes="booking-detail-modal"
          >
            {bookingDetail}
          </Modal>
        </CSSTransition>

        <CSSTransition
          in={this.state.bookingOnHoldSuccessfulModal}
          timeout={300}
          unmountOnExit
          classNames="modal"
        >
          <OnHoldModal
            translate={this.props.translate}
            handleCloseOnHoldSuccessModal={this.handleCloseOnHoldSuccessModal}
          />
        </CSSTransition>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    bookingTabs: state.booking.tabs,
    searchResults: state.booking.searchResults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCount: (oldStatus, newStatus) =>
      dispatch(updateCount(oldStatus, newStatus)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(BookingTypeTab));
