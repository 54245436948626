import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import Axios from "axios";
import { CSSTransition } from "react-transition-group";
import API, { headers } from "../utils/API";

import SingleFinance from "./components/SingleFinance";

import styles from "./Finance.module.scss";

import financeTR from "../translations/tr/finance.json";
import financeEN from "../translations/en/finance.json";
import Helmet from "react-helmet";
import { handleErrors } from "../utils/helper";

class Finance extends Component {
  state = {
    paid: [],
    paidTotal: 0,
    commission: [],
    comissionTotal: 0,
    isPaidLoading: true,
    isCommisionLoading: true,
    showPaidRest: false,
    showCommissionRest: false,
  };

  paidLimit = 3;
  commissionLimit = 3;

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(financeEN, "en");
    this.props.addTranslationForLanguage(financeTR, "tr");
  }

  configs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
  };

  componentDidMount() {
    const getPaid = API.get("finance/1", this.configs);
    const getCommission = API.get("finance/2", this.configs);

    Axios.all([getPaid, getCommission])
      .then(
        Axios.spread((...response) => {
          const paid = response[0];
          const commission = response[1];

          this.setState({
            paid: paid.data.data,
            paidTotal: paid.data.total,
            isPaidLoading: false,
            commission: commission.data.data,
            commissionTotal: commission.data.total,
            isCommisionLoading: false,
          });
        })
      )
      .catch((error) => handleErrors(error));
  }

  handleExpandRest = (e, type) => {
    if (e) e.preventDefault();

    this.setState((prevState) => {
      return { [type]: !prevState[type] };
    });
  };

  render() {
    const { translate } = this.props;

    const mutualProps = {
      translate,
      styles,
      lang: this.props.activeLanguage
        ? this.props.activeLanguage.code
        : this.props.defaultLanguage,
    };

    const paids = this.state.isPaidLoading
      ? this.props.translate("globalTranslations.loading")
      : this.state.paid && this.state.paid.length
      ? this.state.paid.map((data, index) => {
          if (index + 1 > this.paidLimit) return false;
          return (
            <SingleFinance
              key={`paid-${index}`}
              data={data}
              type={1}
              {...mutualProps}
            />
          );
        })
      : translate("finance.no_payment_record_found");

    let paidRest;
    if (this.state.paid.length > this.paidLimit) {
      paidRest = this.state.paid.map((data, index) => {
        if (index < this.paidLimit) return false;
        return (
          <SingleFinance
            key={`paid-rest-${index}`}
            data={data}
            type={1}
            {...mutualProps}
          />
        );
      });
    }

    const commissions = this.state.isCommisionLoading
      ? this.props.translate("globalTranslations.loading")
      : this.state.commission && this.state.commission.length
      ? this.state.commission.map((data, index) => {
          if (index + 1 > this.commissionLimit) return false;
          return (
            <SingleFinance
              key={`comission-${index}`}
              data={data}
              type={2}
              {...mutualProps}
            />
          );
        })
      : translate("finance.no_commission_record_found");

    let commissionRest;
    if (this.state.commission.length > this.commissionLimit) {
      commissionRest = this.state.commission.map((data, index) => {
        if (index < this.commissionLimit) return false;
        return (
          <SingleFinance
            key={`comission-rest-${index}`}
            data={data}
            type={2}
            {...mutualProps}
          />
        );
      });
    }
    return (
      <div className="pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app">
        <Helmet>
          <title>{translate("nav.finance")}</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xl-6">
              <section
                className={`page-area ${styles.financeFix} ${styles.paymentsDonePageHolder}`}
              >
                <div className={styles.Finance}>
                  <section className={styles.financeType}>
                    <h4 className={styles.title}>
                      {translate("finance.payments_done")}{" "}
                      {this.state.paidTotal > this.paidLimit && (
                        <a
                          href="#!"
                          className={styles.showCollapseButton}
                          onClick={(e) =>
                            this.handleExpandRest(e, "showPaidRest")
                          }
                        >
                          {this.state.showPaidRest
                            ? translate("globalTranslations.shorten")
                            : translate("globalTranslations.expand")}
                        </a>
                      )}
                    </h4>
                    <div className={styles.financeContentHolder}>
                      {paids}
                      <CSSTransition
                        in={this.state.showPaidRest}
                        timeout={300}
                        unmountOnExit
                        classNames="finance-rest"
                      >
                        <div className="overflow-hidden">{paidRest}</div>
                      </CSSTransition>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <div className="col-12 col-xl-6">
              <section
                className={`page-area ${styles.financeFix} ${styles.commissionPageHolder}`}
              >
                <div className={styles.Finance}>
                  <section className={styles.financeType}>
                    <h4 className={styles.title}>
                      {translate("finance.commission")}{" "}
                      {this.state.commissionTotal > this.commissionLimit && (
                        <a
                          href="#!"
                          className={styles.showCollapseButton}
                          onClick={(e) =>
                            this.handleExpandRest(e, "showCommissionRest")
                          }
                        >
                          {this.state.showCommissionRest
                            ? translate("globalTranslations.shorten")
                            : translate("globalTranslations.expand")}
                        </a>
                      )}
                    </h4>
                    <div className={styles.financeContentHolder}>
                      {commissions}
                      <CSSTransition
                        in={this.state.showCommissionRest}
                        timeout={300}
                        unmountOnExit
                        classNames="finance-rest"
                      >
                        <div className="overflow-hidden">{commissionRest}</div>
                      </CSSTransition>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(Finance));
