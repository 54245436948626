import React from "react";
import { NavLink } from "react-router-dom";
import { route } from "../../app/routes";

export default function MobileNavCatItem(props) {
  const { data, lang } = props;

  const link = route.helpDetail.links[lang]
    .replace(":cat", props.cat)
    .replace(":name", data.slug);

  return (
    <li key={data.id} className="nav-menu-item">
      <NavLink to={link} className="nav-menu-link" activeClassName="active">
        {data.title}
      </NavLink>
    </li>
  );
}
