import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import moment from "moment";
import "moment/locale/tr";
import { CSSTransition } from "react-transition-group";
import RejectPopup from "./components/RejectPopup";
import CompletePopup from "./components/CompletePopup";
import { getWidth, handleErrors } from "../utils/helper";
import API, { headers } from "../utils/API";
import toastr from "toastr";
import Tooltip from "../utils/components/Tooltip";

class BookingDetails extends Component {
  state = {
    cancelModal: false,
    approveModal: false,
    onHoldSuccessful: false,
    completeModal: false,
    reason: "",
    description: "",
    date: 1,
    note: "",
    confirmationCode: "",
    positions: {
      cancel: {
        top: 0,
        left: 0,
        width: 0,
      },
      approve: {
        top: 0,
        left: 0,
        width: 0,
      },
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.cancelModal !== this.state.cancelModal &&
      this.state.cancelModal === true &&
      getWidth() > 766
    ) {
      const cancelButton = document.querySelector(".cancel-button");
      const { top, left, height, width } = cancelButton.getClientRects()[0];
      const { cancel } = this.state.positions;

      if (cancel.top !== top + height + 5 || cancel.left !== left) {
        const positions = { ...this.state.positions };
        positions["cancel"] = {
          top: top + height + 5,
          left,
          width,
        };

        this.setState({ positions });
      }
    }

    if (
      (getWidth() > 766 &&
        prevState.approveModal !== this.state.approveModal &&
        this.state.approveModal === true) ||
      (getWidth() > 766 &&
        prevState.completeModal !== this.state.completeModal &&
        this.state.completeModal === true)
    ) {
      const approveButton = document.querySelector(".approve-button");
      const { top, left, height, width } = approveButton.getClientRects()[0];
      const { approve } = this.state.positions;

      if (approve.top !== top + height + 5 || approve.left !== left) {
        const positions = { ...this.state.positions };
        positions["approve"] = {
          top: top + height + 5,
          left,
          width,
        };

        this.setState({ positions });
      }
    }
  }

  handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "radio") this.setState({ [name]: parseInt(value) });
    else this.setState({ [name]: value });
  };

  handleCloseAllPopups = () => {
    this.setState({
      cancelModal: false,
      approveModal: false,
      onHoldSuccessful: false,
      completeModal: false,
    });
  };

  handleCloseOnEsc = (e, id) => {
    if (e.keyCode === 27) {
      this.handleCloseAllPopups();
      window.removeEventListener("keydown", this.handleCloseOnEsc);
    }
  };

  handleShowControlPopup = (e, id) => {
    if (e) e.preventDefault();

    this.handleCloseAllPopups();
    this.setState({ [id]: true });
    window.addEventListener("keydown", this.handleCloseOnEsc);
  };

  handleRequestHelp = (e) => {
    if (e) e.preventDefault();

    toastr.clear();

    API.post(
      `bookings/${this.props.booking.id}/request-help`,
      {},
      {
        headers: {
          ...headers,
          Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
        },
      }
    )
      .then((response) => {
        const { info } = response.data;

        toastr.success(info.message);
      })
      .catch((err) => handleErrors(err));
  };

  render() {
    const { translate, booking } = this.props;

    const p_date =
      booking.preferred_date &&
      moment(booking.preferred_date, "DD/MM/YYYY HH:mm:ss").format(
        "DD MMMM YYYY, HH:mm"
      );

    const preferred_date =
      booking.other_date ||
      (booking.date_preference === 2 &&
        booking.status_id !== 3 &&
        booking.status_id !== 8 &&
        booking.status_id !== 9) ? (
        <s>{p_date}</s>
      ) : (
        p_date
      );

    const b_date = moment(booking.date_updated, "DD/MM/YYYY HH:mm:ss").format(
      "DD MMMM YYYY, HH:mm:ss"
    );

    const reasons = {
      1: { title: "Tarihler mevcut değil" },
      2: { title: "Deneyim artık mevcut değil" },
      3: { title: "Müşteri fikrini değiştirdi" },
      4: { title: "Diğer" },
    };

    const popupsProps = {
      positions: this.state.positions,
      translate: translate,
      bookingID: booking.id,
      bookingStatus: booking.status_id,
      handleChange: this.handleChange,
      handleCloseAllPopups: this.handleCloseAllPopups,
    };

    return (
      <div className="booking-detail">
        <div className="head-area d-flex justify-content-between align-items-center">
          <div className="info-area mr-md-5 d-flex justify-content-between align-items-center">
            <div className="user-avatar mr-2 d-flex justify-content-center align-items-center rounded-circle overflow-hidden">
              <img
                src={booking.user.avatar_url}
                alt={`${booking.user.name} Avatar`}
                className="h-100 rounded-circle"
              />
            </div>
            <div className="info">
              <h3 className="user mb-0">{booking.user.name}</h3>
              <span className="other-info">
                {booking.status} | {translate("bookings.transaction_date")}:{" "}
                {b_date}
              </span>
            </div>
          </div>
          {booking.status_id !== 9 && booking.status_id !== 6 && (
            <div className="settings-area d-none d-md-flex flex-row-reverse">
              {booking.status_id !== 4 && (
                <button
                  className="button-success setting-button ml-3 approve-button"
                  onClick={(e) =>
                    this.props.handleConfirmAction("confirm", {
                      id: booking.id,
                      status_id: booking.status_id,
                    })
                  }
                >
                  {translate("bookings.approve")}
                </button>
              )}
              {booking.status_id === 4 && (
                <>
                  <button
                    className="button-success setting-button ml-3 approve-button"
                    onClick={(e) => {
                      this.handleShowControlPopup(e, "completeModal");
                    }}
                  >
                    {translate("bookings.complete")}
                  </button>
                </>
              )}
              {booking.status_id !== 8 && booking.status_id !== 4 && (
                <button
                  className="button-secondary opposite setting-button ml-3"
                  onClick={(e) => {
                    this.props.handleConfirmAction("onHold", {
                      id: booking.id,
                      status_id: booking.status_id,
                    });
                  }}
                >
                  {translate("bookings.on_hold")}
                </button>
              )}

              {(booking.status_id === 3 || booking.status_id === 8) && (
                <button
                  className="button-transparent cancel-button"
                  onClick={(e) => this.handleShowControlPopup(e, "cancelModal")}
                >
                  {translate("bookings.reject")}
                </button>
              )}
            </div>
          )}
        </div>

        <div className="content-area">
          <section className="booking-info-section mb-4 w-100">
            <h6 className="section-title w-100 mb-4 not-selectable">
              {translate("bookings.detailed_info")}
            </h6>
            <div className="details-list w-100">
              <div
                className={`detail-content mb-2 w-100 d-flex align-items-center justify-content-between `}
              >
                <div className="title">
                  {translate("bookings.preferred_date")}:
                </div>
                <div className="content selectable">{preferred_date}</div>
              </div>
            </div>
          </section>
          <section className="booking-info-section mb-4 mt-3 w-100">
            <h6 className="section-title w-100 mb-4 not-selectable d-flex">
              {translate("bookings.participants")}
              <Tooltip type="info" direction="top" classes="ml-2 text-center">
                {translate("bookings.participants_tooltip")}
              </Tooltip>
            </h6>
            <div className="participants-area w-100 d-flex align-items-center justify-content-between">
              <div className="participant w-100 d-flex justify-content-start align-items-center">
                <div className="avatar mr-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                  <img
                    src={booking.participant.avatar_url}
                    alt="User Name Avatar"
                    className="rounded-circle w-100"
                  />
                </div>
                <div className="user-info d-flex flex-column">
                  <h3 className="name mb-2">{`${booking.participant.first_name} ${booking.participant.last_name}`}</h3>
                  <div className="infos d-flex flex-column w-100">
                    <div className="info mb-1 d-flex justify-content-start align-items-center">
                      <div className="title">
                        {translate("bookings.phone")}:
                      </div>
                      <span className="content selectable ml-2">
                        {booking.participant.phone}
                      </span>
                    </div>
                    <div className="info d-flex justify-content-start align-items-center">
                      <div className="title">
                        {translate("bookings.email_address")}:
                      </div>
                      <span className="content selectable ml-2">
                        {booking.participant.email}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="booking-info-section mb-4 mt-3 w-100">
            <h6 className="section-title w-100 mb-4 not-selectable">
              {translate("bookings.coupon")}
            </h6>
            <div className="coupon-area w-100 d-flex align-items-center justify-content-center">
              <div className="content selectable">{booking.booking_code}</div>
            </div>
          </section>

          <section className="booking-info-section mb-4 mt-3 w-100">
            <h6 className="section-title w-100 mb-4 not-selectable">
              {translate("bookings.product_details")}
            </h6>
            <div className="exp-info-area d-flex flex-column justify-content-start align-items-start">
              <div className="product-info">
                <h3 className="title">{booking.product.name}</h3>
                <span className="code selectable">
                  {translate("bookings.product_code")}: {booking.product.code}
                </span>
              </div>
              <div className="unit-info d-flex flex-column align-items-start mt-2">
                {booking.units.map((data, index) => (
                  <div className="unit d-flex align-items-center" key={index}>
                    <div className="unit-name mr-2">{data.name}</div>
                    <div className="unit-count ml-2">x{data.quantity}</div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {booking.status_id !== 9 && booking.status_id !== 6 && (
            <section className="settings-area d-flex d-md-none flex-column-reverse">
              {booking.status_id !== 4 && (
                <button
                  className="button-success setting-button approve-button"
                  onClick={(e) =>
                    this.props.handleConfirmAction("confirm", {
                      id: booking.id,
                      status_id: booking.status_id,
                    })
                  }
                >
                  {translate("bookings.approve")}
                </button>
              )}
              {booking.status_id === 4 && (
                <button
                  className="button-success setting-button approve-button"
                  onClick={(e) => {
                    this.handleShowControlPopup(e, "completeModal");
                  }}
                >
                  {translate("bookings.complete")}
                </button>
              )}
              {booking.status_id !== 8 && booking.status_id !== 4 && (
                <button
                  className="button-secondary opposite setting-button"
                  onClick={(e) => {
                    this.props.handleConfirmAction("onHold", {
                      id: booking.id,
                      status_id: booking.status_id,
                    });
                  }}
                >
                  {translate("bookings.on_hold")}
                </button>
              )}

              {(booking.status_id === 3 || booking.status_id === 8) && (
                <button
                  className="button-transparent cancel-button"
                  onClick={(e) => this.handleShowControlPopup(e, "cancelModal")}
                >
                  {translate("bookings.reject")}
                </button>
              )}
            </section>
          )}
          <section className="request-help-section mb-4 mt-3 w-100 d-flex justify-content-between align-items-center">
            <p className="desc m-0 mr-5">
              {translate("bookings.request_help_desc")}
            </p>
            <button
              className="button-primary request-button"
              onClick={this.handleRequestHelp}
            >
              {translate("bookings.request_help")}
            </button>
          </section>
        </div>

        <CSSTransition
          in={this.state.cancelModal}
          timeout={300}
          classNames="control-popups"
          unmountOnExit
        >
          <RejectPopup
            {...popupsProps}
            reason={this.state.reason}
            description={this.state.description}
            reasons={reasons}
            handleConfirmAction={this.props.handleConfirmAction}
          />
        </CSSTransition>
        <CSSTransition
          in={this.state.completeModal}
          timeout={300}
          classNames="control-popups"
          unmountOnExit
        >
          <CompletePopup
            {...popupsProps}
            confirmationCode={this.state.confirmationCode}
            handleConfirmAction={this.props.handleConfirmAction}
          />
        </CSSTransition>
      </div>
    );
  }
}

export default withLocalize(BookingDetails);
