import React from "react";
import SingleStep from "./SingleStep";

const STEPS = [
  {
    id: 6,
    name: "completion",
    route: "completion",
  },
  {
    id: 7,
    name: "checking",
    route: "checking",
  },
  {
    id: 8,
    name: "agreement",
    route: "agreement",
  },
];

const Steps = ({ translate, lang, user }) => {
  return (
    <div className="steps d-flex flex-column flex-md-row justify-content-center align-items-start align-items-md-center">
      {STEPS.map((step, index) => (
        <SingleStep
          key={index}
          translate={translate}
          lang={lang}
          step={{
            ...step,
            status:
              parseInt(user.approvalStatus) > step.id
                ? 2
                : parseInt(user.approvalStatus) === step.id
                ? 1
                : 0,
          }}
        />
      )).reduce((prev, curr, index) => [
        prev,
        <div key={`d-${index}`} className="divider d-none d-md-block" />,
        curr,
      ])}
    </div>
  );
};

export default Steps;
