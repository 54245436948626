import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import NotFound from "./NotFound";
import { route, loggedRoute, guestRoute, inactiveLoggedRoute } from "./routes";
import Loader from "./components/Loader";

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

class Router extends Component {
  state = { isLoading: true };
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1250);
  };
  render() {
    const routes =
      this.props.user.isLoggedIn &&
      this.props.user._token !== null &&
      this.props.user.approvedAccount
        ? Object.values(loggedRoute).map((data) => {
            return (
              <Route
                path={data.links[this.props.lang]}
                key={data.id}
                exact={data.exact}
                render={(routeProps) => {
                  return renderMergedProps(data.property, routeProps, {
                    lang: this.props.lang,
                    user: this.props.user,
                  });
                }}
              />
            );
          })
        : this.props.user.isLoggedIn &&
          this.props.user._token !== null &&
          !this.props.user.approvedAccount
        ? Object.values(inactiveLoggedRoute).map((data) => (
            <Route
              path={data.links[this.props.lang]}
              key={data.id}
              exact={data.exact}
              render={(routeProps) => {
                return renderMergedProps(data.property, routeProps, {
                  lang: this.props.lang,
                  user: this.props.user,
                });
              }}
            />
          ))
        : Object.values(guestRoute).map((data) => {
            return (
              <Route
                path={data.links[this.props.lang]}
                key={data.id}
                exact={data.exact}
                render={(routeProps) => {
                  return renderMergedProps(data.property, routeProps, {
                    lang: this.props.lang,
                    user: this.props.user,
                  });
                }}
              />
            );
          });

    const staticRoutes = Object.values(route).map((data) => {
      return (
        <Route
          path={data.links[this.props.lang]}
          key={data.id}
          exact={true}
          render={(routeProps) => {
            return renderMergedProps(data.property, routeProps, {
              lang: this.props.lang,
              user: this.props.user,
            });
          }}
        />
      );
    });

    return this.state.isLoading ? (
      <Loader />
    ) : (
      <Switch>
        {staticRoutes}
        {routes}
        <Route
          render={() => (
            <NotFound
              isLoggedIn={
                this.props.user.isLoggedIn && this.props.user._token !== null
              }
              lang={this.props.lang}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withLocalize(Router);
