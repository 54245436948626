import React, { Component } from "react";
import "./OfflineState.scss";

import image from "./assets/no-internet-connection.png";
import logo from "./assets/logo.svg";

import Helmet from "react-helmet";

export default class OfflineState extends Component {
  constructor(props) {
    super(props);

    let lang = navigator.language || navigator.userLanguage;

    if (lang.includes("-")) lang = lang.split("-")[0];

    if (lang !== "tr" && lang !== "en") lang = "tr";

    this.state = {
      lang,
    };
  }

  componentDidMount = () => {
    document.querySelector("html").classList.add("overflow-hidden");
    document.body.classList.add("overflow-hidden");
  };

  componentWillUnmount = () => {
    document.querySelector("html").classList.remove("overflow-hidden");
    document.body.classList.remove("overflow-hidden");
  };

  render() {
    return (
      <div className="offline-page d-flex flex-column vw-100 vh-100 justify-content-center align-items-center">
        <Helmet>
          <title>{this.props.translation[this.state.lang].page_title}</title>
        </Helmet>
        <img
          src={logo}
          alt="Bucketlist Logo"
          className="logo position-absolute"
        />
        <img src={image} alt="No Internet" className="image" />
        <h4 className="title text-center">
          {this.props.translation[this.state.lang].title}
        </h4>
        <p className="content text-center">
          {this.props.translation[this.state.lang].content}
        </p>
      </div>
    );
  }
}
