import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import toastr from "toastr";
import { CSSTransition } from "react-transition-group";

import { update } from "../auth/authActions";
import ProfileTemplate from "./ProfileTemplate";
import API, { headers } from "../utils/API";
import {
  getCities,
  getDistricts,
  getBase64,
  validateInput,
  makeURL,
  handleErrors,
} from "../utils/helper";
import { loggedRoute } from "../app/routes";

import SettingsNav from "./ProfileComponents/SettingsNav";
import { default as ProfileSettings } from "./ProfileComponents/Profile";
import AuthorizedPerson from "./ProfileComponents/AuthorizedPerson";
import BillingInfo from "./ProfileComponents/BillingInfo";
import BankInfo from "./ProfileComponents/BankInfo";

import styles from "./Profile.module.scss";
import Helmet from "react-helmet";
import supplierTypes, { inputs } from "../utils/db/supplierTypes";

class Profile extends Component {
  state = {
    approvalStatus: {
      status: false,
      checklist: {},
    },
    companyName: "",
    companyPhone: "",
    services: "",
    address: "",
    city: "",
    district: "",
    companyWebsite: "",
    authorizedFirstName: "",
    authorizedLastName: "",
    authorizedEmail: "",
    authorizedPhone: "",
    company_type: "",
    company_name: "",
    address_line: "",
    country_id: "",
    city_id: "",
    state_id: "",
    zip: "",
    tax_number: "",
    tax_location: "",
    bankName: "",
    iban: "",
    selectedSetting: "",
    countries: [],
    cities: [],
    districts: [],
    contactDistricts: [],
    supplierTypes: [],
    isSending: false,
    uploadProgress: 0,
    isProgressActive: false,
  };

  constructor(props) {
    super(props);

    this.settingsNames = {
      profile: {
        id: 0,
        name: "profile.editing_profile",
      },
      authorized_person: {
        id: 1,
        name: "profile.authorized_person",
      },
      billing_info: {
        id: 2,
        name: "profile.company_billing_info",
      },
      bank_info: {
        id: 3,
        name: "profile.company_bank_info",
      },
    };
  }

  configs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.setState({ uploadProgress: percentCompleted });
    },
  };

  async componentDidMount() {
    const cities = await getCities(212);
    this.setState({ cities });

    API.get("account/types", this.configs)
      .then(({ data: res }) => {
        const { data: supplierTypes } = res;

        this.setState({ supplierTypes });
      })
      .catch((err) => handleErrors(err));

    API.get("account", this.configs)
      .then(async (response) => {
        const { data } = response.data;

        if (data.contact.city.id) {
          const contactDistricts = await getDistricts(data.contact.city.id);
          this.setState({ contactDistricts });
        }

        if (data.contact.city.id) {
          const districts = await getDistricts(data.contact.city.id);
          this.setState({ districts });
        }

        const profileInfo = {
          companyName: data.supplier_name || "",
          companyPhone: data.supplier_phone || "",
          address: data.contact.address_line || "",
          companyWebsite: data.website || "",
          authorizedFirstName: data.authorized_first_name || "",
          authorizedLastName: data.authorized_last_name || "",
          authorizedEmail: data.authorized_email || "",
          authorizedPhone: data.authorized_phone || "",
          company_type: data.contact.company_type
            ? data.contact.company_type
            : 0,
          company_name: data.contact.company_name || "",
          address_line: data.contact.address_line || "",
          country_id: data.contact.country.id || "",
          city_id: data.contact.city.id || "",
          state_id: data.contact.state.id || "",
          zip: data.contact.zip || "",
          tax_number: data.tax_number || "",
          tax_administration: data.tax_administration || "",
          bankName: data.bank_name || "",
          iban: data.bank_iban || "",
          idn: data.idn || "",
        };

        const user = {
          ...this.props.user,
          approvedAccount: data.approved_account,
          approvalProgress: data.account_checklist,
          approvalStatus: data.status_id,
        };

        this.props.update(user);

        this.setState({
          ...profileInfo,
          defaultValues: profileInfo,
        });
      })
      .catch((err) => handleErrors(err));

    this.setSetting();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.city_id !== "" && prevState.city_id !== this.state.city_id) {
      const contactDistricts = await getDistricts(this.state.city_id);
      this.setState({ state_id: "", contactDistricts });
    }

    if (prevState.city !== "" && prevState.city !== this.state.city) {
      const districts = await getDistricts(this.state.city);
      this.setState({ district: "", districts });
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setSetting();
    }
  }

  setSetting = () => {
    const page = this.props.location.pathname.split("/").pop();

    if (
      page ===
      loggedRoute.profile.links[this.props.activeLanguage.code].replace("/", "")
    )
      this.setState({ selectedSetting: null });
    else
      Object.keys(this.settingsNames).some((key) => {
        if (
          makeURL(this.props.translate(this.settingsNames[key].name)) === page
        ) {
          if (this.state.selectedSetting !== key) {
            this.setState({ selectedSetting: key });
          }
          return true;
        } else return false;
      });
  };

  handleSettingChange = (e, setting) => {
    e.preventDefault();

    const defaultValues = { ...this.state.defaultValues };

    this.setState({ selectedSetting: setting, ...defaultValues }, () => {
      if (this.state.selectedSetting) {
        this.props.history.push(
          loggedRoute.profilePage.links[this.props.activeLanguage.code].replace(
            ":page",
            makeURL(
              this.props.translate(
                this.settingsNames[this.state.selectedSetting].name
              )
            )
          )
        );
      } else
        this.props.history.push(
          loggedRoute.profile.links[this.props.activeLanguage.code]
        );
    });
  };

  handleAvatarChange = (e) => {
    const file = e.target.files[0];
    const promise = getBase64(file);

    if (file.size <= process.env.REACT_APP_AVATAR_MAX_FILE_SIZE) {
      this.setState({ isProgressActive: true });
      promise
        .then((value) => {
          const params = {
            avatar_url: value,
          };
          API.patch("account", params, this.configs).then((response) => {
            toastr.clear();
            toastr.success(response.data.info.message, "", {
              progressBar: true,
            });
            const user = {
              ...this.props.user,
              avatar: response.data.data.avatar_url,
            };

            this.props.update(user);
            this.setState({ isProgressActive: false, uploadProgress: 0 });
          });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isProgressActive: false, uploadProgress: 0 });
        });
    } else {
      toastr.clear();
      toastr.error(
        this.props.translate("profile.avatar_max_file_size", {
          size: process.env.REACT_APP_AVATAR_MAX_FILE_SIZE / 1024 / 1024,
        })
      );
      return false;
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "company_type" ||
      name === "country_id" ||
      name === "city_id" ||
      name === "state_id" ||
      name === "city" ||
      name === "district"
    )
      this.setState({
        [name]: parseInt(value),
      });
    else
      this.setState({
        [name]: value,
      });
  };

  inputValidation = (e, type) => {
    if (type === "phone")
      if (!validateInput("phone", e.target.value)) {
        this.setState({ authorizedPhone: "" });
      }

    if (type === "email")
      if (!validateInput("email", e.target.value)) {
        this.setState({ authorizedEmail: "" });
      }

    if (type === "tax_number")
      if (!validateInput("number", e.target.value)) {
        this.setState({ tax_number: "" });
      }

    if (type === "website")
      if (!validateInput("website", e.target.value)) {
        this.setState({ companyWebsite: "" });
      }

    if (type === "idn")
      if (!validateInput("idn", e.target.value)) {
        this.setState({ idn: "" });
      }
  };

  handleSubmit = (e, type) => {
    e.preventDefault();

    this.setState({ isSending: true });

    let params = {};
    let defaultInfo;
    if (type === "profile") {
      const { companyName, address, city, district, companyWebsite } =
        this.state;

      params = {
        supplier_name: companyName,
        website: companyWebsite,
      };

      defaultInfo = {
        companyName,
        address,
        city,
        district,
        companyWebsite,
      };
    } else if (type === "authorized") {
      const {
        authorizedFirstName,
        authorizedLastName,
        authorizedEmail,
        authorizedPhone,
      } = this.state;

      params = {
        authorized_first_name: this.state.authorizedFirstName,
        authorized_last_name: this.state.authorizedLastName,
        authorized_email: this.state.authorizedEmail,
        authorized_phone: this.state.authorizedPhone,
      };

      defaultInfo = {
        authorizedFirstName,
        authorizedLastName,
        authorizedEmail,
        authorizedPhone,
      };
    } else if (type === "billing" && String(this.state.company_type)) {
      const {
        company_type,
        company_name,
        address_line,
        country_id,
        city_id,
        state_id,
        zip,
        tax_number,
        tax_administration,
      } = this.state;

      const { inputs: typeInputs } = supplierTypes.find(
        (type) => type.id === company_type
      );

      params["company_type"] = company_type;

      typeInputs
        .filter((input) => inputs[input].relevancy.includes(2))
        .forEach((input) => {
          if (
            this.state[inputs[input].name] &&
            this.state[inputs[input].name] !== ""
          )
            params[inputs[input].name] = this.state[inputs[input].name];
        });

      defaultInfo = {
        company_type,
        company_name,
        address_line,
        country_id,
        city_id,
        state_id,
        zip,
        tax_number,
        tax_administration,
      };
    } else if (type === "bank") {
      const { bankName, iban } = this.state;

      params = {
        bank_name: bankName,
        bank_iban: iban,
      };

      defaultInfo = { bankName, iban };
    }

    const user = {
      ...this.props.user,
      firstName: this.state.authorizedFirstName,
      lastName: this.state.authorizedLastName,
      supplierName: this.state.companyName,
    };

    const config = {
      headers: {
        ...headers,
        Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
      },
    };

    setTimeout(() => {
      API.patch("account", params, config)
        .then((response) => {
          toastr.clear();
          toastr.success(response.data.info.message, "", {
            progressBar: true,
          });

          user["approvedAccount"] = response.data.data.approved_account;
          user["approvalProgress"] = response.data.data.account_checklist;
          user["supplierType"] = response.data.data.contact.company_type;

          this.props.update(user);

          const defaultValues = { ...this.state.defaultValues, ...defaultInfo };

          this.setState({ isSending: false, defaultValues });
        })
        .catch((error) => {
          handleErrors(error);
          this.setState({ isSending: false });
        });
    }, 50);
  };

  render() {
    const { translate } = this.props;
    const mutualProps = {
      styles: styles,
      translate: translate,
      handleSettingChange: this.handleSettingChange,
      handleChange: this.handleChange,
      handleSubmit: this.handleSubmit,
      isSending: this.state.isSending,
    };

    return (
      <ProfileTemplate
        isProgressActive={this.state.isProgressActive}
        uploadProgress={this.state.uploadProgress}
      >
        <Helmet>
          <title>{translate("profile.editing_company_profile")}</title>
        </Helmet>
        <div className={styles.pageContent}>
          <CSSTransition
            in={!Boolean(this.state.selectedSetting)}
            timeout={0}
            unmountOnExit
            classNames="profile-settings-page-nav"
          >
            <SettingsNav
              styles={styles}
              translate={translate}
              handleSettingChange={this.handleSettingChange}
            />
          </CSSTransition>
          <CSSTransition
            in={this.state.selectedSetting === "profile"}
            timeout={0}
            unmountOnExit
          >
            <ProfileSettings
              {...mutualProps}
              handleAvatarChange={this.handleAvatarChange}
              inputProps={{
                companyName: this.state.companyName,
                services: this.state.services,
                address: this.state.address,
                city: this.state.city,
                district: this.state.district,
                companyWebsite: this.state.companyWebsite,
              }}
              cities={this.state.cities}
              districts={this.state.districts}
              inputValidation={this.inputValidation}
              isProgressActive={this.state.isProgressActive}
            />
          </CSSTransition>
          <CSSTransition
            in={this.state.selectedSetting === "authorized_person"}
            timeout={0}
            unmountOnExit
          >
            <AuthorizedPerson
              {...mutualProps}
              inputProps={{
                authorizedFirstName: this.state.authorizedFirstName,
                authorizedLastName: this.state.authorizedLastName,
                authorizedEmail: this.state.authorizedEmail,
                authorizedPhone: this.state.authorizedPhone,
              }}
              cities={this.state.cities}
              districts={this.state.contactDistricts}
              inputValidation={this.inputValidation}
            />
          </CSSTransition>
          <CSSTransition
            in={this.state.selectedSetting === "billing_info"}
            timeout={0}
            unmountOnExit
          >
            <BillingInfo
              {...mutualProps}
              inputProps={{
                company_type: this.state.company_type,
                company_name: this.state.company_name,
                address_line: this.state.address_line,
                country_id: this.state.country_id,
                city_id: this.state.city_id,
                state_id: this.state.state_id,
                zip: this.state.zip,
                tax_number: this.state.tax_number,
                tax_administration: this.state.tax_administration,
                idn: this.state.idn,
              }}
              cities={this.state.cities}
              districts={this.state.contactDistricts}
              inputValidation={this.inputValidation}
              user={this.props.user}
              types={this.state.supplierTypes}
            />
          </CSSTransition>
          <CSSTransition
            in={this.state.selectedSetting === "bank_info"}
            timeout={0}
            unmountOnExit
          >
            <BankInfo
              {...mutualProps}
              inputProps={{
                bankName: this.state.bankName,
                iban: this.state.iban,
              }}
              cities={this.state.cities}
              districts={this.state.districts}
            />
          </CSSTransition>
        </div>
      </ProfileTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (user) => dispatch(update(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Profile));
