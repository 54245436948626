import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import API, { headers } from "../utils/API";

import authEN from "../translations/en/auth.json";
import authTR from "../translations/tr/auth.json";

import { guestRoute } from "../app/routes";
import ErrorArea from "../utils/components/ErrorArea";
import SuccessArea from "../utils/components/SuccessArea";
import Helmet from "react-helmet";
import { validateInput } from "../utils/helper";

class ForgotPassword extends Component {
  state = {
    email: "",
    requestResponse: "",
    error: false,
    isSending: false,
    isCompleted: false,
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(authEN, "en");
    this.props.addTranslationForLanguage(authTR, "tr");
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.email !== this.state.email)
      this.setState({ isCompleted: this.handleCheckInputs() });
  };

  handleCheckInputs = () => {
    const { email } = this.state;
    if (email.length > 0 && validateInput("email", email)) return true;

    return false;
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    await this.setState({ error: false, requestResponse: "", isSending: true });

    const configs = {
      headers: {
        ...headers,
      },
    };

    const params = {
      email: this.state.email,
    };

    API.post("password/recovery", params, configs)
      .then((response) => {
        const { message } = response.data.info;
        this.setState({
          requestResponse: message,
          error: false,
          isSending: false,
        });
      })
      .catch((errors) => {
        if (errors.response) {
          const { email } = errors.response.data.errors;

          this.setState({ requestResponse: email[0], error: true });
        }

        this.setState({ isSending: false });
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    event.target.classList.remove("error");

    this.setState({
      [name]: value,
    });
  };
  render() {
    const response =
      this.state.requestResponse !== "" ? (
        this.state.error ? (
          <ErrorArea>{this.state.requestResponse}</ErrorArea>
        ) : (
          <SuccessArea>{this.state.requestResponse}</SuccessArea>
        )
      ) : null;
    return (
      <div className="container">
        <Helmet>
          <title>{this.props.translate("forgot_password.page_title")}</title>
        </Helmet>
        <div className="row">
          <div className="col col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
            <h3 className="page-title">
              {this.props.translate("forgot_password.page_title")}
            </h3>
            <section className="page-area">
              <div className="forgotPassLink text-left mt-0 mb-4">
                <Link
                  to={guestRoute["login"].links[this.props.activeLanguage.code]}
                >
                  {`< `}
                  {this.props.translate("globalTranslations.go_back")}
                </Link>
              </div>
              <form
                onSubmit={this.handleSubmit}
                className="col d-flex flex-column"
              >
                <div className="form-group position-relative sliding-label">
                  <input
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onBlur={(e) => {
                      if (!validateInput("email", e.target.value))
                        e.target.classList.add("error");
                    }}
                    className={`form-control ${
                      this.state.email !== "" && `filled`
                    }`}
                    id="email"
                    required
                  />
                  <label htmlFor="email" className="position-absolute">
                    <span>{this.props.translate("auth.email_address")}</span>
                  </label>
                </div>

                {response}

                <button
                  className="button-primary mt-4"
                  disabled={this.state.isSending || !this.state.isCompleted}
                >
                  {this.props.translate("forgot_password.reset_my_password")}
                </button>

                <p className="applyLink mt-5 w-100 text-center">
                  {this.props.translate(
                    "login.dont_have_a_supplier_hub_account",
                    {
                      link: (
                        <Link
                          to={
                            guestRoute["register"].links[
                              this.props.activeLanguage.code
                            ]
                          }
                        >
                          {this.props.translate(
                            "globalTranslations.click_here"
                          )}
                        </Link>
                      ),
                    }
                  )}
                </p>
              </form>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(ForgotPassword);
