import React from "react";
import { Link } from "react-router-dom";
import { inactiveLoggedRoute } from "../../../../app/routes";
import API, { headers } from "../../../../utils/API";
import {
  getBase64,
  handleErrors,
  isTouchDevice,
} from "../../../../utils/helper";
import { STEPS } from "../Information";
import { update } from "../../../../auth/authActions";

import toastr from "toastr";
import { connect } from "react-redux";
import Axios from "axios";
import DocumentArea from "./DocumentArea";

const DocumentsStep = ({
  history,
  translate,
  lang,
  information,
  setInformation,
  user,
  update,
}) => {
  const [types, setTypes] = React.useState([]);
  const [requiredTypes, setRequiredTypes] = React.useState([]);
  const [isSending, setIsSending] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const [sentDocuments, setSentDocuments] = React.useState([]);
  const [otherDocuments, setOtherDocuments] = React.useState([]);
  const [isOkayToSave, setIsOkayToSave] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState({
    id: "",
    progress: "",
  });
  const [customDocument, setCostumDocument] = React.useState({
    id: 0,
    name: "",
    file: "",
    type: 0,
  });
  const [showDeleteInfoOnUpload, setShowDeleteInfoOnUpload] =
    React.useState(true);
  const [showDeleteInfo, setShowDeleteInfo] = React.useState(false);

  React.useEffect(() => {
    const cancelSource = Axios.CancelToken.source();
    API.get("docs/types", {
      headers: {
        ...headers,
        Authorization: `${user._tokenType} ${user._token}`,
      },
      cancelToken: cancelSource.token,
    })
      .then(({ data: res }) => {
        setTypes(res.data.map((data) => ({ ...data, type: data.id })));

        handleGetUploadedDocuments();
      })
      .catch((err) => handleErrors(err));

    return () => {
      cancelSource.cancel("Operation canceled by the user.");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRequiredTypes(
      types.filter((type) => information.type in type.required_in)
    );
  }, [information.type, types]);

  const handleFileSelect = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.files[0] });
    const type = e.target.name.split("_")[1];
    const documentType = requiredTypes.find(
      (req) => String(req.type) === String(type)
    );

    setSelectedDocument({
      ...documentType,
      file: e.target.files[0],
      progress: 0,
    });
  };

  const handleCustomDocumentChange = (e) => {
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    const name = e.target.type === "file" ? `file` : e.target.name;

    setCostumDocument({ ...customDocument, [name]: value });
  };

  React.useEffect(() => {
    if (customDocument.file)
      setSelectedDocument({
        ...customDocument,
        progress: 0,
      });
  }, [customDocument]);

  React.useEffect(() => {
    if (
      selectedDocument &&
      (selectedDocument?.type || selectedDocument?.type === 0)
    ) {
      setIsSending(selectedDocument.type);
      const hasSentBefore = sentDocuments.find(
        (doc) => String(doc.type) === String(selectedDocument.type)
      );

      if (hasSentBefore) {
        API.delete(`docs/${hasSentBefore.id}`, {
          headers: {
            ...headers,
            Authorization: `${user._tokenType} ${user._token}`,
          },
        })
          .then(async () => {
            await handleUploadDocument(selectedDocument.type === 0 || false);
          })
          .catch((err) => {
            handleErrors(err);
            setIsSending(false);
          });
      } else {
        setTimeout(
          async () =>
            await handleUploadDocument(selectedDocument.type === 0 || false),
          20
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument]);

  React.useEffect(() => {
    const handleCheckIfAllDocumentsAreSent = () => {
      const check = requiredTypes.every(({ type, optional }) => {
        if (!optional) {
          const hasSentBefore = sentDocuments.find(
            (doc) => String(doc.type) === String(type)
          );
          return hasSentBefore;
        } else return true;
      });

      setIsOkayToSave(check);
    };

    handleCheckIfAllDocumentsAreSent();
  }, [requiredTypes, sentDocuments]);

  const handleUploadDocument = async (isCustom = false) => {
    const file = await getBase64(selectedDocument.file);

    setTimeout(() => {
      setUploadProgress({ type: selectedDocument.type, progress: 0 });
    }, 10);

    setTimeout(() => {
      API.post(
        "docs",
        {
          type: isCustom ? 0 : selectedDocument.type,
          name: selectedDocument.name,
          cover_file: file,
        },
        {
          headers: {
            ...headers,
            Authorization: `${user._tokenType} ${user._token}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress({ type: selectedDocument.type, progress });
          },
        }
      )
        .then(() => {
          toastr.clear();
          toastr.success("Belge yüklendi.");
          handleGetUploadedDocuments(selectedDocument.name);
          setIsSending(false);
          setUploadProgress({ type: "", progress: 0 });
          setCostumDocument({
            id: 0,
            name: "",
            file: "",
            type: 0,
          });
        })
        .catch((err) => {
          handleErrors(err);
          setIsSending(false);
          setUploadProgress({ type: "", progress: 0 });
        });
    }, 40);
  };

  const handleRemoveDocument = (id) => {
    if (id) {
      API.delete(`docs/${id}`, {
        headers: {
          ...headers,
          Authorization: `${user._tokenType} ${user._token}`,
        },
      })
        .then(() => {
          toastr.clear();
          toastr.success("Belge silindi.");
          handleGetUploadedDocuments();
        })
        .catch((err) => handleErrors(err));
    }
  };

  const handleGetUploadedDocuments = (recentlyUploaded = null) => {
    API.get("docs", {
      headers: {
        ...headers,
        Authorization: `${user._tokenType} ${user._token}`,
      },
    })
      .then(({ data: res }) => {
        const sentDocuments = res.data.filter((doc) => doc.type !== 0);
        setSentDocuments(sentDocuments);

        const otherDocuments = res.data.filter((data) => data.type === 0);
        setOtherDocuments(otherDocuments);

        if (recentlyUploaded && showDeleteInfoOnUpload && !isTouchDevice()) {
          setTimeout(() => setShowDeleteInfo(recentlyUploaded), 50);
          setTimeout(() => setShowDeleteInfo(false), 5000);
        }

        if (sentDocuments.length || otherDocuments.length)
          setShowDeleteInfoOnUpload(false);
      })
      .catch((err) => handleErrors(err));
  };

  const handleSentToReview = (e) => {
    e.preventDefault();
    setIsSending(true);
    API.post(
      "account/send-to-review",
      {},
      {
        headers: {
          ...headers,
          Authorization: `${user._tokenType} ${user._token}`,
        },
      }
    )
      .then(() => {
        const updatedUser = { ...user };

        updatedUser["approvalStatus"] = 7;

        update(updatedUser);

        history.push(inactiveLoggedRoute.checking.links[lang]);
      })
      .catch((err) => {
        handleErrors(err);
        setIsSending(false);
      });
  };

  return (
    <div className="content-holder">
      {requiredTypes.map((document) => (
        <DocumentArea
          key={document.type}
          document={document}
          uploadProgress={uploadProgress}
          sentDocuments={sentDocuments}
          otherDocuments={otherDocuments}
          isSending={isSending}
          handleFileSelect={handleFileSelect}
          handleRemoveDocument={handleRemoveDocument}
          translate={translate}
          showDeleteInfo={showDeleteInfo}
          closeDeleteInfo={() => setShowDeleteInfo(false)}
        />
      ))}
      {otherDocuments.map((document, i) => (
        <DocumentArea
          key={document.type || `other_${i}`}
          document={document}
          uploadProgress={uploadProgress}
          sentDocuments={sentDocuments}
          otherDocuments={otherDocuments}
          isSending={isSending}
          handleFileSelect={handleFileSelect}
          handleRemoveDocument={handleRemoveDocument}
          translate={translate}
          showDeleteInfo={showDeleteInfo}
          closeDeleteInfo={() => setShowDeleteInfo(false)}
        />
      ))}
      <DocumentArea
        document={customDocument}
        uploadProgress={uploadProgress}
        sentDocuments={sentDocuments}
        otherDocuments={otherDocuments}
        isSending={isSending}
        handleFileSelect={handleFileSelect}
        handleRemoveDocument={handleRemoveDocument}
        isCustom
        handleCustomDocumentChange={handleCustomDocumentChange}
        translate={translate}
      />
      <div className="row mt-1">
        <div className="col-12 col-md-6">
          <Link
            className="button-primary w-100"
            to={inactiveLoggedRoute.completion.links[lang].replace(
              ":step?",
              STEPS[1].slug[lang]
            )}
          >
            {translate("globalTranslations.prev")}
          </Link>
        </div>
        <div className="col-12 col-md-6 mt-3 mt-md-0">
          <button
            className="button-primary w-100"
            onClick={handleSentToReview}
            disabled={!isOkayToSave || isSending}
          >
            {isSending === true
              ? translate("globalTranslations.saving") + `...`
              : translate("globalTranslations.save")}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  update: (user) => dispatch(update(user)),
});

export default connect(null, mapDispatchToProps)(DocumentsStep);
