import React from "react";
import { CSSTransition } from "react-transition-group";

export default function NewDocument(props) {
  const { styles, translate, inputProps } = props;
  return (
    <div className="bl-modal-content">
      <div className="row">
        <div className="col">
          <h3 className={`text-center ${styles.modalTitle}`}>
            {translate("documents.new_document_title")}
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="text-center mt-3 mb-4">
            {translate("documents.new_document_desc")}
          </p>
        </div>
      </div>
      <div className="row mb-2 mt-1">
        <div className="col">
          <div className="form-group position-relative sliding-label mb-0">
            <select
              name="documentType"
              id="documentType"
              value={inputProps.documentType}
              onChange={props.handleChange}
              className={`form-control custom-select ${
                inputProps.documentType !== "" && `filled`
              }`}
              required
            >
              <option value="" hidden>
                &nbsp;
              </option>
              {props.types.map(({ type, name }) => (
                <option key={type} value={type}>
                  {name}
                </option>
              ))}
              <option value="0">Diğer</option>
            </select>
            <label htmlFor="documentType" className="position-absolute">
              <span>{translate("documents.document_type")}</span>
            </label>
          </div>
          <small className="form-text text-muted mb-2">
            PDF veya resim formatında yükleyebilirsiniz.
          </small>
        </div>
      </div>
      <CSSTransition
        in={parseInt(inputProps.documentType) === 0}
        timeout={10}
        unmountOnExit
      >
        <div className="row mb-2">
          <div className="col">
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="companyName"
                value={inputProps.companyName}
                onChange={props.handleChange}
                className={`form-control ${
                  inputProps.companyName !== "" && `filled`
                }`}
                id="companyName"
                required
              />
              <label htmlFor="companyName" className="position-absolute">
                <span>{translate("documents.document_name")}</span>
              </label>
            </div>
          </div>
        </div>
      </CSSTransition>
      <div className="row mb-3">
        <div className="form-group col">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input d-none"
              id="documentFile"
              name="documentFile"
              onChange={props.handleFileSelect}
              accept="image/*, .pdf"
              disabled={props.isSending}
            />
            <label
              className={`custom-file-label d-flex justify-content-between align-items-center ${styles.documentInputLabelFix}`}
              htmlFor="documentFile"
              style={{ cursor: "pointer" }}
              data-select={translate("globalTranslations.upload_a_file")}
            >
              {inputProps.documentName
                ? inputProps.documentName
                : translate("globalTranslations.upload_a_file")}

              {inputProps.documentName && !props.isSending && (
                <button
                  className="btn btn-link p-0 position-absolute d-flex justify-content-center align-items-center"
                  onClick={props.handleFileReset}
                >
                  <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mw-100 mh-100"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.7283 7.29716C21.3183 6.88711 20.6525 6.8862 20.2425 7.29625L14.5598 12.9789L8.78962 7.20877C8.38407 6.80323 7.72656 6.80323 7.32101 7.20877C6.91547 7.61432 6.91547 8.27183 7.32101 8.67738L13.0912 14.4476L7.40849 20.1302C6.99844 20.5403 6.99935 21.206 7.4094 21.6161C7.81945 22.0261 8.48518 22.027 8.89523 21.617L14.5779 15.9343L20.3481 21.7045C20.7536 22.11 21.4112 22.11 21.8167 21.7045C22.2222 21.2989 22.2222 20.6414 21.8167 20.2359L16.0465 14.4657L21.7292 8.78299C22.1393 8.37294 22.1384 7.70721 21.7283 7.29716Z"
                      fill="#979797"
                    />
                  </svg>
                </button>
              )}
            </label>
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-md-5">
        <div className="col">
          <div className="file_upload_desc">
            <p className="text-center">
              {translate("documents.document_file_desc")}
            </p>
          </div>
        </div>
      </div>

      <div className="row mb-3 mb-md-0 mt-4">
        <div className="col-6 offset-3">
          <button
            className={`button-primary w-100 ${
              props.isSending || props.isProgressActive
                ? `request-progress`
                : ``
            }`}
            onClick={(e) => props.handleSubmit(e, "document")}
            disabled={props.isSending || !props.isSaveButtonActive}
            data-request-progress={`${props.uploadProgress}%`}
          >
            {translate("documents.add_a_document")}
          </button>
        </div>
      </div>
    </div>
  );
}
