import React from "react";
import { Link } from "react-router-dom";
import { inactiveLoggedRoute } from "../../../../app/routes";
import supplierTypes, { inputs } from "../../../../utils/db/supplierTypes";
import { STEPS } from "../Information";
import {
  getCities,
  getDistricts,
  handleErrors,
  validateInput,
} from "../../../../utils/helper";
import Axios from "axios";
import API, { headers } from "../../../../utils/API";
import { update } from "../../../../auth/authActions";
import { connect } from "react-redux";

const InformationStep = ({
  translate,
  lang,
  history,
  information,
  setInformation,
  user,
  update,
}) => {
  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [isOkayToNext, setIsOkayToNext] = React.useState(null);
  const [inputErrors, setInputErrors] = React.useState({});
  const [isSending, setIsSending] = React.useState(false);
  const [selectedSupplierType, setSelectedSupplierType] = React.useState(null);

  React.useEffect(() => {
    if (information?.type) {
      setSelectedSupplierType({
        ...supplierTypes.find(
          ({ id: type }) => String(type) === String(information.type)
        ),
      });
    }
  }, [information?.type]);

  React.useEffect(() => {
    checkIfAllInformationIsFilled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplierType]);

  React.useEffect(() => {
    const cancelSource = Axios.CancelToken.source();
    const timeout = setTimeout(
      async () => setCities(await getCities(212, cancelSource.token)),
      10
    );

    return () => {
      cancelSource.cancel("Operation canceled by the user.");
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let timeout;
    if (!information.country_id || countries.length === 0) {
      setCountries([{ id: 212, name: "Türkiye" }]);
      timeout = setTimeout(() => {
        setInformation({
          ...information,
          country_id: "212",
        });
      }, 20);
    }

    checkIfAllInformationIsFilled();

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information]);

  React.useEffect(() => {
    const cancelSource = Axios.CancelToken.source();
    const timeout = setTimeout(async () => {
      if (information.city_id) {
        setStates(await getDistricts(information.city_id, cancelSource.token));
      }
    }, 10);

    return () => {
      cancelSource.cancel("Operation canceled by the user.");
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information.city_id]);

  React.useEffect(() => {
    if (
      information.state_id &&
      !states.find((state) => String(state.id) === String(information.state_id))
    ) {
      setInformation({ ...information, state_id: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  const checkIfAllInformationIsFilled = () => {
    let errors = { ...inputErrors };

    if (!selectedSupplierType) return false;

    const check = selectedSupplierType?.inputs.every((input) => {
      if (information[inputs[input].name]) {
        if ("validation" in inputs[input]) {
          if (
            !validateInput(
              inputs[input].validation,
              information[inputs[input].name]
            )
          ) {
            errors = !(inputs[input].name in errors)
              ? { ...errors, [inputs[input].name]: true }
              : errors;
            setInputErrors(errors);
            return false;
          }
        }
      }

      if (
        information[inputs[input].name] ||
        selectedSupplierType.optional?.includes(input)
      ) {
        if (inputs[input].name in errors) {
          delete errors[inputs[input].name];
        }
      } else {
        errors = !(inputs[input].name in errors)
          ? { ...errors, [inputs[input].name]: true }
          : errors;
      }

      setInputErrors(errors);

      return (
        information[inputs[input].name] ||
        selectedSupplierType.optional?.includes(input)
      );
    });

    setIsOkayToNext(check);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isOkayToNext) {
      setIsSending(true);

      let params = {
        company_type: information.type,
      };

      const resetDocuments = user.supplierType !== information.type;

      if (!selectedSupplierType) return false;

      selectedSupplierType?.inputs.forEach((input) => {
        if (information[inputs[input]?.name])
          params[inputs[input].name] = information[inputs[input].name];
      });

      API.patch("account", params, {
        headers: {
          ...headers,
          Authorization: `${user._tokenType} ${user._token}`,
        },
      })
        .then(({ data: res }) => {
          setIsSending(false);

          const { data } = res;

          setTimeout(() => {
            const updatedUser = {
              ...user,
              firstName: data.authorized_first_name,
              lastName: data.authorized_last_name,
              email: data.authorized_email,
              supplierName: data.supplier_name,
              supplierType: data.contact.company_type,
            };

            update(updatedUser);
          }, 10);

          if (resetDocuments) {
            API.delete("docs/all", {
              headers: {
                ...headers,
                Authorization: `${user._tokenType} ${user._token}`,
              },
            })
              .then(() => {
                history.push(
                  inactiveLoggedRoute.completion.links[lang].replace(
                    ":step?",
                    STEPS[2].slug[lang]
                  )
                );
              })
              .catch((err) => handleErrors(err));
          } else
            setTimeout(
              () =>
                history.push(
                  inactiveLoggedRoute.completion.links[lang].replace(
                    ":step?",
                    STEPS[2].slug[lang]
                  )
                ),
              20
            );
        })
        .catch((err) => {
          handleErrors(err);
          setIsSending(false);
        });
    }
  };

  return (
    <div className="content-holder">
      <div className="row">
        {selectedSupplierType?.inputs.map((input, i) => {
          input = inputs[input];

          const props = {
            name: input.name,
            id: input.name,
            value: information[input.name] || "",
            onChange: (e) => {
              setInformation({
                ...information,
                [e.target.name]: e.target.value,
              });
            },
            required: !selectedSupplierType?.optional?.includes(input.id),
            tabIndex: i,
          };

          let options = [];
          if (input.type === "select") {
            if (input.name === "country_id") options = countries;
            else if (input.name === "city_id") options = cities;
            else if (input.name === "state_id") options = states;
          }

          return (
            <div
              key={i}
              className={`col-12 ${`half` in input ? `col-md-6` : ``}`}
            >
              <div className="form-group position-relative sliding-label">
                {input.type && input.type === "select" ? (
                  <select
                    {...props}
                    className={`form-control custom-select ${
                      information[input.name] &&
                      information[input.name] !== "" &&
                      `filled`
                    } ${input.name in inputErrors ? `error` : ""}`}
                    disabled={!options.length || input.disabled}
                  >
                    <option value="" hidden>
                      &nbsp;
                    </option>
                    {options.map((option) => (
                      <option key={option.id} value={String(option.id)}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    {...props}
                    className={`w-100 form-control ${
                      information[input.name] &&
                      information[input.name] !== "" &&
                      `filled`
                    } ${input.name in inputErrors ? `error` : ""}`}
                  />
                )}
                <label htmlFor={input.name} className="position-absolute">
                  <span>
                    {translate(
                      `account_completion.information.supplier_information.${input.name}`
                    )}{" "}
                    {selectedSupplierType?.optional?.includes(input.id) &&
                      `(${translate("globalTranslations.optional")})`}
                  </span>
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <Link
            className="button-primary w-100"
            to={inactiveLoggedRoute.completion.links[lang].replace(
              ":step?",
              STEPS[0].slug[lang]
            )}
            onClick={() => {
              if (information.type === 5)
                setInformation({
                  ...information,
                  type: 0,
                });
            }}
          >
            {translate("globalTranslations.prev")}
          </Link>
        </div>
        <div className="col-12 col-md-6 mt-3 mt-md-0">
          <button
            className="button-primary w-100"
            onClick={handleSubmit}
            disabled={!isOkayToNext || isSending}
          >
            {isSending
              ? translate("globalTranslations.saving") + `...`
              : translate("globalTranslations.continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  update: (user) => dispatch(update(user)),
});

export default connect(null, mapDispatchToProps)(InformationStep);
