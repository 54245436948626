import React from "react";
import Stars from "../../utils/components/Stars";

export default function Review(props) {
  const { styles, review } = props;
  return (
    <div className={styles.Review}>
      <div className={styles.avatar}>
        <img src={review.avatar_url} alt={`${review.full_name} Avatar`} />
      </div>
      <div className={styles.info}>
        <div className={styles.top}>
          <div className={styles.topInfo}>
            <h4 className={styles.userName}>{review.full_name}</h4>
            <p className={styles.product}>{review.product.title}</p>
          </div>
          <Stars rating={review.rate} />
        </div>
        <div className={styles.bottom}>
          <h3 className={styles.reviewTitle}>{review.title}</h3>
          <p className={styles.reviewContent}>{review.content}</p>
          <div className={`divider m-0 ${styles.reviewDivider}`}></div>
        </div>
      </div>
    </div>
  );
}
