import React, { Component } from "react";
import ProfileNav from "./ProfileNav";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";

import profileEN from "../translations/en/profile.json";
import profileTR from "../translations/tr/profile.json";

import styles from "./Profile.module.scss";
import { connect } from "react-redux";

class ProfileTemplate extends Component {
  state = {
    isNavOn: false,
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(profileEN, "en");
    this.props.addTranslationForLanguage(profileTR, "tr");
  }

  componentDidMount = () => {
    this.setState({ isNavOn: false });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname)
      this.setState({ isNavOn: false });
  };

  handleToggleNav = (e) => {
    if (e) e.preventDefault();

    this.setState((prevState) => {
      return {
        isNavOn: !prevState.isNavOn,
      };
    });
  };

  render() {
    return (
      <div
        className={`pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app ${styles.profileFix}`}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <section className="page-area p-0">
                <div className="row position-relative">
                  <div
                    className={`col col-lg-4 ${styles.leftNav} ${
                      this.state.isNavOn ? styles.navActive : ``
                    }`}
                  >
                    <ProfileNav
                      user={this.props.user}
                      handleToggleNav={this.handleToggleNav}
                      isProgressActive={this.props.isProgressActive}
                      uploadProgress={this.props.uploadProgress}
                    />
                  </div>
                  <a
                    href="#!"
                    className={`${styles.toggleNavButton} ${
                      this.state.isNavOn ? styles.navActive : ``
                    }`}
                    onClick={this.handleToggleNav}
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.0938 1.61523L5.3125 8.07358L12.0938 14.5319"
                        stroke="#B6B6B6"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {this.props.translate("globalTranslations.go_back")}
                  </a>
                  <div
                    className={`col col-lg-8 ${styles.rightContent} ${
                      this.state.isNavOn ? styles.navActive : ``
                    }`}
                  >
                    {this.props.children}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  withRouter(withLocalize(ProfileTemplate))
);
