import React from "react";
import { Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";

import "./Bookings.scss";

function SingleBooking(props) {
  const { translate, booking } = props;

  const preferred_date =
    booking.other_date ||
    (booking.date_preference === 2 &&
      booking.status_id !== 3 &&
      booking.status_id !== 8 &&
      booking.status_id !== 9) ? (
      <s>{booking.preferred_date}</s>
    ) : (
      booking.preferred_date
    );

  return (
    <div className="single-booking experience-holder w-100 d-flex flex-column align-items-end">
      <div className="experience w-100 position-relative d-flex justify-content-between align-items-start">
        <div className="cover-image d-flex justify-content-center align-items-center rounded-circle overflow-hidden">
          <img
            src={booking.user.avatar_url}
            alt={`${booking.user.name} Avatar`}
            className="rounded-circle avatar h-100"
          />
        </div>
        <div className="info ml-3">
          <h4 className="title mb-1">{booking.user.name}</h4>
          <h6 className="booking-name not-selectable">
            {booking.product && booking.product.name
              ? booking.product.name
              : null}
          </h6>
          <div className="dates mt-md-3 d-flex flex-column flex-md-row align-items-md-center justify-content-md-start">
            <span className="booking-preffered-date date d-flex align-items-center justify-content-start">
              <span className="mr-1">
                {translate("bookings.preferred_date")}:
              </span>{" "}
              {preferred_date}
            </span>
          </div>
        </div>
        <Link
          to="#!"
          className="stretched-link text-muted align-self-center"
          onClick={props.handleModalToggle}
          data-id={booking.id}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.90624 1.61426L11.6875 8.0726L4.90624 14.5309"
              stroke="#B6B6B6"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>

      <div className="divider list"></div>
    </div>
  );
}

export default withLocalize(SingleBooking);
