import React from "react";
import { Link } from "react-router-dom";

import "./Product.scss";

function SingleProduct(props) {
  return (
    <div className="single-product experience-holder w-100 d-flex flex-column align-items-end">
      <div className="experience w-100 position-relative d-flex justify-content-between align-items-center">
        <div className="cover-image d-flex justify-content-center align-items-center">
          <img
            src={props.product.cover_image_url}
            alt={`${props.product.product_name} Cover`}
            className="rounded-circle h-100"
          />
        </div>
        <div className="info ml-3">
          <div className="categories not-selectable d-flex justify-content-start align-items-center">
            <div className="category">{props.product.category[1].name}</div>
          </div>
          <h4 className="title mb-2">{props.product.product_name}</h4>
          <span className="product-code">
            <span className="mr-2">
              {props.translate("products.product_code")}:
            </span>{" "}
            {props.product.product_code}
          </span>
        </div>
        <div className="actions d-flex justify-content-end align-items-center">
          <Link
            to="#!"
            className="new-ticket-link position-relative flex-shrink-0 p-3 mr-1"
            onClick={(e) => props.handleToggleTicketModal(e, props.product.id)}
          >
            Talep Oluştur
          </Link>
          <Link
            to="#!"
            className="stretched-link text-muted"
            onClick={props.handleModalToggle}
            data-id={props.product.id}
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.90624 1.61426L11.6875 8.0726L4.90624 14.5309"
                stroke="#B6B6B6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
      </div>

      <div className="divider list"></div>
    </div>
  );
}

export default SingleProduct;
