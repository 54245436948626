import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";

import { route } from "../app/routes";

import helpCenterEN from "../translations/en/helpCenter.json";
import helpCenterTR from "../translations/tr/helpCenter.json";

import HelpBox from "./components/HelpBox";
import AutoSuggest from "../utils/components/AutoSuggest";

import styles from "./HelpCenter.module.scss";
import Helmet from "react-helmet";
import API, { headers } from "../utils/API";
import { Link } from "react-router-dom";
import { handleErrors } from "../utils/helper";

class HelpCenter extends Component {
  state = {
    cats: [],
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(helpCenterEN, "en");
    this.props.addTranslationForLanguage(helpCenterTR, "tr");
  }

  componentDidMount = () => {
    const configs = {
      headers: {
        ...headers,
        "Accept-Language": this.props.activeLanguage.code,
      },
    };
    API.get("content/categories", configs)
      .then((res) => {
        const { data } = res.data;

        this.setState({ cats: data });
      })
      .catch((err) => handleErrors(err));
  };

  render() {
    return (
      <div className={styles.helpCenter}>
        <Helmet>
          <title>{this.props.translate("nav.help_center")}</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col col-md-8 offset-md-2">
              <h4 className={`text-center ${styles.pageTitle}`}>
                {this.props.translate("help_center.page_title")}
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-8 offset-md-2">
              <AutoSuggest
                placeholder={this.props.translate(
                  "help_center.search_placeholder"
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <section
                className={`mt-3 d-flex align-items-center justify-content-between flex-wrap ${styles.helpCenterExamples}`}
              >
                {this.state.cats && this.state.cats.length
                  ? this.state.cats.map((data) => {
                      return (
                        <HelpBox
                          key={data.slug}
                          styles={styles}
                          link={
                            route["helpDetail"].links[
                              this.props.activeLanguage.code
                            ]
                          }
                          cat={data}
                        />
                      );
                    })
                  : ""}
              </section>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col">
              <div className={styles.buttonsArea}>
                <p className="text-center">
                  {this.props.translate("help_center.for_other_inquiries")}
                </p>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <Link
                    to={route.contactUs.links[this.props.activeLanguage.code]}
                    className={`text-center ${styles.whiteButton}`}
                  >
                    {this.props.translate("help_center.contact_button")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(HelpCenter);
