import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import styles from "./Reviews.module.scss";
import Review from "./Components/Review";
import API, { headers } from "../utils/API";
import Pagination from "../utils/components/Pagination";
import Helmet from "react-helmet";

import reviewsEN from "../translations/en/reviews.json";
import reviewsTR from "../translations/tr/reviews.json";
import { handleErrors } from "../utils/helper";

class Reviews extends Component {
  state = {
    reviews: [],
    isLoading: true,
    page: 1,
    pageCount: 1,
    limit: 15,
  };

  configs = {
    headers: {
      ...headers,
      Authorization: `${this.props.user._tokenType} ${this.props.user._token}`,
    },
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(reviewsEN, "en");
    this.props.addTranslationForLanguage(reviewsTR, "tr");
  }

  componentDidMount() {
    this.getReviews();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.setState({ isLoading: true });
      this.getReviews();
    }
  }

  getReviews = async () => {
    API.get(
      `reviews?limit=${this.state.limit}&page=${this.state.page}`,
      this.configs
    )
      .then((response) => {
        const { data, meta } = response.data;

        const pageCount = meta.last_page;

        this.setState({
          reviews: data,
          isLoading: false,
          pageCount,
        });
      })
      .catch((error) => handleErrors(error));
  };

  handlePageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { page, action } = e.target.dataset;

    let newPage;
    if (page) {
      newPage = page;
    } else if (action) {
      newPage = action === "next" ? this.state.page + 1 : this.state.page - 1;
    } else if (e.target.nodeName === "svg") {
      const linkAction = e.target.parentElement.dataset.action;
      newPage =
        linkAction === "next" ? this.state.page + 1 : this.state.page - 1;
    } else if (e.target.nodeName === "path") {
      const linkAction = e.target.parentElement.parentElement.dataset.action;
      newPage =
        linkAction === "next" ? this.state.page + 1 : this.state.page - 1;
    }

    if (newPage) {
      this.setState({ page: parseInt(newPage) });
    }
  };

  render() {
    let index = 0;
    const reviews = this.state.isLoading
      ? this.props.translate("globalTranslations.loading")
      : this.state.reviews && this.state.reviews.length
      ? this.state.reviews.map((data) => {
          return <Review key={index++} styles={styles} review={data} />;
        })
      : this.props.translate("reviews.no_record_found");

    return (
      <div className="pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pt-2 app">
        <Helmet>
          <title>{this.props.translate("nav.reviews")}</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <section className={`page-area ${styles.reviewsFix}`}>
                <h6 className="sub-title mb-4">
                  {this.props.translate("reviews.sub_title")}
                </h6>
                <div className={styles.Reviews}>{reviews}</div>
                <Pagination
                  page={this.state.page}
                  pageCount={this.state.pageCount}
                  handlePageChange={this.handlePageChange}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withLocalize(Reviews));
