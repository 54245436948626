import React from "react";
import InputMask from "react-input-mask";

export default function BankInfo(props) {
  const { styles, translate, inputProps } = props;
  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h3
              className={`d-flex align-items-end justify-content-between ${styles.pageTitle}`}
            >
              {translate("profile.company_bank_info")}
              <span
                className={styles.backButton}
                onClick={(e) => props.handleSettingChange(e, "")}
              >
                {translate("globalTranslations.go_back")}
              </span>
            </h3>
          </div>
        </div>
        <div className="row mt-2 mt-md-2">
          <div className="col-12">
            <p>{translate("profile.bank_info_desc")}</p>
          </div>
        </div>
        <div className="row mt-2 mt-md-4">
          <div className="col col-12 col-md-6">
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="bankName"
                value={inputProps.bankName}
                onChange={props.handleChange}
                className={`form-control ${
                  inputProps.bankName !== "" && `filled`
                }`}
                id="bankName"
                required
              />
              <label htmlFor="bankName" className="position-absolute">
                <span> {translate("profile.bank_name")}</span>
              </label>
            </div>
          </div>

          <div className="col col-12 col-md-6 mt-2 mt-md-0">
            <div className="form-group position-relative sliding-label">
              <InputMask
                type="text"
                id="iban"
                mask="aa99 9999 9999 9999 9999 9999 99"
                maskChar="_"
                permanents="[4, 9, 14, 19, 24, 29]"
                value={inputProps.iban && inputProps.iban.toUpperCase()}
                name="iban"
                alwaysShowMask={false}
                onChange={props.handleChange}
                className={`form-control ${inputProps.iban !== "" && `filled`}`}
                required
              />
              <label htmlFor="iban" className="position-absolute">
                <span>{props.translate("profile.iban")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6 offset-3">
            <button
              className="button-primary w-100"
              onClick={(e) => props.handleSubmit(e, "bank")}
              disabled={
                props.isSending || !inputProps.iban || !inputProps.bankName
              }
            >
              {translate("globalTranslations.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
