import Cookies from "universal-cookie/es6";

const initState = {
  user: {
    _token: "",
    isLoggedIn: false,
  },
};

const authReducer = (state = initState, action) => {
  const cookie = new Cookies();
  const options = { sameSite: "strict", path: "/" };

  switch (action.type) {
    case "LOGIN": {
      const user = { ...action.user, isLoggedIn: true };

      cookie.set("user", action.user, options);

      return {
        user,
      };
    }
    case "LOGOUT":
      cookie.remove("user", options);
      localStorage.removeItem("products");
      localStorage.removeItem("supplier_information");
      return {
        user: { _token: "", isLoggedIn: false },
      };
    case "CHANGE_LANG":
      state.user["lang"] = action.lang;
      cookie.set("user", state.user, options);
      return state;
    case "UPDATE":
      cookie.set("user", action.user, options);
      return { user: { ...action.user } };
    default:
      return state;
  }
};

export default authReducer;
