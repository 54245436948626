const supplierTypes = [
  {
    id: 1,
    name: "sole_proprietorship",
    order: 1,
    inputs: [6, 0, 1, 7, 13, 14, 8, 9, 10, 11, 12, 15],
  },
  {
    id: 2,
    name: "limited",
    order: 2,
    inputs: [6, 0, 1, 7, 13, 14, 8, 9, 10, 11, 12, 15],
  },
  {
    id: 3,
    name: "ordinary_partnership",
    order: 3,
    inputs: [6, 0, 1, 7, 13, 14, 8, 9, 10, 11, 12, 15],
  },
  {
    id: 4,
    name: "foundation",
    order: 4,
    inputs: [6, 0, 1, 7, 13, 14, 8, 9, 10, 11, 12, 15],
    optional: [13, 14],
  },
  {
    id: 5,
    name: "personal",
    inputs: [6, 0, 1, 5, 8, 9, 10, 11, 12, 15],
  },
];

export const inputs = [
  {
    id: 0,
    name: "authorized_person_first_name",
    half: true,
    relevancy: [1],
  },
  {
    id: 1,
    name: "authorized_person_last_name",
    half: true,
    relevancy: [1],
  },
  {
    id: 2,
    name: "authorized_phone",
    validation: "phone",
    relevancy: [1],
  },
  {
    id: 3,
    name: "authorized_email",
    validation: "email",
    relevancy: [1],
  },
  {
    id: 4,
    name: "password",
    relevancy: [],
  },
  {
    id: 5,
    name: "idn",
    validation: "idn",
    relevancy: [2],
  },
  {
    id: 6,
    name: "supplier_name",
    relevancy: [1],
  },
  {
    id: 7,
    name: "company_name",
    relevancy: [2],
  },
  {
    id: 8,
    name: "address_line",
    relevancy: [2],
  },
  {
    id: 9,
    name: "country_id",
    half: true,
    type: "select",
    defaultValue: 212,
    disabled: true,
    relevancy: [2],
  },
  {
    id: 10,
    name: "city_id",
    half: true,
    type: "select",
    relevancy: [2],
  },
  {
    id: 11,
    name: "state_id",
    half: true,
    type: "select",
    relevancy: [2],
  },
  {
    id: 12,
    name: "zip",
    half: true,
    relevancy: [2],
  },
  {
    id: 13,
    name: "tax_administration",
    relevancy: [2],
  },
  {
    id: 14,
    name: "tax_number",
    validation: "number",
    relevancy: [2],
  },
  {
    id: 15,
    name: "bank_iban",
    relevancy: [3],
  },
];

export default supplierTypes;
