import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import toastr from "toastr";

import API, { headers } from "../utils/API";

import authEN from "../translations/en/auth.json";
import authTR from "../translations/tr/auth.json";

import { seeHidePassword } from "../utils/helper";

import ErrorArea from "../utils/components/ErrorArea";
import { guestRoute } from "../app/routes";
import Helmet from "react-helmet";
import SuccessArea from "../utils/components/SuccessArea";
import InfoArea from "../utils/components/InfoArea";

class ResetPassword extends Component {
  state = {
    password: "",
    seePass: false,
    passwordAgain: "",
    seePassAgain: false,
    error: false,
    requestResponse: "",
    isSending: false,
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(authEN, "en");
    this.props.addTranslationForLanguage(authTR, "tr");
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    await this.setState({ error: false, requestResponse: "", isSending: true });

    const configs = {
      headers: {
        ...headers,
      },
    };

    const { email, token } = this.props.match.params;

    const params = {
      email,
      token,
      password: this.state.password,
      password_confirmation: this.state.passwordAgain,
    };

    API.post("password/reset", params, configs)
      .then((response) => {
        const { message } = response.data.info;
        this.setState({
          requestResponse: message,
          error: false,
          isSending: false,
        });

        const that = this;
        setTimeout(() => {
          that.props.history.push(
            guestRoute.login.links[that.props.activeLanguage.code]
          );
        }, 1750);
      })
      .catch((err) => {
        const { info, errors } = err.response.data;

        if (errors && Object.keys(errors).length)
          Object.keys(errors).map((key) => {
            toastr.error(errors[key]);
            return true;
          });

        this.setState({
          requestResponse: info.message,
          error: true,
          isSending: false,
        });
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    const { translate } = this.props;
    const response =
      this.state.requestResponse !== "" ? (
        this.state.error ? (
          <ErrorArea>{this.state.requestResponse}</ErrorArea>
        ) : (
          <SuccessArea>{this.state.requestResponse}</SuccessArea>
        )
      ) : null;
    return (
      <div className="container">
        <Helmet>
          <title>{this.props.translate("reset_password.page_title")}</title>
        </Helmet>
        <div className="row">
          <div className="col col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
            <h3 className="page-title">
              {translate("reset_password.page_title")}
            </h3>
            <section className="page-area">
              <form
                onSubmit={this.handleSubmit}
                className="col d-flex flex-column"
              >
                <InfoArea>{translate("reset_password.info")}</InfoArea>
                <div className="form-group position-relative sliding-label">
                  <input
                    type={this.state.seePass ? `text` : `password`}
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    className={`form-control ${
                      this.state.password !== "" && `filled`
                    }`}
                    id="password"
                    required
                  />
                  <label htmlFor="password" className="position-absolute">
                    <span>{translate("reset_password.new_password")}</span>
                  </label>
                  <button
                    className="btn position-absolute"
                    style={{ bottom: "7px", right: 0 }}
                    type="button"
                    onClick={() =>
                      this.setState({
                        seePass: seeHidePassword(this.state.seePass),
                      })
                    }
                  >
                    <i
                      className={`far ${
                        this.state.seePass ? `fa-eye-slash` : `fa-eye`
                      } text-muted`}
                    />
                  </button>
                </div>
                <div className="form-group position-relative sliding-label">
                  <input
                    type={this.state.seePassAgain ? `text` : `password`}
                    name="passwordAgain"
                    value={this.state.passwordAgain}
                    onChange={this.handleChange}
                    className={`form-control ${
                      this.state.passwordAgain !== "" && `filled`
                    }`}
                    id="passwordAgain"
                    required
                  />
                  <label htmlFor="passwordAgain" className="position-absolute">
                    <span>
                      {translate("reset_password.confirm_new_password")}
                    </span>
                  </label>
                  <button
                    className="btn position-absolute"
                    style={{ bottom: "7px", right: 0 }}
                    type="button"
                    onClick={() =>
                      this.setState({
                        seePassAgain: seeHidePassword(this.state.seePassAgain),
                      })
                    }
                  >
                    <i
                      className={`far ${
                        this.state.seePassAgain ? `fa-eye-slash` : `fa-eye`
                      } text-muted`}
                    />
                  </button>
                </div>
                {response}
                <button
                  className="button-primary mt-4"
                  disabled={this.state.isSending}
                >
                  {translate("reset_password.button")}
                </button>

                <button
                  className="button-transparent"
                  disabled={this.state.isSending}
                  onClick={() =>
                    this.props.history.push(
                      guestRoute.login.links[this.props.activeLanguage.code]
                    )
                  }
                >
                  {translate("globalTranslations.cancel")}
                </button>
                {this.state.loginError}
              </form>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(ResetPassword);
