import React from "react";
import { Link } from "react-router-dom";

export default function HelpBox(props) {
  const { styles, link, cat } = props;
  return (
    <div
      className={`d-flex flex-column align-items-center align-items-md-start justify-content-between w-100 position-relative ${
        styles.helpBox
      } ${!cat.contents.length ? styles.comingSoon : ``}`}
    >
      <div
        className={`d-flex justify-content-center align-items-center ${styles.icon}`}
        dangerouslySetInnerHTML={{ __html: cat.icon }}
      ></div>
      <div
        className={`d-flex flex-column align-items-start ${styles.contentHolder}`}
      >
        <h3 className={`mt-0 mb-0 mb-md-2 ${styles.title}`}>
          <Link
            to={
              cat.contents.length
                ? link
                    .replace(":cat", cat.slug)
                    .replace(":name", cat.contents[0].slug)
                : "#!"
            }
            className="text-reset text-decoration-none stretched-link"
            onClick={
              !cat.contents.length ? (e) => e.preventDefault() : () => {}
            }
          >
            {cat.title}
          </Link>
        </h3>
        <p
          className={`d-none d-md-block ${styles.desc}`}
          dangerouslySetInnerHTML={{ __html: cat.description }}
        ></p>
      </div>
    </div>
  );
}
