const DocumentRemoveInfo = ({ onClick }) => {
  return (
    <div
      className={`document-remove-info position-absolute d-none d-md-flex`}
      onClick={onClick}
    >
      <p className="m-0">
        Belgeyi silmek için{" "}
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22,4H16V3a3,3,0,0,0-3-3H11A3,3,0,0,0,8,3V4H2A1,1,0,0,0,2,6H4V20a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V6h2a1,1,0,0,0,0-2ZM10,3a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V4H10ZM9,18a1,1,0,0,1-2,0V10a1,1,0,0,1,2,0Zm4,0a1,1,0,0,1-2,0V10a1,1,0,0,1,2,0Zm4,0a1,1,0,0,1-2,0V10a1,1,0,0,1,2,0Z"
            fill="#ffffff"
          />
        </svg>{" "}
        butonuna tıklayın.
      </p>
    </div>
  );
};

export default DocumentRemoveInfo;
