import React from "react";

export default function Profile(props) {
  const { translate } = props;
  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h3
              className={`d-flex align-items-center justify-content-between ${props.styles.pageTitle}`}
            >
              {translate("profile.editing_profile")}
              <div>
                <input
                  type="file"
                  id="changeAvatar"
                  name="avatar"
                  className="d-none"
                  onChange={props.handleAvatarChange}
                  disabled={props.isProgressActive}
                />
                <label htmlFor="changeAvatar" className="mr-3">
                  <svg
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="37"
                      height="37"
                      rx="18.5"
                      fill="url(#paint0_linear)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M25.4866 13.5441L22.5947 13.0695L22.1618 11.3164C22.0865 11.0609 21.9485 11 21.6853 11H15.9133C15.6501 11 15.5078 11.0672 15.4367 11.3164L15.0039 13.0695L12.112 13.5441C11.5017 13.6529 11 14.0399 11 14.6514V23.7997C11 24.4113 11.4977 24.907 12.112 24.907H25.4864C26.1007 24.907 26.5984 24.4113 26.5984 23.7997V14.6514C26.5986 14.0399 26.0918 13.6578 25.4866 13.5441ZM18.7993 16.4772C17.4981 16.4772 16.4428 17.5277 16.4428 18.8235C16.4428 20.1194 17.4981 21.1699 18.7993 21.1699C20.1005 21.1699 21.1558 20.1194 21.1558 18.8235C21.1558 17.5277 20.1005 16.4772 18.7993 16.4772ZM14.669 18.8235C14.669 21.0949 16.5183 22.9364 18.7993 22.9364C21.0803 22.9364 22.9296 21.0949 22.9296 18.8235C22.9296 16.5522 21.0803 14.7107 18.7993 14.7107C16.5183 14.7107 14.669 16.5522 14.669 18.8235Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="76.1383"
                        y1="12.2357"
                        x2="14.3027"
                        y2="-33.8876"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FEB984" />
                        <stop offset="1" stopColor="#E75B2B" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className="ml-2">
                    {translate("profile.add_change_avatar")}
                  </span>
                </label>
                <span
                  className={props.styles.backButton}
                  onClick={(e) => props.handleSettingChange(e, "")}
                >
                  {translate("globalTranslations.go_back")}
                </span>
              </div>
            </h3>
          </div>
        </div>
        <div className="row mt-3 mt-md-5">
          <div className="col">
            <div className="form-group position-relative sliding-label">
              <input
                type="text"
                name="companyName"
                value={props.inputProps.companyName}
                onChange={props.handleChange}
                className={`form-control ${
                  props.inputProps.companyName !== "" && `filled`
                }`}
                id="companyName"
                required
              />
              <label htmlFor="companyName" className="position-absolute">
                <span>{translate("profile.company_name")}</span>
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-2 mt-md-0">
          <div className="col">
            <div className="form-group position-relative sliding-label">
              <input
                type="url"
                name="companyWebsite"
                value={props.inputProps.companyWebsite}
                onChange={props.handleChange}
                className={`form-control ${
                  props.inputProps.companyWebsite !== "" && `filled`
                }`}
                id="companyWebsite"
                onBlur={(e) => props.inputValidation(e, "website")}
              />
              <label htmlFor="companyWebsite" className="position-absolute">
                <span>{translate("profile.website")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6 offset-3">
            <button
              className="button-primary w-100"
              onClick={(e) => props.handleSubmit(e, "profile")}
              disabled={
                props.isSending ||
                !props.inputProps.companyName ||
                props.isProgressActive
              }
            >
              {translate("globalTranslations.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
