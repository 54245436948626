const initState = {
  product: {},
  oldProductState: {},
};

const createProductReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE": {
      const product = { ...action.product };

      return { product, oldProductState: product };
    }
    case "SAVE": {
      const product = { ...action.product };

      return { product, oldProductState: state.oldProductState };
    }
    case "CLEAR": {
      return { product: {}, oldProductState: {} };
    }
    default:
      return { product: state.product, oldProductState: state.oldProductState };
  }
};

export default createProductReducer;
