import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";

import { route } from "../app/routes";

import helpCenterEN from "../translations/en/helpCenter.json";
import helpCenterTR from "../translations/tr/helpCenter.json";

import styles from "./HelpCenter.module.scss";
import SidebarContent from "./components/SidebarContent";
import Detail from "./components/Detail";

import MobileNav from "./components/MobileNav";
import Helmet from "react-helmet";
import API, { headers } from "../utils/API";
import { handleErrors } from "../utils/helper";
import { connect } from "react-redux";

class HelpDetail extends Component {
  state = {
    content: {},
    sidebar: [],
    cats: [],
    openedSidebar: this.props.match.params.cat,
    isMobileMenuActive: false,
  };

  constructor(props) {
    super(props);

    this.props.addTranslationForLanguage(helpCenterEN, "en");
    this.props.addTranslationForLanguage(helpCenterTR, "tr");
  }

  componentDidMount = () => {
    const configs = {
      headers: {
        ...headers,
        "Accept-Language": this.props.activeLanguage.code,
      },
    };
    API.get("content/categories", configs)
      .then((res) => {
        const { data } = res.data;

        this.setState({ cats: data });
      })
      .catch((err) => handleErrors(err));

    this.handleGetDetails();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleGetDetails();
      this.handleToggleMobileMenu();
    }
  }

  handleGetDetails = () => {
    const slug = this.props.match.params.name;

    const configs = {
      headers: {
        ...headers,
        "Accept-Language": this.props.activeLanguage.code,
      },
    };
    API.get("content/detail/" + slug, configs)
      .then((res) => {
        const { data } = res.data;

        this.setState({ content: data });
      })
      .catch((err) => {
        handleErrors(err);

        this.props.history.push(
          route.helpCenter.links[this.props.activeLanguage.code]
        );
      });
  };

  handleChange = (e) => {
    const { value } = e.target;

    const openedSidebar = value;

    this.setState({
      openedSidebar,
    });
  };

  handleToggleMobileMenu = (e) => {
    if (e) e.preventDefault();

    this.setState((prevState) => {
      return {
        isMobileMenuActive: !prevState.isMobileMenuActive,
      };
    });
  };

  render() {
    const { translate } = this.props;

    const sidebarContentProps = {
      styles,
      handleChange: this.handleChange,
      activeLanguage: this.props.activeLanguage,
      openedSidebar: this.state.openedSidebar,
      route,
    };

    const contents =
      this.state.cats && this.state.cats.length
        ? this.state.cats.map((data) => {
            return (
              <SidebarContent
                key={data.slug}
                {...sidebarContentProps}
                title={data.title}
                icon={data.icon}
                slug={data.slug}
                contents={data.contents}
              />
            );
          })
        : "";

    return (
      <div className={styles.HelpDetail}>
        <Helmet>
          <title>{this.props.translate("nav.help_center")}</title>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-3 d-none d-md-block">
              <div className={styles.contentSidebar}>
                <div className={styles.sidebarLists}>{contents}</div>
              </div>
            </div>
            <div className="col-12 d-block d-md-none">
              <MobileNav
                styles={styles}
                translate={translate}
                handleToggleMobileMenu={this.handleToggleMobileMenu}
                isMobileMenuActive={this.state.isMobileMenuActive}
                content={this.state.cats}
                lang={this.props.activeLanguage.code}
                selectedCat={this.props.match.params.cat}
                selectedCatTitle={
                  this.state.content &&
                  this.state.content.categories &&
                  this.state.content.categories &&
                  this.state.content.categories[0].title
                }
              />
            </div>
            <div className="col col-12 col-md-9">
              <Detail styles={styles} content={this.state.content} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withLocalize(HelpDetail));
